import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { capitalize } from "lodash";
import moment from "moment";
import axios from "axios";

const Main = () => {
  const [stores, setStores] = useState([]);

  const [pagination, setPagination] = useState({});

  const fetchStores = (organisation_id, page_number = 1, page_limit = 100) => {
    const url = `https://foodsapi.mobitill.com/adhoc/store/get-many/${organisation_id}?page_number=${page_number}&page_limit=${page_limit}`;

    axios({ method: "GET", url })
      .then((response) => {
        const pager = { ...pagination };
        pager.total = response.data.pagination.page_count;

        setPagination(pager);

        const stores = response.data.stores.map((d) => {
          d.key = d.store_id;
          //d.store_name = capitalize(d.store_name);
          return d;
        });

        setStores(stores);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const organisation_id = 20;

    fetchStores(organisation_id);
  }, []);

  // const onTableChange = (p) => {
  //   const pager = { ...pagination };
  //   pager.current = p.current;
  //   setPagination(pager);

  //   const organisation_id = 20;

  //   fetchStores(organisation_id, pager.current);
  // };

  const onTableChange = (p) => {
    const pager = { ...pagination };
    pager.current = p.current;
    setPagination(pager);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "store_name",
      key: "store_name",
      render: (name) => capitalize(name),
    },
    {
      title: "Description",
      dataIndex: "store_description",
      key: "store_description",
      render: (description) => capitalize(description),
    },
    {
      title: "Created at",
      dataIndex: "store_created_at",
      key: "store_created_at",
      render: (date) => moment(date).format("MMMM Do YYYY"),
    },
    {
      title: "Action",
      dataIndex: "store_id",
      key: "store_id",
      render: (store_id) => <a href={`/stores/view/${store_id}`}>view</a>,
    },
  ];

  return (
    <Table columns={columns} dataSource={stores} onChange={onTableChange} />
  );
};

export default Main;
