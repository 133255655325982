import React, { Component } from 'react'
import { Title } from 'reactbulma'
import { Redirect } from 'react-router-dom'
import { Layout } from 'antd';
import axios from 'axios'
import { inject, observer, Observer } from 'mobx-react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { SubTitle } from 'reactbulma'
import Header from '../../components/header'
import Roles from './roles'
import TableComponent from '../../components/tableComponent'
import { columnFormatter, positionFormatter, boldFormatter } from '../../components/columnFormatter'

@inject('store') 
@observer
class Settings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      redirect: false,
      data: {}
    }

  }

  fetchVendors = (after_id = null, limit = this.state.pageSize) => {

    let organisation_id = this.props.store.selected_organisation.id

    const params = { organisation_id }

    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    
    axios({
      url: `${this.props.store.domain}/vendors`,
      method: 'get',
      params,
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {

        const { data } = res.data

        data.forEach((element, index) => {
            element.key = index + 1
        })

        this.props.store.updateValue('vendors', data)
        

      })
      .catch( (error) => {
        if(error.response) {
          const { data } = error.response
          this.setState({
            errors: data.errors
          })
        } else {
          console.error(error)
        }
      })
  }

  componentDidMount() {
      if(this.props.store.vendors.length === 0) this.fetchVendors()
  }

  render() {
   
    return (
      <div className='def_page'>
     
      <Roles/>

      </div>
    )
  }
}

export default Settings