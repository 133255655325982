import React, { Component } from "react";
import { Title, SubTitle } from 'reactbulma'
import moment from 'moment'
import axios from 'axios'
import { Layout, Tabs } from 'antd';
import { Route, Switch } from 'react-router'
import { inject, observer, Observer } from 'mobx-react';
import SubHeader from "../../components/SubHeader";
import Descriptions from "../../components/Descriptions";
import TableComponent from '../../components/tableComponent'
import { columnFormatter, positionFormatter, boldFormatter, smallColumnFormatter } from '../../components/columnFormatter'
import StatusTag from '../../components/StatusTag'

const { TabPane } = Tabs;

@inject('store') 
@observer
class UserDetails extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
          isLoading: false,
          errors: null,
          user_deliveries: [],
          showDetails: true
        }
        
    }

    goBack = () => {
        this.props.history.goBack()
    }

    ShowDetails = (props) => {
      return(
          <div>p</div>
      )
    }

    onTabClick = (key) => {
      if(key === '1') this.setState({ showDetails: true })
      if(key === '2') this.setState({ showDetails: false })
    }

    viewDelivery = () => {
        
        let data = {}
        data['ID'] = this.props.selected_user.id || 'N/A'
        data['First name'] = this.props.selected_user.first_name || 'N/A'
        data['Last name'] = this.props.selected_user.last_name || 'N/A'
       
       
        data['Email'] = this.props.selected_user.email || 'N/A'
        data['Address'] = this.props.selected_user.address || 'N/A'
        data['Country'] = this.props.selected_user.country || 'N/A'
        data['Phone number'] = this.props.selected_user.phone_number || 'N/A'
        data['Cancel count'] = this.props.selected_user.cancel_count || 0
        data['Is customer blacklisted?'] = this.props.selected_user.blacklisted ? 'Yes' : 'No'

        return data
          
     }

      fetchUserDeliveries = async (after_id = null, limit = this.state.pageSize) => {
        let organisation_id = sessionStorage.getItem('selected_org_id')
        const params = { organisation_id }
        const authStr = `Bearer ${sessionStorage.getItem('token')}`
        this.setState({isLoading: true})
        
        axios({
          url: `${this.props.store.domain}/users/${this.props.selected_user.id}/deliveries`,
          method: 'get',
          params,
          headers: { 'Authorization': authStr }
          })
          .then( (res) => {
            this.setState({ isLoading: false, errors: []})
            const { data } = res.data

            data.forEach((element, index) => {
              element.key = index + 1
              //element.created_at = moment(element.created_at).format('LLLL')
              element.completed_timestamp = element.completed_timestamp && moment(element.completed_timestamp).format('LLLL') || 'N/A'
              element.initiated_timestamp = element.initiated_timestamp && moment(element.initiated_timestamp).format('LLLL') || 'N/A'
            })

            this.setState({user_deliveries: data})
          })
          .catch( (error) => {
            if(error.response) {
              const { data } = error.response
              this.setState({ isLoading: false,errors: data.errors})
            } else {
              this.setState({isLoading: false})
              console.error(error)
            }
          })
      }

    componentDidMount() {
        if(!this.props.selected_user.id) return this.props.history.goBack();
        this.fetchUserDeliveries()
    }

    status(cell, row) {
      return (
          <StatusTag row={row} />
      );
    } 

    changeToKm = (cell, row) => {
      console.log({row})
      if(!row.distance) return 'N/A'
      if(row.distance < 1000) {
        return `${row.distance} m`
      }
      return `${row.distance/1000} Km`
    }

    UserDeliveries = () => {
     
      const columns = [
        { dataField: 'key', text: 'No',  align: 'center', ...positionFormatter}, 
        { dataField: 'id', text: 'Delivery id', hidden: true}, 
        { dataField: 'phone_number', text: 'Customer phone number', hidden: true}, 
        { dataField: 'customer_name', text: 'Customer name', hidden: true}, 
        { dataField: 'location_name', text: 'Customer address', ...boldFormatter, ...columnFormatter}, 
        { dataField: 'delivery_employee_id', text: 'Driver id', hidden: true}, 
        { dataField: 'driver_name', text: 'Driver name', hidden: true}, 
        { dataField: 'distance', text: 'Distance', align: 'right', formatter: this.changeToKm.bind(this), ...columnFormatter},
        { dataField: 'initiated_timestamp', text: 'Time requested', ...columnFormatter}, 
        { dataField: 'completed', text: 'Delivery completed', hidden: true}, 
        { dataField: 'completed_timestamp', text: 'Time completed', ...columnFormatter}, 
        { dataField: 'status', text: 'Status', formatter: this.status.bind(this), ...columnFormatter}, 
        { dataField: 'code', text: 'Code',  align: 'right', ...boldFormatter, ...columnFormatter }, 
      ];
  
      return (
        <div className='card'>
        <TableComponent columns={columns} data={this.state.user_deliveries}/>
        </div>
      )
    }

    render() {
        return (
          <div className='grid_margin'>
            <SubHeader title={`${this.props.selected_user.first_name} ${this.props.selected_user.last_name}`} goBack={this.goBack} />

            <Tabs defaultActiveKey="1" onTabClick={this.onTabClick} style={{marginTop: '0px !important'}}>
              <TabPane tab="Details" key="1"></TabPane>
              <TabPane tab="Customer deliveries" key="2"></TabPane>
            </Tabs>

            {this.state.showDetails && 
              <Descriptions data={this.viewDelivery()} /> 
            }

            { !this.state.showDetails && 
              <this.UserDeliveries/>
            }

              
        </div>
        )
    }
};

export default UserDetails;