import React, { Component } from 'react'
import { Title } from 'reactbulma'
import { Route, Switch } from 'react-router'
import { Tabs } from 'antd';
import axios from 'axios'
import { inject, observer, Observer } from 'mobx-react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { SubTitle } from 'reactbulma'
import Header from '../../components/header'
import Loader from '../../components/LoaderComponent'
import TableComponent from '../../components/tableComponent'
import { columnFormatter, positionFormatter, boldFormatter } from '../../components/columnFormatter'
import AddOrganisation from './addOrganisation'
import Stores from './stores'
const { TabPane } = Tabs;
@inject('store') 
@observer
class Organisations extends Component {
  constructor(props) {
    super(props)


    this.state = {
      isLoading: true,
      redirect: false,
      data: {}
    }

  }

  onTabClick = (key) => {
    if(key === '1') this.props.history.push('/organisations')
    if(key === '2') this.props.history.push('/organisations/add')
  }

  rowEvents = {
    onClick: (e, row, rowIndex) => { 
      let item = {...row}

      console.log(item._id);
      
      this.props.history.push(`/organisations/stores/${item._id}`)
    }
  };

  Organisations = observer(() => {
    let p =this.props.store.organisations
    const columns = [
      { dataField: 'key', text: 'No',  align: 'center', ...positionFormatter}, 
      { dataField: 'name', text: 'Name',  ...boldFormatter, ...columnFormatter}, 
      { dataField: 'address', text: 'Address', ...columnFormatter}, 
      { dataField: 'email', text: 'Email', ...columnFormatter}, 
      { dataField: 'country', text: 'Country', ...columnFormatter}, 
      { dataField: 'phone_number', text: 'Phone number', ...columnFormatter}, 
      { dataField: 'created_at', text: 'Date added', ...columnFormatter}, 
    ];

    return (
      <div className='grid_margin card'>
      <TableComponent columns={columns} data={p} rowEvents={this.rowEvents} />
      </div>
     
    )
  })

  render() {
    return (
      <div className='def_page'>
      <Header title = { 'My organisations' } />
      <Tabs className='grid_margin' defaultActiveKey="1" onTabClick={this.onTabClick} style={{marginTop: '0px !important'}}>
        <TabPane tab="Organisations" key="1"></TabPane>
        <TabPane tab="Add new organisation" key="2"></TabPane>
      </Tabs>
      <Switch>
        <Route
          exact
          path={`${this.props.match.path}/`}
          render={ props => (
            <this.Organisations/>
          )}
        />

        <Route
          exact
          path={`${this.props.match.path}/add`}
          render={ props => (
            <AddOrganisation 
            {...props}
            />
          )}
        />

       <Route
          exact
          path={`${this.props.match.path}/stores/:id`}
          render={ props => (
            <Stores 
            {...props}
            />
          )}
        /> 
        </Switch>
      </div>
    )
  }
}

export default Organisations