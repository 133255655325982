import React, { Component } from "react";
import { Title, SubTitle } from 'reactbulma'

class StatusTag extends Component {
    render() {
        let item = this.props.row
        
        return (
            <div style={{width: 'fit-content', margin: 'auto' }}>
                { item.initiated === true && item.completed !== true && item.declined !== true && <div className='round_tag_progress'>In progress</div> }
                { item.completed !== true && item.declined === true && <div className='round_tag_danger'>Cancelled</div> }
                { (item.completed === true && item.declined === false) && <div className='round_tag_arrived'>Arrived</div> }
                { (item.confirmed === true) && <div className='round_tag_ok'>Completed</div> }

                { item.gender && item.active  && <div className='round_tag_ok'>Active</div>}
                { item.gender && !item.active  && <div className='round_tag_danger'>Not active</div>}
                { item.gender && item.blacklisted  && <div className='round_tag_danger'>Blacklisted</div>}
            </div>
          );
    }
};

export default StatusTag;