import React, { Component } from "react";
import { Title, SubTitle } from 'reactbulma'

class PriceFormatter extends Component {
    render() {
        return (
            <div style={{ display: 'flex' , float: 'right', flexDirection: 'row'}}>
               <span>
               <strong>
               {this.props.amount || this.props.amount_due || 0}
               </strong>
               </span>
               &ensp;
               <span style={{ color: 'grey'}}>
              { sessionStorage.getItem('selected_org_currency') }
               </span>
            </div>
        )
    }
};

export default PriceFormatter;