import React, { Component } from 'react'
import { Title, SubTitle, Message, Delete } from 'reactbulma'
import { Layout, Empty, Icon } from 'antd';
import { ReactComponent as Nothing } from '../images/svg.svg'

class Loader extends Component {

  arrangeErrors =(data) => {
    if(typeof data === 'object'){
      return data.toString().split('\n')
    }

    return data
  }

  Loading = () => {
    return(
      <div className='def_page' style={{margin: 'auto', justifyContent: 'center', alignItems: 'center'}}>
         <Icon type="loading" spin style={{fontSize: '60px'}}/>
         <br/>
         <SubTitle is='6'>Loading data ...</SubTitle>
      </div>
    )
  }

  NothingFound = (props) => {
    return(
       <div className='def_page' style={{margin: 'auto', justifyContent: 'center', alignItems: 'center'}}>
          <Nothing/>
          <SubTitle is='6'>No {this.props.name} found!</SubTitle>
       </div>
    )
  }

  Error = (props) => {
    return(
      <Message danger style={{margin: 'auto', justifyContent: 'center', alignItems: 'center'}}>
    <Message.Header>
      <p><strong>An error occurred</strong>!</p>
      <Delete/>
    </Message.Header>
    <Message.Body>
      {this.arrangeErrors(this.props.errors)}
    </Message.Body>
  </Message>
    )
  }

  render() {
    console.log({loader: this.props})
    if (this.props.isLoading === true) return <this.Loading/>
    if (this.props.errors.length > 0) return <this.Error/>
    if (this.props.size === 0) return <this.NothingFound/>
    return null
   
  }
}

export default Loader