import React, { Component } from 'react';
import { Form, message, Icon, Input, Button, Checkbox, Typography, Radio, Alert } from 'antd';
import { inject } from "mobx-react";
import axios from "axios";
import { Link, Redirect } from 'react-router-dom';
import { Title } from 'reactbulma'
// import mapato_banner from '../../images/MobiTill-on-White-bg.png'
// import logo from '../../images/logo.png'
import Background from '../images/background.jpg'
const { Text } = Typography;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }

@inject("store") class Signup extends Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        this.state = {
            confirmDirty: false,
            success: null,
            error: null,
            isLoading: false,
        };
    };

    loginPage = () => {
      this.props.history.push(`/login`);
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
             if(!values.agreement){
                this.setState({
                    isLoading: false,
                    error: "Please accept agreement"
                });
                return
             } 
         
            this.handleSignup(values)
          }
        });
      };

    handleSignup = (data) => {

        delete data.confirm_password
        delete data.agreement

        // this.setState({
        //     success: null,
        //     error: null,
        //     isLoading: true
        // });

        // //send request to server
        // axios.post(`${this.store.domain}/auth/signup`, { data })
        let payload = { 
          type: 'owner',
          data
        }
    
        axios({
            url: `${this.store.domain}/auth/signup`,
            method: 'post',
            data: payload
        })
            .then(() => {
                this.setState({
                    isLoading: false,
                    success: "Successfully signed up."
                })

                message.success('Successfully signed up. \n You can now log in.', 4, () => {
                    this.props.history.push(`/login`);
                });

            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    error: error.response.data.error
                });
            });
        }

        componentDidMount() {
            this.props.form.validateFields();
        }

        validateAgreement = (rule, value, callback) => {
           
            if (value && value !== true ) {
              callback('Please accept agreement!');
            }
        };

        handleConfirmBlur = e => {
            const { value } = e.target;
            this.setState({ confirmDirty: this.state.confirmDirty || !!value });
        };

        compareToFirstPassword = (rule, value, callback) => {
            const { form } = this.props;
            if (value && value !== form.getFieldValue('password')) {
              callback('Two passwords that you entered are inconsistent!');
            } else {
              callback();
            }
          };
        
        validateToNextPassword = (rule, value, callback) => {
            const { form } = this.props;
            if (value && this.state.confirmDirty) {
              form.validateFields(['confirm_password'], { force: true });
            }
            callback();
        };

        render() {
            const {
                error,
                isLoading
            } = this.state;

            const layout1 = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                  },
                  wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                  },
              };

              const tailFormItemLayout = {
                wrapperCol: {
                  xs: {
                    span: 24,
                    offset: 0,
                  },
                  sm: {
                    span: 16,
                    offset: 8,
                  },
                },
              };  
        
            const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

            const firstNameError = isFieldTouched('first_name') && getFieldError('first_name');
            const lastNameError = isFieldTouched('last_name') && getFieldError('last_name');
            const phoneError = isFieldTouched('phones') && getFieldError('phones');
            const addressError = isFieldTouched('address') && getFieldError('address');
            const countryError = isFieldTouched('country') && getFieldError('country');
            const emailError = isFieldTouched('email') && getFieldError('email');
            const idError = isFieldTouched('national_id') && getFieldError('national_id');
            const password = isFieldTouched('password') && getFieldError('password');
            const confirm_password = isFieldTouched('confirm_password') && getFieldError('confirm_password');
            const agreement = isFieldTouched('agreement') && getFieldError('agreement');
            const resError = isFieldTouched('residential_status') && getFieldError('residential_status');

            return (
                <div style={{ display:'flex', flexDirection:'row', justifyContent: 'space-between', height:'100%', width: '100%', backgroundImage: `url(${Background})`, backgroundSize: 'cover'}}>
                {/* <div className="flex_center" style={{ display: 'flex', width:'10%',height:'100%'}}>
                
                </div> */}
              <div className='card' style={{ width:'30%',  margin:'auto', display:'flex', flexDirection:'column', alignContent:'center', justifyContent: 'center'}}>
                <Form  onSubmit={this.handleSubmit} layout={''} className="login-form" style={{margin: '10px 20px 10px 20px'}}>
                {/* <img src={logo} alt={logo} style={{ height:'4%'}}/> <br/> */}
                <Title is='3' style={{ width:'100%', textAlign: 'center' }}>Sign up</Title>
               
                <Form.Item label='First Name'  {...layout1} validateStatus={firstNameError ? 'error' : ''} help={firstNameError || ''}>
                  {getFieldDecorator('first_name', { rules: [{ required: true, message: 'Please input first name'}]},)(
                  <Input placeholder="First Name" />,)}
                </Form.Item>
      
                <Form.Item label='Last Name' {...layout1} validateStatus={lastNameError ? 'error' : ''} help={lastNameError || ''}>
                  {getFieldDecorator('last_name', { rules: [{ required: true, message: 'Please input last name' }],})(
                  <Input placeholder="Last Name" />,)}
                </Form.Item>
      
                <Form.Item {...layout1} label='Phone Number' validateStatus={phoneError ? 'error' : ''} help={phoneError || ''}>
                  {getFieldDecorator('phone_number', { rules: [{ required: true, whitespace: true, message: 'Please input phone number' }],})(
                  <Input placeholder="Eg 0712345678" />,)}
                </Form.Item>
      
                <Form.Item {...layout1} label='Email' validateStatus={emailError ? 'error' : ''} help={emailError || ''}>
                  {getFieldDecorator('email', { rules: [{ required: true, message: 'Please input email' }],})(
                  <Input placeholder="Eg abc@cdef.com" />,)}
                </Form.Item>
             
                {/* <Form.Item {...layout1} label='Address' validateStatus={addressError ? 'error' : ''} help={addressError || ''}>
                  {getFieldDecorator('address', { rules: [{ required: false, message: 'Please input employee address' }],})(
                  <Input placeholder="P.O Box, Street address" />,)}
                </Form.Item> */}

                <Form.Item {...layout1} label='Country' validateStatus={countryError ? 'error' : ''} help={countryError || ''}>
                    {getFieldDecorator('country', { rules: [{ required: false, message: 'Please input employee country' }],})(
                    <Input placeholder="Eg Kenya" />,)}
                </Form.Item>

                <Form.Item {...layout1} label="Password" hasFeedback  validateStatus={password ? 'error' : ''} help={password || ''}>
                {getFieldDecorator('password', {
                    rules: [
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                    {
                        validator: this.validateToNextPassword,
                    },
                    ],
                })(<Input.Password />)}
                </Form.Item>

                <Form.Item {...layout1} label="Confirm Password" hasFeedback validateStatus={confirm_password ? 'error' : ''} help={confirm_password || ''}>
                {getFieldDecorator('confirm_password', {
                    rules: [
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    {
                        validator: this.compareToFirstPassword,
                    },
                    ],
                })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                </Form.Item>

                <Form.Item {...tailFormItemLayout} >
                {getFieldDecorator('agreement', {valuePropName: 'checked',}, {rules: [{validator: this.validateAgreement}]})(
                    <Checkbox>
                    I have read the <a href="">terms & agreement</a>
                    </Checkbox>,
                )}
                </Form.Item>
        
                <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="full_width" disabled={hasErrors(getFieldsError())} loading={this.state.isLoading}>
                    Sign Up
                </Button>
                <br/>
                <br/>
                <Button type="link" size="large" className="full_width" onClick={this.loginPage}>
                  Already signed up? Log in here
                </Button >
                </Form.Item>

                {error &&
                <Alert
                message="Error"
                description={error}
                type="error"
                closable
                />}
    
              </Form>
              </div>
              </div>
            )
        }
        
};

const WrappedSignUpForm = Form.create({ name: 'space_signup_form' })(Signup);

export default WrappedSignUpForm;