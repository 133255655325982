import React from "react";
import { Button, Form, Input, InputNumber, Select, notification } from "antd";
import { useRouteMatch } from "react-router-dom";
import axios from "axios";

const formItemLayout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 32 },
};

const tailFormItemLayout = {
  wrapperCol: { offset: 2, span: 32 },
};

const Main = ({ form, inventory, fetchInventory }) => {
  const { getFieldDecorator } = form;
  const { Option } = Select;
  const { params } = useRouteMatch();

  const openNotification = (message, type = "info") =>
    notification[type]({
      message,
    });

  const onSubmit = (e) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        const authStr = `Bearer ${sessionStorage.getItem("token")}`;
        const url = `https://foodsapi.mobitill.com/inventories/${params.id}`;

        axios({
          method: "PUT",
          url,
          headers: { Authorization: authStr },
          data: { id: 35, type: "owner", data: values },
        })
          .then(() =>
            openNotification("Inventory successfully updated.", "success")
          )
          .catch((error) => openNotification(error.message, "error"))
          .finally(() => fetchInventory(params.id));
      }
    });
  };

  return (
    <Form onSubmit={onSubmit} {...formItemLayout}>
      <Form.Item style={{ marginBottom: 0 }}>
        <Form.Item
          label="Name"
          style={{ display: "inline-block", width: "calc(33% - 8px)" }}
        >
          {getFieldDecorator("description", {
            initialValue: inventory.description,
            rules: [{ required: true, message: "Name is required." }],
          })(<Input placeholder="Name" />)}
        </Form.Item>

        <Form.Item
          label="Status"
          style={{
            display: "inline-block",
            width: "calc(33% - 8px)",
            margin: "0 8px",
          }}
        >
          {getFieldDecorator("status", {
            initialValue: inventory.status,
            rules: [{ required: true, message: "Status is required." }],
          })(
            <Select placeholder="Status">
              <Option value="ADDED">ADDED</Option>
              <Option value="SOLD">SOLD</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item
          label="SKU"
          style={{
            display: "inline-block",
            width: "calc(33% - 8px)",
            margin: "0 8px",
          }}
        >
          {getFieldDecorator("sku", {
            initialValue: inventory.sku,
          })(<Input placeholder="SKU" />)}
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Form.Item
          label="Units"
          style={{ display: "inline-block", width: "calc(33% - 8px)" }}
        >
          {getFieldDecorator("units", {
            initialValue: inventory.units,
            rules: [{ required: true, message: "Units is required." }],
          })(<InputNumber min={0} type="number" placeholder="Units" />)}
        </Form.Item>

        <Form.Item
          label="Unit price"
          style={{
            display: "inline-block",
            width: "calc(33% - 8px)",
            margin: "0 8px",
          }}
        >
          {getFieldDecorator("unit_price", {
            initialValue: inventory.unit_price,
            rules: [{ required: true, message: "Unit price is required." }],
          })(<InputNumber min={0} type="number" placeholder="Unit price" />)}
        </Form.Item>

        <Form.Item
          label="Unit cost"
          style={{
            display: "inline-block",
            width: "calc(33% - 8px)",
            margin: "0 8px",
          }}
        >
          {getFieldDecorator("unit_cost", {
            initialValue: inventory.unit_cost,
            rules: [{ required: true, message: "Unit cost is required." }],
          })(<InputNumber min={0} type="number" />)}
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Form.Item
          label="Total price"
          style={{
            display: "inline-block",
            width: "calc(33% - 8px)",
          }}
        >
          {getFieldDecorator("total_price", {
            initialValue: inventory.total_price,
            rules: [{ required: true, message: "Totalprice is required." }],
          })(<InputNumber min={0} type="number" placeholder="Total price" />)}
        </Form.Item>

        <Form.Item
          label="Total cost"
          style={{
            display: "inline-block",
            width: "calc(33% - 8px)",
            margin: "0 8px",
          }}
        >
          {getFieldDecorator("total_cost", {
            initialValue: inventory.total_cost,
            rules: [{ required: true, message: "Total is required." }],
          })(<InputNumber min={0} type="number" placeholder="Total cost" />)}
        </Form.Item>

        <Form.Item
          label="Quantity in stock"
          style={{
            display: "inline-block",
            width: "calc(33% - 8px)",
            margin: "0 8px",
          }}
        >
          {getFieldDecorator("quantity_in_stock", {
            initialValue: inventory.quantity_in_stock,
            rules: [
              { required: true, message: "Quantity in stock is required." },
            ],
          })(
            <InputNumber
              min={0}
              type="number"
              placeholder="Quantity in stock"
            />
          )}
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Form.Item
          label="Reorder level"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
          }}
        >
          {getFieldDecorator("reorder_level", {
            initialValue: inventory.reorder_level,
          })(<InputNumber min={0} type="number" />)}
        </Form.Item>

        <Form.Item
          label="Reorder quantity"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "0 8px",
          }}
        >
          {getFieldDecorator("reorder_quantity", {
            initialValue: inventory.reorder_quantity,
          })(<InputNumber min={0} type="number" />)}
        </Form.Item>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const WrappedForm = Form.create({ name: "edit_inventory_item" })(Main);

export default WrappedForm;
