import React, { Component } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { inject, observer } from 'mobx-react'
import {
	Form,
	Button,
	Select,
	Upload,
	Icon,
	message,
} from 'antd'


@inject('store')
@observer
class AddFromExcel extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: true,
			success: false,
			errors: [],
			startFileUpload: false,
			category_uid: '',
		}
		//this.DraggerProps ={}
	}

	handleSubmit = evt => {
		evt.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (err) return
			let payload = this.props.form.getFieldsValue()
			if (this.props.selected_category) {
				this.updateCategory(payload)
			} else {
				this.addCategory(payload)
			}
		})
	}

	addCategory = details => {
		const authStr = `Bearer ${sessionStorage.getItem('token')}`
		const headers = { Authorization: authStr }
		let payload = {}

		payload.data = details
		payload.id = sessionStorage.getItem('id')
		payload.type = sessionStorage.getItem('type')
		payload.data.organisation_id = this.props.store.selected_organisation.id

		axios({
			url: `${this.props.store.domain}/product-categories`,
			method: 'post',
			data: payload,
			headers,
		})
			.then(res => {
				const { data } = res.data
				this.setState({
					success: true,
					errors: [],
					startFileUpload: true,
					category_uid: data.uid,
				})
				this.props.fetchProductCategories()
			})
			.catch(error => {
				if (error.response) {
					const { data } = error.response
					this.setState({ success: false, errors: data.errors })
				} else {
					console.error(error)
				}
			})
	}

	updateCategory = details => {
		const authStr = `Bearer ${sessionStorage.getItem('token')}`
		const headers = { Authorization: authStr }
		let payload = {}

		payload.data = details
		payload.id = sessionStorage.getItem('id')
		payload.type = sessionStorage.getItem('type')
		payload.data.organisation_id = this.props.store.selected_organisation.id

		axios({
			url: `${this.props.store.domain}/product-categories/${this.props.selected_category.uid}`,
			method: 'put',
			data: payload,
			headers,
		})
			.then(res => {
				const { data } = res.data
				this.setState({
					success: true,
					errors: [],
					startFileUpload: true,
					category_uid: data.uid,
				})
				this.props.fetchProductCategories()
			})
			.catch(error => {
				if (error.response) {
					const { data } = error.response
					this.setState({ success: false, errors: data.errors })
				} else {
					console.error(error)
				}
			})
	}

	deleteCategory = () => {
		const authStr = `Bearer ${sessionStorage.getItem('token')}`
		const headers = { Authorization: authStr }
		let payload = {}
		payload.data = {}
		payload.id = sessionStorage.getItem('id')
		payload.type = sessionStorage.getItem('type')
		payload.data.organisation_id = this.props.store.selected_organisation.id

		axios({
			url: `${this.props.store.domain}/product-categories/${this.props.selected_category.uid}`,
			method: 'delete',
			data: payload,
			headers,
		})
			.then(res => {
				const { data } = res.data
				this.setState({ success: true, errors: [] })
				this.props.fetchProductCategories()
			})
			.catch(error => {
				if (error.response) {
					const { data } = error.response
					this.setState({ success: false, errors: data.errors })
				} else {
					console.error(error)
				}
			})
	}

	componentDidMount() {}

	render() {
		const category_title =
			this.props.selected_category === undefined
				? 'Add category'
				: 'Edit category'
		const category_id =
			this.props.selected_category === undefined
				? this.state.category_uid
				: this.props.selected_category.uid
		const upload_url = `${this.props.store.domain}/products/upload`
		const authStr = `Bearer ${sessionStorage.getItem('token')}`
		let payload = {}
		payload.id = sessionStorage.getItem('id')
		payload.type = sessionStorage.getItem('type')

		const props = {
			name: 'file_upload',
			action: upload_url,
			data: payload,
			headers: {
				authorization: authStr,
			},
			onChange(info) {
				if (info.file.status !== 'uploading') {
					console.log(info.file, info.fileList)
				}
				if (info.file.status === 'done') {
					message.success(`${info.file.name} file uploaded successfully`)
				} else if (info.file.status === 'error') {
					message.error(`${info.file.name} file upload failed.`)
				}
			},
		}

		// const props = {
		//     name: 'images',
		//     multiple: true,
		//     accept: 'image/x-png, image/png, image/gif, image/jpeg',
		//     headers: { authorization:  `Bearer ${sessionStorage.getItem('token')}`},
		//     action: `${this.props.store.domain}/product-categories/${category_id}/upload`,
		//     onChange(info) {

		//       const { status } = info.file;
		//       if (status !== 'uploading') {

		//       }
		//       if (status === 'done') {
		//         message.success(`${info.file.name} file uploaded successfully.`);
		//       } else if (status === 'error') {
		//         message.error(`${info.file.name} file upload failed.`);
		//       }
		//     },
		//   };

		// const  UploadImage = (props) => {
		//     return(
		//     <Dragger {...props}>
		//         <p className="ant-upload-drag-icon">
		//         <Icon type="picture" theme="twoTone" />
		//         </p>
		//         <p className="ant-upload-text">Click or drag images to upload</p>
		//         <p className="ant-upload-hint">
		//          Up to 4 images allowed. Maximum size per image should be 3MB
		//         </p>
		//     </Dragger>
		//       )
		// }

		// const meta = {
		//   columns: 6,
		//   gutter: 50,
		//   formItemLayout: null, // Must set this for inline layout
		//   colon: true,
		//   fields: [
		//     {
		//       key: 'label1',
		//       colSpan: 4,
		//       render() {
		//         return (
		//           <fieldset>
		//             <legend> {category_title} </legend>
		//           </fieldset>
		//         )
		//       },
		//     },
		//     { key: 'name', label: 'Name', colSpan: 3, required: true, initialValue: this.props.selected_category && this.props.selected_category.name},
		//     { key: 'description', label: 'Description', colSpan: 3, widget: 'textarea', required: true, initialValue: this.props.selected_category && this.props.selected_category.description },
		//     {
		//       key: 'label1',
		//       colSpan: 4,
		//       render() {
		//         return (
		//           <fieldset>
		//             <legend>Category images</legend>
		//           </fieldset>
		//         )
		//       },
		//     },
		//    // { key: 'button', label: '', colSpan: 6, widget: this.UploadImage },
		//   ],

		// }

		return (
			<div className="grid_margin">
				<Upload {...props}>
					<Button>
						<Icon type="upload" /> Click to Upload Excel File
					</Button>
				</Upload>
				{/* <Form layout="vertical" onSubmit={this.handleSubmit}>
        <FormBuilder form={this.props.form} meta={meta} />
        <Form.Item>
        {(this.state.startFileUpload || this.props.selected_category) && <UploadImage {...props}/>}
        <br/>
        <Alerts 
        success={this.state.success} 
        errors={this.state.errors}
        description = {'Success.'}
        />
        <br/>
          <Button htmlType="submit" type="primary" style={{ width: '48%' }}>
            Submit
          </Button>
        { this.props.selected_category && 
          <Popconfirm
            title="Are you sure you want to delete this category?"
            onConfirm={this.deleteCategory}
            okText="Yes"
            cancelText="No"
          >
          <Button  type="danger" style={{ width: '48%', float: 'right'}}>
            Delete category
          </Button>
          </Popconfirm>
         }
        </Form.Item>
      </Form> */}
			</div>
		)
	}
}

export default Form.create()(AddFromExcel)
