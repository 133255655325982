import React, { Component } from 'react';
import { inject, observer, Observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Icon, Avatar, Typography } from 'antd';
import dil_logo from '../../src/images/DIL-GROUP-LOGO.png'
const { SubMenu } = Menu;

const { Text } = Typography;
const { Header, Sider, Content } = Layout;


@inject('store') 
@observer
class VerticalMenu extends Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        this.state = {
            error: null,
            activeItem: 'profile_account',
        };
    };

  menuCollapse = () => {
     
      this.store.updateValue('menu_collapsed', !this.store.menu_collapsed);
  }

    render() {
      const org_products = this.props.store.org_products || []
      console.log(org_products)
      return (
           
      <div className="fixed_sider" style={{display: 'flex', flexDirection: 'column', maxWidth: '250px', justifyContent:'space-between', backgroundColor: '#ffffff', height: '100%', overflowY: 'auto'}}>
      <Menu
        onClick={this.handleClick}
        style={{ width: this.store.menu_collapsed ? '80px' : '230px', textAlign:'left'}}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1', 'sub2', 'sub3']}
        mode="inline" 
        inlineCollapsed={this.store.menu_collapsed}>

        
        <Menu.Item key="1">
        <Icon type="bar-chart" />
          <span>Overview</span>
          <Link to={'/overview'} />
        </Menu.Item>

        
        <Menu.Item key="2">
        <Icon type="bank" />
          <span>Organisations</span>
          <Link to={'/organisations'} />
        </Menu.Item>

        <Menu.Item key="3">
        <Icon type="shopping-cart" />
          <span>Products</span>
          <Link to={'/products'} />
        </Menu.Item>

    {org_products.includes('customer_orders') && 
        <Menu.Item key="4">
        <Icon type="profile" />
          <span>Customer orders</span>
          <Link to={'/customer-orders'} />
        </Menu.Item>
    }

    {org_products.includes('inventories') && 
        <Menu.Item key="5">
        <Icon type="security-scan" />
          <span>Inventory</span>
          <Link to={'/inventories'} />
        </Menu.Item>

    }

    {org_products.includes('deliveries') && 
        <Menu.Item key="16">
        <Icon type="car" theme="filled" />
          <span>Deliveries</span>
          <Link to={'/deliveries'} />
        </Menu.Item>
    }

    {org_products.includes('devices') && 
        <Menu.Item key="6">
        <Icon type="mobile" theme="filled"/>
          <span>Devices</span>
          <Link to={'/devices'} />
        </Menu.Item> 

    }

    {org_products.includes('employees') && 
        <Menu.Item key="7">
        <Icon type="team" />
          <span>Staff / Employees</span>
          <Link to={'/employees'} />
        </Menu.Item>
    }

    {org_products.includes('users') && 
        <Menu.Item key="8">
        <Icon type="user" />
          <span>Customers</span>
          <Link to={'/users'} />
        </Menu.Item>
    }

    {org_products.includes('sales_report') && 
        <Menu.Item key="15">
        <Icon type="project" />
          <span>Sales report</span>
          <Link to={'/sales-report'} />
        </Menu.Item> 
    }

    {org_products.includes('purchase_orders') && 
      <Menu.Item key="9">
        <Icon type="audit"/>
          <span>Purchase orders</span>
          <Link to={'/purchase-orders'} />
        </Menu.Item>

    }

    {org_products.includes('stock_updates') && 
        <Menu.Item key="10">
        <Icon type="file-done" />
          <span>Stock updates</span>
          <Link to={'/stock-updates'} />
        </Menu.Item> 
    }
        
    {org_products.includes('expenses') && 
        <Menu.Item key="11">
        <Icon type="dollar" />
          <span>Expenses</span>
          <Link to={'/expenses'} />
        </Menu.Item>

        }

    {org_products.includes('distributors') && 
       <Menu.Item key="12">
        <Icon type="shop" theme="filled"/>
          <span>Distributors</span>
          <Link to={'/distributors'} />
        </Menu.Item>

    }

    {org_products.includes('stores') && 
        <Menu.Item key="14">
        <Icon type="environment" theme="filled"/>
          <span>Stores</span>
          <Link to='/stores' />
        </Menu.Item>
    }

    {org_products.includes('settings') && 
        <Menu.Item key="13">
        <Icon type="setting" theme="filled"/>
          <span>Settings</span>
          <Link to={'/settings'} />
        </Menu.Item>

    }

    {org_products.includes('billing') && 
        <Menu.Item key="14">
        <Icon type="credit-card" theme="filled"/>
          <span>Billing</span>
        </Menu.Item>
    }

      </Menu>

      <div className='paddless_p' style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent:'', alignItems:'center'}}>
        <img alt='logo' src={dil_logo} style={{width: '50px'}}/>
        <br/>
        <Text>{this.store.menu_collapsed ? '' : 'Data Integrated Limited'}</Text>
        <p className='mapato_header'> </p>
        <p className='mapato_header'> &copy; 2023</p>
        <div style={{backgroundColor:'#1890ff', height:'40px', width: '100%', display:'flex', alignContent:'center'}} className='flex_center' onClick={this.menuCollapse}>
        <Icon style={{color:'white'}} type={this.store.menu_collapsed ? 'right' : 'left'} />
        </div>
        </div>
        </div>
        )
    };
};

export default VerticalMenu