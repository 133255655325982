import { Card } from "antd";
import React, { useState } from "react";
import List from "./List";
import Add from "./Add";

const tabList = [
  {
    key: "List",
    tab: "List",
  },
  {
    key: "Add",
    tab: "Add",
  },
];

const contentList = {
  List: <List />,
  Add: <Add />,
};

const Main = () => {
  const [currentTab, setCurrentTab] = useState("List");

  const onTabChange = (key) => setCurrentTab(key);

  return (
    <div className="def_page">
      <Card
        style={{ width: "100%" }}
        title="Stores"
        tabList={tabList}
        activeTabKey={currentTab}
        onTabChange={(key) => onTabChange(key)}
      >
        {contentList[currentTab]}
      </Card>
    </div>
  );
};

export default Main;
