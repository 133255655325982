import { observable, computed, action, observe, intercept } from "mobx";


class Store {
    @observable domain = 'https://foodsapi.mobitill.com';
    //@observable domain = 'http://localhost:2000';
    @observable menu_collapsed = false;
    @observable selected_organisation = {};
    //@observable selected_organisation_id = '';
    @observable organisations = []
    @observable products = []
    @observable customer_orders = []
    @observable order_items = []
    @observable order_payments = []
    @observable inventories = []
    @observable employees = []
    @observable sales_report = []
    @observable added_stock = []
    @observable expenses = []
    @observable vendors = []
    @observable purchase_orders = []
    @observable devices = []
    @observable deliveries = []
    @observable product_categories = []
    @observable users = []
    @observable roles = []
    @observable org_products = []
    @observable table_pages = {
        products: 1,
        deliveries: 1
    }

    @action updateValue(key, value) {
        this[key] = value 
    }

    @action clearAll() {
        this.selected_organisation = {};
        this.organisations = [];
        this.products = []
        this.customer_orders = []
        this.order_items = []
        this.order_payments = []
        this.inventories = []
        this.employees = []
        this.sales_report = []
        this.added_stock = []
        this.expenses = []
        this.vendors = []
        this.purchase_orders = []
        this.devices = []
        this.deliveries = []
        this.product_categories = []
        this.users = []
        this.roles = []
        this.org_products = []
    }

    @action updateTablePages(table, page) {
        console.log({table, page})
        if(table == null) return
        this.table_pages[table] = page 
    }


};

export default new Store();