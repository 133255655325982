import React, { Component } from 'react'
import { Title, SubTitle, Message, Delete } from 'reactbulma'
import { Layout, Empty, Icon, Alert } from 'antd';
import { ReactComponent as Nothing } from '../images/svg.svg'

class Alerts extends Component {

  arrangeErrors =(data) => {
    if(typeof data === 'object'){
      return data.toString().split('\n')
    }

    return data
  }

  Success = (props) => {
    return(
        <Alert
        message="Success"
        description={this.props.description}
        type="success"
        showIcon
        closable
      />
    )
  }

  Error = (props) => {
    return(
        <Alert
        message="An error occured"
        description={this.arrangeErrors(this.props.errors)}
        type="error"
        showIcon
        closable
      />
    )
  }

  render() {
    if (this.props.success) return <this.Success/>
    if (this.props.errors.length > 0) return <this.Error/>
    return null
  }
}

export default Alerts