import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

// Create Document Component
const MyDocument = ({ customer_order, customer_order_items }) => {
  console.log(customer_order_items);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>Order report</Text>
        </View>

        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>Invoice No:</Text>
          <Text style={styles.invoiceDate}>{customer_order.order__id}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Date: </Text>
          <Text>{new Date().toLocaleDateString("en-UK")}</Text>
        </View>

        <View style={styles.headerContainer}>
          <Text style={styles.billTo}>Bill To:</Text>
          <Text>
            Name:{" "}
            {`${customer_order.customer_first_name} ${customer_order.customer_last_name}`}
          </Text>
          <Text>Delivery address: {customer_order.order_delivery_address}</Text>
          <Text>Block No: {customer_order.order_block_number}</Text>
          <Text>House No.: {customer_order.order_house_number}</Text>
          <Text>Floor No.: {customer_order.order_floor_number}</Text>
          <Text>
            Customer Phone No.: {customer_order.customer_phone_number}
          </Text>
          <Text>Customer Email: {customer_order.customer_email}</Text>
          <Text>Store: {customer_order.store_name}</Text>
        </View>

        <View style={styles.itemsTableContainer}>
          <View style={styles.itemsTableHeaderContainer}>
            <Text style={styles.itemsTableHeaderDescription}>
              Item Description
            </Text>
            <Text style={styles.itemsTableHeaderQty}>Qty</Text>
            <Text style={styles.itemsTableHeaderRate}>@</Text>
            <Text style={styles.itemsTableHeaderAmount}>Amount</Text>
          </View>
        </View>

        {customer_order_items.map((item) => (
          <View style={styles.itemsDataRowRow}>
            <Text style={styles.itemsDataRowDescription}>
              {item.description}
            </Text>
            <Text style={styles.itemsDataRowQty}>{item.unit}</Text>
            <Text style={styles.itemsDataRowRate}>{item.amount_per_unit}</Text>
            <Text style={styles.itemsDataRowAmount}>{item.amount_total}</Text>
          </View>
        ))}
      </Page>
    </Document>
  );
};

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  titleContainer: {
    flexDirection: "row",
    marginTop: 24,
  },
  reportTitle: {
    color: "#61dafb",
    letterSpacing: 4,
    fontSize: 25,
    textAlign: "center",
    textTransform: "uppercase",
  },
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 36,
    justifyContent: "flex-end",
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  label: {
    width: 60,
  },
  headerContainer: {
    marginTop: 36,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  itemsTableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  itemsTableHeaderContainer: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  itemsTableHeaderDescription: {
    width: "60%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  itemsTableHeaderQty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  itemsTableHeaderRate: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  itemsTableHeaderAmount: {
    width: "15%",
  },
  itemsDataRowRow: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  itemsDataRowDescription: {
    width: "60%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  itemsDataRowQty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  itemsDataRowRate: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  itemsDataRowAmount: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
});

export default MyDocument;
