import { Card } from "antd";
import React, { useState } from "react";
import View from "./View";
import AddInventory from "./AddInventory";
import ListInventory from "./ListInventory";

const tabList = [
  {
    key: "View",
    tab: "View",
  },
  {
    key: "ListInventory",
    tab: "List inventory",
  },
  {
    key: "AddInventory",
    tab: "Add inventory",
  },
];

const contentList = {
  View: <View />,
  ListInventory: <ListInventory />,
  AddInventory: <AddInventory />,
};

const Main = () => {
  const [currentTab, setCurrentTab] = useState("View");

  const onTabChange = (key) => setCurrentTab(key);

  return (
    <div className="def_page">
      <Card
        style={{ width: "100%" }}
        title="View store"
        tabList={tabList}
        activeTabKey={currentTab}
        onTabChange={(key) => onTabChange(key)}
      >
        {contentList[currentTab]}
      </Card>
    </div>
  );
};

export default Main;
