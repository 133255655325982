import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Typography, Radio, Alert } from 'antd';
import { inject } from 'mobx-react';
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import { Title } from 'reactbulma'
import Background from '../images/background.jpg'

const { Text } = Typography;

@inject('store') 
class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            success: null,
            isLoading: false,
            loggedIn: false, 
        };
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            this.handleLogin(values)
          }
        });
      };

    logIn = () => {
      this.props.history.push(`/login`);
    }

    componentDidMount = () => {
   
    }

    handleLogin = (values) => {
        this.setState({
            error: null,
            success: null,
            isLoading: true
        });

            let payload = {
             
              data: {
                username: values.username,
                type: values.log_type,
              }
            }
            console.log(payload)
              axios({
                  url: `${this.props.store.domain}/auth/resetPassword`,
                  method: 'post',
                  data: payload
              })
                .then(res => {
                    this.setState({
                        isLoading: false,
                        error: null,
                        success: true
                    });
                })
                .catch(error => {
                    if (error.response) {
                        this.setState({
                            isLoading: false,
                            error: (error.response && error.response.data.errors) || 'something went wrong'
                        });
                        return
                      }
                    this.setState({
                        isLoading: false,
                        error:'something went wrong'
                    });
                });
    };

    render() {
        const { error, success } = this.state;
        const { getFieldDecorator } = this.props.form;
       
        return (
            <div className='' style={{ display:'flex', flexDirection:'row', justifyContent: 'space-between', height:'100%', width: '100%', backgroundImage: `url(${Background})`, backgroundSize: 'cover'}}>
            

                <div className='card' style={{ width:'30%',  margin:'auto', display:'flex', flexDirection:'column', alignContent:'center', justifyContent: 'center'}}>
              
                <Form onSubmit={this.handleSubmit} className="login-form" style={{margin: '10px 20px 10px 20px'}} >
                <br/> <br/>
                <Title is='3' spaced>Reset password</Title>
                
                <br/>
                <Form.Item>
                  {getFieldDecorator('username', {
                    rules: [{ required: true, message: 'Enter your email or phone number' }],
                  })(
                    <Input
                      size="large"
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Please enter your email address or phone number"
                    />,
                  )}
                </Form.Item>
                <br/>

                <Text>Select if you are a business or employee</Text>
                <Form.Item>
                {getFieldDecorator('log_type', {
                    rules: [{ required: true, message: 'Please select if you are a business or employee!' }],
                  })(
                    <Radio.Group name="log_type">
                    <Radio value={'owner'}>Business</Radio>
                    <Radio value={'employee'}>Staff / employee</Radio>
                    </Radio.Group>
                  )}
                
                </Form.Item>

                <Form.Item>
                <Button type="primary" htmlType="submit" className="full_width" size="large" loading={this.state.isLoading}>
                    Send password to my phone number
                </Button>
                <br/>
                <br/>
                
                <Button type="link" className="full_width" size="large" onClick={this.logIn}>
                    go to  Login
                </Button>
                </Form.Item>

            {error &&
                <Alert
                message="Error"
                description={error}
                type="error"
                closable
                />}

            {success &&
                <Alert
                message="Success"
                description='A password has been sent to your phone number'
                type="success"
                closable
                />}
            
              </Form>


              
                 <div>
                     </div> 
              </div>
            </div>
        )
         
    }
};

const ForgotPasswordForm = Form.create({ name: 'forgot_pass_form' })(ForgotPassword);

export default ForgotPasswordForm;