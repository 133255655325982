import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import Header from "../../components/header";
import Orders from "./CustomerOrders";
import ViewOrder from "./ViewOrder";

const Main = () => {
  const { path } = useRouteMatch();

  const routes = [
    {
      path: `${path}/orders`,
      component: <Orders />,
    },
    {
      path: `${path}/view/:id`,
      component: <ViewOrder />,
    },
  ];

  return (
    <div className="def_page">
      <Header title="Customer orders" />

      <div className="grid_margin card">
        <Switch>
          {routes.map((route) => (
            <Route path={route.path} children={route.component} />
          ))}

          <Redirect path={path} to={`${path}/orders`} />
        </Switch>
      </div>
    </div>
  );
};

export default Main;
