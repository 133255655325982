import React, { Component } from 'react'
import { List, Card } from 'antd';
import axios from 'axios'
import { inject, observer } from 'mobx-react';
import category from '../../images/category.png'

const { Meta } = Card;

@inject('store') 
@observer
class ProductCategories extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,

    }
  }

  fetchProductCategories = (after_id = null, limit = this.state.pageSize) => {

    let organisation_id = this.props.store.selected_organisation.id

    const params = { organisation_id }

    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    
    axios({
      url: `${this.props.store.domain}/product-categories`,
      method: 'get',
      params,
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {

        const { data } = res.data

        data.forEach((element, index) => {
            element.key = index + 1
        })

        this.props.store.updateValue('product_categories', data)
        

      })
      .catch( (error) => {
        if(error.response) {
          const { data } = error.response
          this.setState({
            errors: data.errors
          })
        } else {
          console.error(error)
        }
      })
  }

  onCategoryClick = (p) => {
    console.log(p)
    this.props.showCategoryDetails(p)
  }

  componentDidMount() {
      if(this.props.store.product_categories.length === 0) this.fetchProductCategories()
  }

  Kard = (props) => {
      return(
        <div onClick={() => this.onCategoryClick(props)}>
        <Card
        style={{ width: 300 }}
        hoverable
        cover={
          <img  style={{ width: 300, height:300, backgroundSize: 'cover', overflow: 'hidden' }}
            alt="category_image"
            src={props.image}
            
          />
        }
        >
        <Meta
          title={props.name}
          description={props.description}
        />
      </Card>
      </div>
      )
  }

  render() {
    let p =this.props.store.product_categories
    return (
      <div className='def_page grid_margin'>
            <List
                grid={{ gutter: 1, column: 4 }}
                dataSource={p}
                
                renderItem={item => (
                <List.Item>
                    <this.Kard id={item.id} uid={item.uid} name={item.name.substring(0,30)} description={item.description.substring(0, 35)} image={item.image_urls && item.image_urls[0] || category}></this.Kard>
                </List.Item>
                )}
            />
      </div>
    )
  }
}

export default ProductCategories