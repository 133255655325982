import * as firebase from "firebase/app";
import "firebase/messaging";

var firebaseConfig = {
    apiKey: "AIzaSyDnxVEEaF6fHTCDfMOKi4jKU_G978g-r84",
    authDomain: "mobitill-retail-api.firebaseapp.com",
    databaseURL: "https://mobitill-retail-api.firebaseio.com",
    projectId: "mobitill-retail-api",
    storageBucket: "mobitill-retail-api.appspot.com",
    messagingSenderId: "204485368622",
    appId: "1:204485368622:web:3505f9d0d9abea5e6638bf",
    measurementId: "G-3H6PJDW91E"
  };

const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);
const messaging = initializedFirebaseApp.messaging();

export { messaging };