import React, { Component } from "react";
import { Title, SubTitle } from 'reactbulma'
import moment from 'moment'
import axios from 'axios'
import { inject, observer, Observer } from 'mobx-react';
import SubHeader from "../../components/SubHeader";
import Descriptions from "../../components/Descriptions";

@inject('store') 
@observer
class DeliveryDetails extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
          isLoading: false,
          order: {},
          order_items: [],
          payments: [],
          user: {}
        }
        
    }

    goBack = () => {
        this.props.history.goBack()
    }

    ShowDetails = (props) => {
      return(
          <div>p</div>
      )
    }

    viewDelivery = () => {
        
        let data = {}
        data['ID'] = this.props.selected_delivery.id || 'N/A'
        data['Order ID'] = this.props.selected_delivery.order_id || 'N/A'
        data['Delivery duration'] = this.props.selected_delivery.delivery_time || 'N/A'
        data['Distance to customer'] = this.props.selected_delivery.distance || 'N/A'
        data['Delivery cost'] = `${this.props.selected_delivery.cost || 0} ${sessionStorage.getItem('selected_org_currency')}`
        data['Initiated'] = this.props.selected_delivery.initiated ? 'Yes' : 'No'
       
        data['Completed'] = this.props.selected_delivery.completed ? 'Yes' : 'No'
        data['Cancelled'] = this.props.selected_delivery.declined ? 'Yes' : 'No'
        data['Cancel source'] = this.props.selected_delivery.cancel_source || 'N/A'
        data['Cancel reason'] = this.props.selected_delivery.cancel_reason || 'N/A'

       
        data['Requested time'] = moment(this.props.selected_delivery.initiated_timestamp).isValid() ? moment(this.props.selected_delivery.initiated_timestamp).format('LLLL') : 'N/A'
        data['Completion time'] = moment(this.props.selected_delivery.initiated_timestamp).isValid() ? moment(this.props.selected_delivery.completed_timestamp).format('LLLL') : 'N/A'
        data['Code'] = this.props.selected_delivery.code || 'N/A'
       
        
        let status = ''

        if(this.props.selected_delivery.initiated === true && this.props.selected_delivery.completed !== true && this.props.selected_delivery.declined !== true ) status = 'In progress'
        if(this.props.selected_delivery.completed !== true && this.props.selected_delivery.declined === true)  status = 'Cancelled'
        if(this.props.selected_delivery.initiated === true && this.props.selected_delivery.completed === true && this.props.selected_delivery.declined !== true )  status = 'Completed'
        data['Status'] = status
        console.log({data})
        return data
          
     }

     viewOrderItems = (items) => {
        return items.map(item => {
            let data = {}
            data['Product'] = item.description
            data['Units'] = item.unit
            data['Amount per unit'] = `${item.amount_per_unit} ${sessionStorage.getItem('selected_org_currency')}`
            data['Total amount'] = `${item.amount_total} ${sessionStorage.getItem('selected_org_currency')}`
            return <Descriptions data={data}/>
        })
     }

     viewOrder = (order) => {
      if(!order) return null
        let data = {}
        data['Total amount'] =`${order.amount} ${sessionStorage.getItem('selected_org_currency')}`
        data[ 'Payed'] = order.payed ? 'Yes' : 'No'
        return <Descriptions data={data}/>
     }

     viewUser = (user) => {
        if(!user) return null
        let data = {}
        data['Name'] =`${user.first_name} ${user.last_name}`
        data['Phone Number'] = user.phone_number || 'N/A'
        data['Email'] = user.email || 'N/A'
        data['Address'] = user.address || 'N/A'
        data['gender'] = user.gender || 'N/A'
        data['Country'] = user.country || 'N/A'
       
        return <Descriptions data={data}/>
     }

     fetchOrderItems = async (after_id = null, limit = this.state.pageSize) => {
        let organisation_id = sessionStorage.getItem('selected_org_id')
        const params = { organisation_id }
        const authStr = `Bearer ${sessionStorage.getItem('token')}`
        this.setState({isLoading: true})
        
        axios({
          url: `${this.props.store.domain}/customer-orders/${this.props.selected_delivery.order_id}`,
          method: 'get',
          params,
          headers: { 'Authorization': authStr }
          })
          .then( (res) => {
            this.setState({ isLoading: false, errors: []})
            const { data } = res.data
            const { order, order_items, payments } = data
            this.setState({order, order_items, payments})
          })
          .catch( (error) => {
            if(error.response) {
              const { data } = error.response
              this.setState({ isLoading: false,errors: data.errors})
            } else {
              this.setState({isLoading: false})
              console.error(error)
            }
          })
      }

      fetchUser = async (after_id = null, limit = this.state.pageSize) => {
        let organisation_id = sessionStorage.getItem('selected_org_id')
        const params = { organisation_id }
        const authStr = `Bearer ${sessionStorage.getItem('token')}`
        this.setState({isLoading: true})
        
        axios({
          url: `${this.props.store.domain}/users/${this.props.selected_delivery.user_id}`,
          method: 'get',
          params,
          headers: { 'Authorization': authStr }
          })
          .then( (res) => {
            this.setState({ isLoading: false, errors: []})
            const { data } = res.data
           
            this.setState({user: data})
          })
          .catch( (error) => {
            if(error.response) {
              const { data } = error.response
              this.setState({ isLoading: false,errors: data.errors})
            } else {
              this.setState({isLoading: false})
              console.error(error)
            }
          })
      }

    componentDidMount() {
        if(!this.props.selected_delivery.id) return this.props.history.goBack();
        this.fetchOrderItems()
        this.fetchUser()
    }

    render() {
        return (
            <div className='grid_margin'>
                <SubHeader title={'Delivery details'}
                goBack={this.goBack} />

            <Descriptions data={this.viewDelivery()} /> 
            <br/>
            <Title is='5'>Order details</Title> 
            {this.viewOrderItems(this.state.order_items)} 
            {this.viewOrder(this.state.order)} 
            <br/>
            <Title is='5'>Customer details</Title>
            {this.viewUser(this.state.user)} 
            <br/>

      </div>
        )
    }
};

export default DeliveryDetails;