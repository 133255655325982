import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Typography, Radio, Alert } from 'antd';
import { inject } from 'mobx-react';
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import { Title } from 'reactbulma'
import Background from '../images/background.jpg'

const { Text } = Typography;

@inject('store') 
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoading: false,
            loggedIn: false, 
        };
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            this.handleLogin(values)
          }
        });
      };

    setPass = () => {
     this.props.history.push(`/forgotPassword`);
    }  

    signUp = () => {
      this.props.history.push(`/signup`);
    }

    componentDidMount = () => {
   
    }

    handleLogin = (values) => {
        this.setState({
            error: null,
            isLoading: true
        });

            let payload = {
              type: values.log_type,
              data: {
                username: values.username,
                password: values.password
              }
            }
      
              axios({
                  url: `${this.props.store.domain}/auth/login`,
                  method: 'post',
                  data: payload
              })
                .then(response => {
                  const { data } = response.data
                  
                  sessionStorage.setItem('type', values.log_type)
                  sessionStorage.setItem('loggedIn', true)
                  sessionStorage.setItem('token', data.token)
                  sessionStorage.setItem('url', this.url)
                  sessionStorage.setItem('admin', data.admin)
                  sessionStorage.setItem('first_name', data.first_name)
                  sessionStorage.setItem('last_name', data.last_name)
                  sessionStorage.setItem('id', data.id)
                
                  if(values.log_type === 'employee') {
                    if(data.organisation) {
                      data.organisation.key = 0
                      data.organisation.value = data.organisation.name
                      this.props.store.updateValue('selected_organisation', data.organisation)
                      this.props.store.updateValue('org_products', data.organisation.products)
                      sessionStorage.setItem('selected_org_id', data.organisation.id)
                      sessionStorage.setItem('selected_org_currency', data.organisation.currency)
                      sessionStorage.setItem('lat', data.organisation.lat)
                      sessionStorage.setItem('long', data.organisation.long)
                     
                      let employee_organisations = []
                      employee_organisations.push(data.organisation)
                      this.props.store.updateValue('organisations', employee_organisations)
                    }
                  }
                  this.setState({ loggedIn: true })
                })
                .catch(error => {
                    if (error.response) {
                        this.setState({
                            isLoading: false,
                            error: (error.response && error.response.data.errors) || 'something went wrong'
                        });
                        return
                      }
                    this.setState({
                        isLoading: false,
                        error:'something went wrong'
                    });
                });
    };

    render() {
        const { error, isLoading } = this.state;
        const { getFieldDecorator } = this.props.form;
        if(!this.state.loggedIn) {
        return (
            <div className='' style={{ display:'flex', flexDirection:'row', justifyContent: 'space-between', height:'100%', width: '100%', backgroundImage: `url(${Background})`, backgroundSize: 'cover'}}>
              
                {/* <div  style={{ display:'flex', width:'60%',height:'100%', margin: 'auto'}}>
                   
                </div> */}

                <div className='card' style={{ width:'30%',  margin:'auto', display:'flex', flexDirection:'column', alignContent:'center', justifyContent: 'center'}}>
              
                <Form onSubmit={this.handleSubmit} className="login-form" style={{margin: '10px 20px 10px 20px'}} >
                <br/> <br/>
                <Title is='3' spaced>Log in</Title>
                
                <br/>
                <Form.Item>
                  {getFieldDecorator('username', {
                    rules: [{ required: true, message: 'Please input your email address' }],
                  })(
                    <Input
                      size="large"
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Email"
                    />,
                  )}
                </Form.Item>
                <br/>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                    <Input
                      size="large"
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="Password"
                    />,
                  )}
                </Form.Item>
                
                <Text>Select if you are a business or employee</Text>
                <Form.Item>
                {getFieldDecorator('log_type', {
                    initialValue: "owner",
                    rules: [{ required: true, message: 'Please select if you are a business or employee!' }],
                  })(
                    <Radio.Group name="log_type">
                    <Radio value={'owner'}>Business</Radio>
                    <Radio value={'employee'}>Staff / employee</Radio>
                    </Radio.Group>
                  )}
                
                </Form.Item>

                <Form.Item>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(<Checkbox>Remember me</Checkbox>)}
            
                  <Button type="link" onClick={this.setPass}>
                    Forgot password
                  </Button >
                 
                </Form.Item>

                <Form.Item>
                <Button type="primary" htmlType="submit" className="full_width" size="large" loading={this.state.isLoading}>
                    Log in
                  </Button>
                  <br/><br/>
                  <Button type="link" size="large" className="full_width" onClick={this.signUp}>
                    Not registered? Sign up now
                </Button >
                </Form.Item>

            {error &&
                <Alert
                message="Error"
                description={error}
                type="error"
                closable
                />}
              </Form>
              
                 <div>
                     </div> 
              </div>
            </div>
        )
            }else{
              return <Redirect to='/overview' />
            }
    }
};

const WrappedNormalLoginForm = Form.create({ name: 'space_login_form' })(Login);

export default WrappedNormalLoginForm;