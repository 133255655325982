import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Radio, Typography, Select, Button, message, Icon, Avatar, Badge } from 'antd';
//import images
import logo from '../images/MobiTill-Primary-on-transparent-bg.png';

const { Option } = Select;
const { Text, Title } = Typography;

@inject('store') 
@observer
class HorizontalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          error: null
        };
    };

    handleSelectOrganization = (value) => {
        this.setOrganizationValue(value)
     };

    getOwnerOrganisations = () => {

      const authStr = `Bearer ${sessionStorage.getItem('token')}` 
  
      const params = {
          owner_id: sessionStorage.getItem('id')
      }
  
      axios({
          url: `${this.props.store.domain}/organisations`,
          method: 'get',
          params,
          headers: { 'Authorization': authStr }
          })
          .then( (res) => {
    
            const { data } = res.data
            
            data.forEach((element, index) => {
                element.key = index + 1
                element.value = element.name
            });
  
            if(data[0]) {
              this.props.store.updateValue('selected_organisation', data[0])
              this.props.store.updateValue('org_products', data[0].products)
              sessionStorage.setItem('selected_org_id', data[0].id)
              sessionStorage.setItem('selected_org_currency', data[0].currency)
              sessionStorage.setItem('lat', data[0].lat)
              sessionStorage.setItem('long', data[0].long)
              
            }

            this.props.store.updateValue('organisations', data)
            
          })
          .catch( (error) => {
    
          })
      } 

    setOrganizationValue = (key) => {
       key = parseInt(key)
  
       let sel = this.props.store.organisations.filter(obj => {return obj.key === key })
     
       let selected_org = sel[0]
       if(!selected_org){
           //message.warning('Selected organisation could not be read');
           return
       }
       this.props.store.clearAll()
       console.log(sel[0])
       this.props.store.updateValue('selected_organisation', sel[0])
       sessionStorage.setItem('selected_org_id', sel[0].id)
       sessionStorage.setItem('selected_org_currency', sel[0].currency)
       sessionStorage.setItem('lat', sel[0].lat)
       sessionStorage.setItem('long', sel[0].long)
       this.props.store.updateValue('org_products', sel[0].products)
       
    }

    logOut = () => {
        this.props.store.clearAll()
        sessionStorage.clear()
        this.props.history.push('/login')
    } 

    componentDidMount() {
      if(sessionStorage.getItem('type') === 'owner') {
          console.log('****getting owners organisations')
          this.getOwnerOrganisations()
      }
    }

    TopBar =  observer(() => {
        let opt =  this.props.store.organisations.map((x) => <Option key={x.key}>{`${Number.parseInt(x.key) + 1} . ${x.value}`}</Option>)
        
        return(
            <div>
            <div className='' style={{display:'flex', width:'100%', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center', backgroundColor: '#F7F9FA', paddingTop:'10px', paddingBottom:'10px'}}>
            <div  style={{marginLeft: '3%'}}>
             <Select
                showSearch
                style={{ width: '200px' }}
                placeholder="Select organisation"
                optionFilterProp="children"
                value={ this.props.store.selected_organisation.name }
                onChange={this.handleSelectOrganization}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 } >   
                {opt}
            </Select> 
            </div>

            <div style={{marginRight: '1%', display:'flex', flexDirection: 'row', justifyContent:'space-around', alignItems:'center'}}>
            <div>
            <Text className='normal_text' style={{fontSize:'1.5em', fontWeight:'400', color:'#757575', margin: '0px'}}>
                {`Hello ${sessionStorage.getItem('first_name')}`}
                </Text>
            </div>
            <div  style={{width: '30px'}}/>
            { (sessionStorage.getItem('uid') !== null && sessionStorage.getItem('type') === 'business') &&
            <Button type="primary" icon="plus" onClick={this.registerOrganisation}>   
                Create Organisation 
            </Button>
            }
            <div  style={{width: '30px'}}/>
            <Button  type="danger" onClick={this.logOut}>
                Logout
            </Button>
            <div  style={{width: '30px'}}/>
            <div>
            <Icon type="notification" size={64} theme="twoTone" />
              <Badge count={1} overflowCount={10}></Badge>
            </div>
            <div  style={{width: '30px'}}/>
            <Avatar size={48} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{sessionStorage.getItem('first_name').toUpperCase().charAt(0) || 'J'}</Avatar>

            </div>

            </div>
           
            </div>
        )
    })

    render() {
     
        return(
            <div>
            <this.TopBar/>
            </div>
        )
        
    };
};

export default HorizontalComponent;