const {countries} = require('countries-list')
let names = []
let currency = []

Object.values(countries).filter(country => country.continent === 'AF').forEach(item => {
   names.push(item.name)
   currency.push(item.currency)
})

const getCountries = () => {
    return names.sort()
}

const getCurrencies = () => {
    return currency.sort()
}

const getCountryPrefix = (country) => {
   let a =  Object.values(countries).find(item => {
       return item.name.toLocaleLowerCase() === country.toLocaleLowerCase()
    })
    if(a) return `+${a.phone}`
    return ''
}

module.exports = {
    getCountries: getCountries,
    getCurrencies: getCurrencies,
    getCountryPrefix: getCountryPrefix
}