import React, { Component } from 'react'
import { Title } from 'reactbulma'
import { Redirect } from 'react-router-dom'
import { Route, Switch } from 'react-router'
import { Layout, Icon, Statistic, Avatar } from 'antd';
import axios from 'axios'
import moment from 'moment'
import { inject, observer, Observer } from 'mobx-react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { SubTitle } from 'reactbulma'
import Header from '../../components/header'
import Loader from '../../components/LoaderComponent'
import TableComponent from '../../components/tableComponent'
import { columnFormatter, positionFormatter, boldFormatter, smallColumnFormatter } from '../../components/columnFormatter'
import StatusTag from '../../components/StatusTag'
import DeliveryDetails from './deliveryDetails'

@inject('store') 
@observer
class Deliveries extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      errors: [],
      selected_delivery: {}
    }
    this.refresh = false
  }

  fetchDeliveries = async (after_id = null, startDate = null, endDate = null, limit = this.state.pageSize) => {

    let organisation_id = sessionStorage.getItem('selected_org_id')

    const params = { organisation_id }
    if(startDate != null && endDate != null) params.start = startDate; params.end = endDate 

    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    this.setState({isLoading: true})
   
    await this.fetchDeliveryEmployees()
    
    axios({
      url: `${this.props.store.domain}/deliveries`,
      method: 'get',
      params,
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {

        const { data } = res.data

        data.forEach((element, index) => {
            element.key = index + 1
            element.created_at = moment(element.created_at).format('LLLL')
            element.completed_timestamp = element.completed_timestamp && moment(element.completed_timestamp).format('LLLL') || 'N/A'
            element.initiated_timestamp = element.initiated_timestamp && moment(element.initiated_timestamp).format('LLLL') || 'N/A'
        })

        this.props.store.updateValue('deliveries', data)
        this.setState({isLoading: false, errors: []})
        this.refresh = false
      })
      .catch( (error) => {
        if(error.response) {
          const { data } = error.response
          this.setState({ isLoading: false,errors: data.errors})
        } else {
          this.setState({isLoading: false})
          console.error(error)
        }
      })
  }

  fetchDeliveryEmployees = () => {
    if(this.props.store.employees.length !== 0) return Promise.resolve()
    
    let organisation_id = sessionStorage.getItem('selected_org_id')

    const params = { organisation_id }

    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    this.setState({isLoading: true})
    return axios({
      url: `${this.props.store.domain}/employees`,
      method: 'get',
      params,
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {

        const { data } = res.data

        data.forEach((element, index) => {
            element.key = index + 1
            element.name = `${element.first_name} ${element.last_name}`
        })

        this.props.store.updateValue('employees', data)
        this.setState({isLoading: false, errors: []})
        return Promise.resolve()
      })
      .catch( (error) => {
        if(error.response) {
          const { data } = error.response
          this.setState({ isLoading: false, errors: data.errors})
        } else {
          console.error(error)
        }
        return Promise.reject('Did not get employees')
      })
  }

  fetchUsers = (after_id = null) => {
    if(this.props.store.users.length !== 0) return Promise.resolve()

    let organisation_id = sessionStorage.getItem('selected_org_id')
    const params = {organisation_id,}
    const authStr = `Bearer ${sessionStorage.getItem('token')}` 
    this.setState({isLoading: true})

    return axios({
      url: `${this.props.store.domain}/users`,
      method: 'get',
      params,
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {
        const { data } = res.data

        //const { orders } = data
        
        data.forEach((element, index) => {
            element.key = index + 1
            element.created_at = element.created_at = moment(element.created_at).format('LLLL')
            element.name = `${element.first_name} ${element.last_name}`
        })
        
        this.props.store.updateValue('users', data)
        // this.props.store.updateValue('order_items', order_items)
        // this.props.store.updateValue('order_payments', payments)
        this.setState({isLoading: false, errors: []})
      })
      .catch( (error) => {
        if(error.response) {
          const { data } = error.response
          this.setState({ isLoading: false, errors: data.errors})
        } else {
          console.error(error)
        }

      })
  }

  onDatePickerChanged = (dates) => {
    this.fetchDeliveries(null, dates[0], dates[1])
  }

  rowEvents = {
    onClick: (e, row, rowIndex) => { 
      let item = {...row}
      this.setState({selected_delivery: item}, () => {
        //this.setState({showTabs: false})
        this.props.history.push(`/deliveries/${item.id}`);
      })
    }
  };

  componentDidMount() {
    if(this.props.store.deliveries.length === 0) this.fetchDeliveries()
  }

  componentDidUpdate(previousProps, previousState) {
    console.log('deliveries updated')
    if(previousProps.store.deliveries.length === 0 && !this.refresh) {
      this.refresh = true
      console.log('fetching em...')
      //this.fetchDeliveries()
    }
  }

  status(cell, row) {
    return (
        <StatusTag row={row} />
    );
  } 

  driverDetails = (cell, row) => {
    let driver = this.props.store.employees.find( item => {
      return item.id === row.delivery_employee_id
    })
    
    if(!driver) return null
   
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <div>
        <Avatar src={row.driver_image || ''} />
        </div>
        &ensp;
        <div>
          {row.driver_name}
        </div>
      </div>
    )
  }

  changeToKm = (cell, row) => {
    console.log({row})
    if(!row.distance) return 'N/A'
    if(row.distance < 1000) {
      return `${row.distance} m`
    }
    return `${row.distance/1000} Km`
  }

  Kard = (props) => {
    return(
      <div className=' card' style={{width: '90%', backgroundColor: props.background, marginRight: props.right}}>
        <Title is='5' style={{color: props.color, fontWeight: '400'}}>{props.title}</Title>
        <Title is='2' style={{color: props.color, fontWeight: '400'}}>{props.value}</Title>
        {/* <div style={{display: 'flex', flexDirection:'row', justifyContent:'', alignItems:'center'}}>
        
        <Icon type="car" theme="filled" style={{fontSize: '20px', color: 'white'}}/>
       
        </div> */}
       
      </div>
    )
  }

  Info = () => {
    let count = this.props.store.deliveries.length
    let in_progress = this.props.store.deliveries.filter( item => item.initiated === true && item.completed !== true && item.declined !== true).length
    let declined = this.props.store.deliveries.filter( item => item.completed !== true && item.declined === true).length
    let completed = this.props.store.deliveries.filter( item => item.initiated === true && item.completed === true && item.declined !== true).length

    return (
      <div className='grid_margin' style={{display: 'flex', flexDirection:'row', justifyContent:'space-around', marginBottom: '30px'}}>   
      {/* <Statistic title="All Deliveries" value={count} />  
      <Statistic title="Deliveries In progress" value={in_progress} />  
      <Statistic title="Deliveries Completed" value={completed} />  
      <Statistic title="Deliveries Cancelled" value={declined} />   */}
        <this.Kard title={'All deliveries'} value={count} background={'white'} color={'grey'} right={'20px'}/>
        <this.Kard title={'Deliveries In progress'} value={in_progress} background={'#63C2DE'} color={'white'} right={'20px'}/>
        <this.Kard title={'Deliveries Completed'} value={completed} background={'#F86C6B'} color={'white'} right={'20px'}/>
        <this.Kard title={'Deliveries Cancelled'} value={declined} background={'#5a99dc'} color={'white'} right={'0px'}/>
        <br/>
        <br/>
      </div>
    )
  }

  Delivery = observer(() => {
    let p = this.props.store.deliveries
    const columns = [
      { dataField: 'key', text: 'No',  align: 'center', ...positionFormatter}, 
      { dataField: 'id', text: 'Delivery id', hidden: true}, 
      { dataField: 'phone_number', text: 'Customer phone number',  ...boldFormatter, ...columnFormatter}, 
      { dataField: 'customer_name', text: 'Customer name', ...columnFormatter}, 
      { dataField: 'location_name', text: 'Customer address', ...boldFormatter, ...columnFormatter}, 
      { dataField: 'delivery_employee_id', text: 'Driver', formatter: this.driverDetails.bind(this), ...columnFormatter}, 
      { dataField: 'driver_name', text: 'Driver name', hidden: true}, 
      { dataField: 'distance', text: 'Distance', align: 'right', formatter: this.changeToKm.bind(this), ...columnFormatter},
      { dataField: 'initiated_timestamp', text: 'Time requested', ...columnFormatter}, 
      { dataField: 'completed', text: 'Delivery completed', hidden: true}, 
      { dataField: 'completed_timestamp', text: 'Time completed', ...columnFormatter}, 
      { dataField: 'status', text: 'Status', formatter: this.status.bind(this), ...columnFormatter}, 
      { dataField: 'code', text: 'Code',  align: 'right', ...boldFormatter, ...columnFormatter }, 
    ];

    return(
      <div>
      { p.length > 0 && 
        <div className='grid_margin card'>  
        <TableComponent columns={columns} data={p} rowEvents={ this.rowEvents }/>
        </div>
        }
      </div>
        
    )
  })

  render() {
    let p = this.props.store.deliveries
    return (
      <div className='def_page'>
      <Header title = { 'Deliveries' } showDatePicker ={true} onDatePickerChanged={this.onDatePickerChanged}/>
      <Loader name={'deliveries'} isLoading={this.state.isLoading} errors={this.state.errors} size={p.length}/>
    
      <Switch>
        
        <Route
          exact
          path={`${this.props.match.path}/`}
          render={ props => (
            <this.Delivery />
          )}
        />

        <Route
          exact
          path={`${this.props.match.path}/:id`}
          render={ props => (
            <DeliveryDetails 
            selected_delivery = {this.state.selected_delivery}
            {...props}
            />
          )}
        /> 
        </Switch>
      </div>
    )
  }
}

export default Deliveries