import { Descriptions, Tag } from "antd";
import { capitalize } from "lodash";
import React from "react";

const Main = ({ inventory }) => {
  const commaSeperatedNum = (num) =>
    `${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /=`;

  return (
    <Descriptions title="Item description" bordered>
      <Descriptions.Item label="Name">
        {capitalize(inventory.description)}
      </Descriptions.Item>
      <Descriptions.Item label="Status">
        <Tag color={inventory.status === "ADDED" ? "green" : "blue"}>
          {inventory.status.toUpperCase()}
        </Tag>  
      </Descriptions.Item>
      <Descriptions.Item label="SKU">{inventory.sku}</Descriptions.Item>
      <Descriptions.Item label="Units">{inventory.units}</Descriptions.Item>
      <Descriptions.Item label="Unit price">
        {commaSeperatedNum(inventory.unit_price)}
      </Descriptions.Item>
      <Descriptions.Item label="Total price">
        {commaSeperatedNum(inventory.total_price)}
      </Descriptions.Item>
      <Descriptions.Item label="Unit cost">
        {commaSeperatedNum(inventory.unit_cost)}
      </Descriptions.Item>
      <Descriptions.Item label="Total cost">
        {commaSeperatedNum(inventory.total_cost)}
      </Descriptions.Item>
      <Descriptions.Item label="Quantity in stock">
        {inventory.quantity_in_stock}
      </Descriptions.Item>
      <Descriptions.Item label="Reorder level">
        {inventory.reorder_level}
      </Descriptions.Item>
      <Descriptions.Item label="Reorder quantity">
        {inventory.reorder_quantity}
      </Descriptions.Item>
      <Descriptions.Item label="Unit of sale">
        {inventory.unit_of_sale}
      </Descriptions.Item>
      <Descriptions.Item label="Unit of order">
        {inventory.unit_of_order}
      </Descriptions.Item>
      <Descriptions.Item label="Conversion">
        {inventory.conversion}
      </Descriptions.Item>
      <Descriptions.Item label="Location">
        {inventory.location}
      </Descriptions.Item>
      <Descriptions.Item label="Created at">
        {new Date(inventory.created_at).toLocaleDateString()}
      </Descriptions.Item>
      <Descriptions.Item label="Last updated at">
        {new Date(inventory.updated_at).toLocaleDateString()}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default Main;
