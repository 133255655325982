import React, { useState, useCallback, Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import { inject, observer } from 'mobx-react';
import { SubTitle } from 'reactbulma'
import { Form, Button, Row, Col, Select, Upload, Icon, message, List, Card, Avatar } from 'antd'
import FormBuilder from 'antd-form-builder'
import SubHeader from "../../components/SubHeader";
import Descriptions from "../../components/Descriptions";
import Alerts from '../../components/Alerts'
import { getCountries, getCurrencies, getCountryPrefix } from '../../components/utils'

import TableComponent from '../../components/tableComponent'
import { columnFormatter, positionFormatter, boldFormatter } from '../../components/columnFormatter'
import StatusTag from '../../components/StatusTag'

const { Option } = Select;
const { Dragger } = Upload;

@inject('store') 
@observer
class AddEmployee extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      success: false,
      errors:[],
      auto_password: null,
      startFileUpload: false,
      employee_id: '',
      viewMode: false,
      showDeliveries: false,
      countryCode: null,
      employee_deliveries : []
    }
  }

  viewEmployee = () => {
    if(this.props.mode === 'edit'){
      let data = {}
      data['First Name'] = this.props.selected_employee.first_name || 'N/A'
      data['Last Name'] = this.props.selected_employee.last_name || 'N/A'
      data['Username'] = this.props.selected_employee.username || 'N/A'
      data['Email'] = this.props.selected_employee.email || 'N/A'
      data['Phone number'] = this.props.selected_employee.phone_number || 'N/A'
      data['Gender'] = this.props.selected_employee.gender || 'N/A'
      data['Address'] = this.props.selected_employee.address || 'N/A'
      data['Country'] = this.props.selected_employee.country || 'N/A'
      data['Date added'] = this.props.selected_employee.created_at && moment(this.props.selected_employee.created_at).format('LLLL') || 'N/A'
      data['Last updated'] = this.props.selected_employee.updated_at && moment(this.props.selected_employee.updated_at).format('LLLL') || 'N/A'
      data['Type of employee'] = this.props.selected_employee.type || 'N/A'
      data['Status'] = this.props.selected_employee.active ? 'Active' : 'Not active'
      

      if(this.props.selected_employee.type === 'delivery') {
        data['Delivery cost'] = `${this.props.selected_employee.delivery_cost || 0} ${sessionStorage.getItem('selected_org_currency')}`
        data['Wallet balance'] = `${this.props.selected_employee.wallet_balance || 0} ${sessionStorage.getItem('selected_org_currency')}`
        data['Cancel count'] = this.props.selected_employee.cancel_count || 0
      }
      
      return data
    }
  }

  handleDetailsMode = () => {
    this.setState({viewMode: true, showDeliveries: false})
  }

  handleEditMode = () => {
    this.setState({viewMode: false, showDeliveries: false})
  }

  handleTabThree = () => {
    this.setState({viewMode: false, showDeliveries: true})
    this.fetchEmployeeDeliveries()
  }

  onDatePickerChanged = (dates) => {
    this.fetchEmployeeDeliveries(null, dates[0], dates[1])
  }

  fetchEmployeeDeliveries = (after_id = null, startDate = null, endDate = null, limit = this.state.pageSize) => {

    let organisation_id = this.props.store.selected_organisation.id

    const params = { organisation_id }
    if(startDate != null && endDate != null) params.start = startDate; params.end = endDate

    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    
    axios({
      url: `${this.props.store.domain}/employees/${this.props.selected_employee.id}/deliveries`,
      method: 'get',
      params,
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {

        const { data } = res.data
        data.forEach((element, index) => {
          element.key = index + 1
          element.completed_timestamp = element.completed_timestamp && moment(element.completed_timestamp).format('LLLL') || 'N/A'
          element.initiated_timestamp = element.initiated_timestamp && moment(element.initiated_timestamp).format('LLLL') || 'N/A'
        })
        console.log({data})
        this.setState({employee_deliveries: data})
      })
      .catch( (error) => {
        if(error.response) {
          const { data } = error.response
          this.setState({
            errors: data.errors
          })
        } else {
          console.error(error)
        }
      })
  }

  fetchRoles = (after_id = null, limit = this.state.pageSize) => {

    let organisation_id = sessionStorage.getItem('selected_org_id')
    const params = { organisation_id }

    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    
    axios({
      url: `${this.props.store.domain}/employee-roles`,
      method: 'get',
      params,
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {
        const { data } = res.data
        data.forEach((element, index) => {
            element.key = index + 1
        })
        this.props.store.updateValue('roles', data)
      })
      .catch( (error) => {
        console.error(error)
      })
  }


  onDeleteItem = () => {
    console.log(this.props.selected_employee)
    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    const headers = {'Authorization': authStr}
    let payload = {}
    
    payload.id = sessionStorage.getItem('id')
    payload.type = sessionStorage.getItem('type')
   
    this.setState({isLoading: true})
    axios({
      url: `${this.props.store.domain}/employees/${this.props.selected_employee.id}`,
      method: 'delete',
      data: payload,
      headers
    })
    .then( (res) => {
     this.setState({isLoading: false, success: true, errors: []})
     message.success('Successfully deleted employee');
     this.props.fetchEmployees()
     this.goBack()
    })
    .catch( (error) => {
      message.error('Error deleted employee');
      if(error.response) {
        const { data } = error.response
        //this.setState({isLoading: false, success: false, errors: data.errors})
      } else {
        console.error(error)
      }
    })
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (err) return
     
    let payload = this.props.form.getFieldsValue()

    if(payload.phone_number){
      if(payload.phone_number.startsWith('0')){
        payload.phone_number = payload.phone_number.substr(1);
      }
      payload.phone_number = `${this.state.countryCode || ''}${payload.phone_number.replace(/\s+/g,'')}`
    }
    console.log({payload})
    if(this.props.mode === 'add') {
      this.addEmployee(payload)
    } else {
      this.updateEmployee(payload)
    }
  })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  addEmployee = (details) => {
   
    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    const headers = {'Authorization': authStr}
    let payload = {}
    
    payload.data = details
    payload.id = sessionStorage.getItem('id')
    payload.type = sessionStorage.getItem('type')
    payload.data.organisation_id = sessionStorage.getItem('selected_org_id')
    this.setState({isLoading: true})
    axios({
      url: `${this.props.store.domain}/employees`,
      method: 'post',
      data: payload,
      headers
    })
    .then( (res) => {
     const { data } = res.data 
     this.setState({isLoading: false, success: true, errors: [], startFileUpload: true, employee_id: data.id})
     this.props.fetchEmployees()
    })
    .catch( (error) => {
      if(error.response) {
        const { data } = error.response
        this.setState({isLoading: false, success: false, errors: data.errors})
      } else {
        console.error(error)
      }
    })

  }

  updateEmployee = (details) => {
    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    const headers = {'Authorization': authStr}
    let payload = {}
    
    payload.data = details
    payload.id = sessionStorage.getItem('id')
    payload.type = sessionStorage.getItem('type')
    payload.data.organisation_id = sessionStorage.getItem('selected_org_id')
    this.setState({isLoading: true})
    axios({
      url: `${this.props.store.domain}/employees/${this.props.selected_employee.id}`,
      method: 'put',
      data: payload,
      headers
    })
    .then( (res) => {
     const { data } = res.data 
     this.setState({isLoading: false, success: true, errors: [], startFileUpload: true})
     this.props.fetchEmployees()
    })
    .catch( (error) => {
      if(error.response) {
        const { data } = error.response
        this.setState({isLoading: false, success: false, errors: data.errors})
      } else {
        console.error(error)
      }
    })
  }

  componentDidMount() {
    if(this.props.selected_employee && this.props.mode === 'edit') {
      if(!this.props.selected_employee.id) return this.props.history.goBack();
      this.setState({ viewMode: true })
    }
    this.fetchRoles()
  }

  Kard = (props) => {
    return(
      <Card
      style={{ width: 300 }}
      hoverable
      cover={
        <img  style={{ width: 300, height: 300, backgroundSize: 'cover', overflow: 'hidden' }}
          alt="category_image"
          src={props.image}
        />
      }>
       <Button type="danger" icon={'delete'} style={{textAlign: 'center', width: '100%'}} onClick={evt => this.deleteProductImage(props.image)}>Delete image</Button>  
    </Card>
    )
}

  Verify = (props) => {
    
  }

  randomFour = () => {
    return Math.floor(1000 + Math.random() * 9000)
  }

  autoGeneratePassword = () => {
    let pass = this.randomFour().toString()
    this.setState({auto_password: pass})
  }

  AutoGenPass = props => (
    <Row gutter={1}>
      <Col span={24}>
        <Button type="primary" icon="key" shape="round" onClick={this.autoGeneratePassword}>Auto generate password</Button>{' '}
      </Col>
    </Row>
  )
 
  status(cell, row) {
    return (
        <StatusTag row={row} />
    );
  } 

  changeToKm = (cell, row) => {
    if(!row.distance) return 'N/A'
    if(row.distance < 1000) {
      return `${row.distance} m`
    }

    return `${row.distance/1000} Km`
  }
  render() {
    const employeeid = this.props.mode === 'edit' ? this.props.selected_employee.id : this.state.employee_id
    const employee_roles = []
    this.props.store.roles.forEach(role => {
      employee_roles.push({value: role.id, label: role.role})
    })
    const props = {
        name: 'images',
        multiple: false,
        accept: 'image/x-png, image/png, image/gif, image/jpeg',
        headers: { authorization:  `Bearer ${sessionStorage.getItem('token')}`},
        action: `${this.props.store.domain}/employees/${employeeid}/upload`,
        onChange(info) {
          
          const { status } = info.file;
          if (status !== 'uploading') {
         
          }
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
    };

    const  UploadImage = (props) => {
        return(
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                <Icon type="picture" theme="twoTone" />
                </p>
                <p className="ant-upload-text">Click or drag employee image to upload</p>
                <p className="ant-upload-hint">
                Up to 1 images allowed. Maximum size per image should be 3MB
                </p>
            </Dragger>
        )
    }

    const meta = {
      columns: 6,
      gutter: 50,
      formItemLayout: null, // Must set this for inline layout
      colon: true,
      fields: [
        { key: 'first_name', label: 'First name', colSpan: 2, required: true, initialValue: this.props.selected_employee && this.props.selected_employee.first_name},
        { key: 'last_name', label: 'Last name', colSpan: 2, required: true, initialValue: this.props.selected_employee && this.props.selected_employee.last_name },
        { key: 'country', label: 'Country', colSpan: 2, required: true, 
          initialValue: this.props.selected_employee && this.props.selected_employee.country, 
          widget: 'select', options: getCountries(),
          widgetProps: {
            showSearch: true,
            onChange: (e) => {
              this.setState({countryCode: getCountryPrefix(e)})
            },
          },
        },
        { key: 'phone_number', label: 'Phone number', colSpan: 2, required: true, 
            widgetProps: {addonBefore: this.state.countryCode}, 
            disabled: this.state.countryCode === null && this.props.mode === 'add',
            initialValue: this.props.selected_employee && this.props.selected_employee.phone_number
        },
        
        { key: 'email', label: 'Email', colSpan: 2, initialValue: this.props.selected_employee && this.props.selected_employee.email},
        { key: 'gender', label: 'Gender', colSpan: 2,  required: true, widget: 'select', options: ['male', 'female', 'other'] , initialValue: this.props.selected_employee && this.props.selected_employee.gender},
        { key: 'address', label: 'Address', colSpan: 2, initialValue: this.props.selected_employee && this.props.selected_employee.address },
        { key: 'role', label: 'Role', colSpan: 2, required: true, widget: 'select', options: employee_roles, initialValue: this.props.selected_employee && this.props.selected_employee.role},
        { key: 'active', label: 'Active', colSpan: 2, widget:'switch', help: 'Is employee active in the company?', initialValue: this.props.selected_employee && this.props.selected_employee.active || true},
        { key: 'password', label: 'Password', colSpan: 2, required: this.props.mode === 'add' ? true : false, initialValue: this.state.auto_password === null ? '' : this.state.auto_password },
        { key: 'auto_gen_password', label: '', colSpan: 1,  widget: this.AutoGenPass, widgetProps: { textAlign: 'right' }},
        { key: 'auto_send_password', label: '', colSpan: 2, widget:'checkbox', help: 'Send password to phone number after submit', initialValue: true }
      ],
    }

    const employee_deliveries_columns = [
      { dataField: 'key', text: 'No',  align: 'center', ...positionFormatter}, 
      { dataField: 'id', text: 'Delivery id', ...columnFormatter, ...boldFormatter},
      { dataField: 'driver_name', text: 'Driver name', hidden: true}, 
      { dataField: 'phone_number', text: 'Customer phone number',  ...boldFormatter, ...columnFormatter}, 
      { dataField: 'customer_name', text: 'Customer name', ...columnFormatter}, 
      { dataField: 'location_name', text: 'Customer address', ...boldFormatter, ...columnFormatter}, 
      { dataField: 'distance', text: 'Distance', align: 'right', formatter: this.changeToKm.bind(this), ...columnFormatter},
      { dataField: 'initiated_timestamp', text: 'Time requested', ...columnFormatter}, 
      { dataField: 'completed', text: 'Delivery completed', hidden: true}, 
      { dataField: 'completed_timestamp', text: 'Time completed', ...columnFormatter}, 
      { dataField: 'status', text: 'Status', formatter: this.status.bind(this), ...columnFormatter}, 
      { dataField: 'code', text: 'Code',  align: 'right', ...boldFormatter, ...columnFormatter }, 
    ];
  
    return (
      <div className='grid_margin'>
      { this.props.mode === 'edit' && 
      <SubHeader title={`${this.props.selected_employee.first_name} ${this.props.selected_employee.last_name}`}
        tab_one_text={'Employee details'}
        tab_two_text={'Edit employee'}
        tab_three_text={'Employee deliveries'}
        handleDetailsMode={this.handleDetailsMode}
        handleEditMode={this.handleEditMode}
        handleTabThree={this.handleTabThree}
        goBack={this.goBack}
        showDatePicker={this.state.showDeliveries}
        onDatePickerChanged={this.onDatePickerChanged}
      />
      }

      { this.props.mode === 'edit' && this.state.viewMode && !this.state.showDeliveries &&
        <Descriptions data={this.viewEmployee()} onDeleteItem={this.onDeleteItem} showDelete={true} deleteText={'Delete employee'}/>
      }

      {!this.state.viewMode && !this.state.showDeliveries &&
      <Form layout="vertical" onSubmit={this.handleSubmit}>
        <FormBuilder form={this.props.form} meta={meta} />
        <Form.Item> 
        {!this.state.viewMode && this.props.mode === 'edit' && this.props.selected_employee.image_url &&
         
         <Avatar size={128} src={this.props.selected_employee.image_url} />
        }
        {(this.state.startFileUpload || this.props.mode === 'edit') &&  
          <div>
          <br/> 
          <UploadImage {...props}/>
          <br/>
          </div>
        }
    
        <Alerts 
        success={this.state.success} 
        errors={this.state.errors}
        description = {`${this.props.mode === 'add' ? 'Added employee successfully': 'Success'}`}
        />
        <br/>
        <br/>
          <Button htmlType="submit" type="primary" loading={this.state.isLoading} style={{ width: '100%' }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
      }

     { this.state.showDeliveries &&
       <div className='card'>
      <TableComponent columns={employee_deliveries_columns} data={this.state.employee_deliveries} />
        </div>
     }

     </div>
    )
  }

}

export default Form.create()(AddEmployee)

