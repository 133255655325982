import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Form, Button, Row, Col, Select, Layout, Tabs, message } from 'antd';
import axios from 'axios'
import { inject, observer, Observer } from 'mobx-react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { SubTitle, Title } from 'reactbulma'
import Header from '../../components/header'
import TableComponent from '../../components/tableComponent'
import { columnFormatter, positionFormatter, boldFormatter } from '../../components/columnFormatter'
import FormBuilder from 'antd-form-builder';

const { TabPane } = Tabs;

@inject('store') 
@observer
class Roles extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      redirect: false,
      data: {},
      tab: '0',
      selected_role: null,
      editRole: false
    }

  }

  fetchRoles = (after_id = null, limit = this.state.pageSize) => {

    let organisation_id = sessionStorage.getItem('selected_org_id')
    const params = { organisation_id }

    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    
    axios({
      url: `${this.props.store.domain}/employee-roles`,
      method: 'get',
      params,
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {

        const { data } = res.data

        data.forEach((element, index) => {
            element.key = index + 1
        })

        this.props.store.updateValue('roles', data)
        console.log({data})

      })
      .catch( (error) => {
        if(error.response) {
          const { data } = error.response
          this.setState({
            errors: data.errors
          })
        } else {
          console.error(error)
        }
      })
  }

  postRole = (role, perms) => {
    let organisation_id = sessionStorage.getItem('selected_org_id')
    const params = { organisation_id }

    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    let payload = {}
    
    payload.data = {}
    payload.id = sessionStorage.getItem('id')
    payload.type = sessionStorage.getItem('type')
    payload.data.organisation_id = sessionStorage.getItem('selected_org_id')
    payload.data.role = role
    payload.data.permissions = perms
    this.setState({isLoading: true})
    console.log(payload)
    axios({
      url: `${this.props.store.domain}/employee-roles`,
      method: 'post',
      data: payload,
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {
        message.info('Role has been created successfully!')
        this.fetchRoles()
      })
      .catch( (error) => {
        message.error('An error occured while creating a role. Check if the role name is already defined')
        console.error(error)
      })
  }

  updateRole = (role, perms) => {

    let organisation_id = sessionStorage.getItem('selected_org_id')
    const params = { organisation_id }

    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    let payload = {}
    
    payload.data = {}
    payload.id = sessionStorage.getItem('id')
    payload.type = sessionStorage.getItem('type')
    payload.data.organisation_id = sessionStorage.getItem('selected_org_id')
    payload.data.role = role
    payload.data.permissions = perms
    axios({
      url: `${this.props.store.domain}/employee-roles/${this.state.selected_role.id}`,
      method: 'put',
      data: payload,
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {
        this.fetchRoles()
        this.cancelEditRole()
        message.info('Role has been updated!')
      })
      .catch( (error) => {
        message.error('An error occurred while updating role.')
      })
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (err) return
      
      let payload = this.props.form.getFieldsValue()
      let json = {}
      let role_name = payload.role_name
      delete payload.role_name
      Object.keys(payload).forEach(item => {
          console.log(item)
          let k = {}
          k.view = payload[item] && payload[item].includes('View') || false
          k.add = payload[item] && payload[item].includes('Add') || false
          k.edit = payload[item] && payload[item].includes('Edit/Update') || false
          k.delete = payload[item] && payload[item].includes('Delete') || false
          json[item] = k
      })
      if(!this.state.selected_role) {
        this.postRole(role_name, json)
      }else{
        this.updateRole(role_name, json)
      }
    })
  }

  onTabClick = (key) => {
    if(key === '1') this.setState({tab: '0'})
    if(key === '2') this.setState({tab: '1', selected_role: null, editRole: false})
  }

  rowEvents = {
    onClick: (e, row, rowIndex) => { 
      let item = {...row}
      this.setState({selected_role: item, editRole: true}, () => {
        console.log(item)
      })
    }
  };


  cancelEditRole = (e) => {
    this.setState({selected_role: null, editRole: false})
  }

  deleteRole = (e) => {
    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    const headers = {'Authorization': authStr}
    let payload = {}
    
    payload.id = sessionStorage.getItem('id')
    payload.type = sessionStorage.getItem('type')
   
    this.setState({isLoading: true})
    axios({
      url: `${this.props.store.domain}/employee-roles/${this.state.selected_role.id}`,
      method: 'delete',
      data: payload,
      headers
    })
    .then( (res) => {
     this.setState({isLoading: false, success: true, errors: []})
     message.success('Successfully deleted role');
     this.fetchRoles()
     this.setState({selected_role: null, editRole: false})
    })
    .catch( (error) => {
      message.error('Error deleting role');
      if(error.response) {
        const { data } = error.response
        //this.setState({isLoading: false, success: false, errors: data.errors})
      } else {
        console.error(error)
      }
    })
    
  }

  componentDidMount() {
      if(this.props.store.roles.length === 0) this.fetchRoles()
  }

  Roles = observer((props) => {
    let p = this.props.store.roles

    const columns = [
      { dataField: 'key', text: 'No',  align: 'center', ...positionFormatter}, 
      { dataField: 'role', text: 'Description',  ...boldFormatter, ...columnFormatter}, 
      { dataField: 'assignees', text: 'Assignees',}, 
     
    ];

    return (
      <div>
       <TableComponent columns={columns} data={p}  tableName='roles' rowEvents={ this.rowEvents }/>
      </div>
    )
  })

  getRoleForm = (permissions) => {
    console.log({permissions})
    const options = ['View', 'Add', 'Edit/Update', 'Delete']
    const items = ['organisations', 'products', 'customer_orders', 'inventory', 'deliveries','devices', 'employees', 'customers', 
                   'sales_reports', 'purchase_orders', 'stock_updates', 'expenses', 'vendors', 'settings', 'billing'   ]
    let field = []
    items.forEach(item => {
     let k = {}
     k.key = item
     k.label = item
     k.widget = 'checkbox-group'
     k.options = options
     if(permissions) {
      let perm = permissions[item] 
      k.initialValue= [ perm.view && 'View', perm.add && 'Add', perm.edit && 'Edit/Update', perm.delete && 'Delete']
     }
     field.push(k)
    })               
    const meta = {
        columns: 1,
        gutter: 0,
        fields: [
            { key: 'role_name', label: 'Role name', required: true, initialValue: this.state.selected_role && this.state.selected_role.role || '', tooltip: 'Role name eg Supervisor, Management etc', },
            ...field
        ]
    }

    return meta
  } 

  render() {
    
   
    return (
      <div className='def_page'>
      <Tabs className='tab_margin' defaultActiveKey="1" onTabClick={this.onTabClick} style={{marginTop: '0px !important'}}>
          <TabPane tab="Roles" key="1"></TabPane>
          <TabPane tab="Add new role" key="2"></TabPane>
      </Tabs>

      <div className='grid_margin card'>
      
      {this.state.tab === '0' && !this.state.editRole &&
       <div>
          <this.Roles/>
       </div>
      }

      {this.state.tab === '1' && 
      <Form layout="vertical" onSubmit={this.handleSubmit} style={{width: '40%'}}>
        <SubTitle is='6'>A role defines what a person or group of people are suppossed to access</SubTitle>
        <FormBuilder form={this.props.form} meta={this.getRoleForm()}/>  
        <Button type='primary' htmlType="submit" loading={false}>Save role</Button>
        <br/><br/><br/>
      </Form>
      }

      {this.state.tab === '0' && this.state.editRole &&
      <Form layout="vertical" onSubmit={this.handleSubmit} style={{width: '40%'}}>
        <Title is='5'>Edit role</Title>
        <FormBuilder form={this.props.form} meta={this.getRoleForm(this.state.selected_role && this.state.selected_role.permissions)}/>  
        <Button type='primary' htmlType="submit" loading={false}>Update role</Button>
        <Button type='danger' onClick={this.cancelEditRole} style={{alignText: 'right'}}>Cancel</Button>
        <Button type='danger' onClick={this.deleteRole} style={{alignText: 'right'}}>Delete Role</Button>
        <br/><br/><br/>
      </Form>
      }
      </div>
      </div>
    )
  }
}

export default Form.create()(Roles)