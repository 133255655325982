import React, { Component } from "react";
import moment from 'moment'
import { DatePicker } from 'antd';
import { Title, SubTitle } from 'reactbulma'

const { RangePicker } = DatePicker;
const dateFormat = 'DD-MMM-YYYY';
class Header extends Component {

    render() {
        return (
            <div className='main_header header_margin' style={{ display: 'flex' , float: 'left', justifyContent: 'space-between'}}>
                <Title is='4'>{this.props.title}</Title>

                { this.props.showDatePicker &&
                    <RangePicker
                      defaultValue={[moment(), moment()]}
                      format={dateFormat}
                      bordered={false}
                      onChange = {this.props.onDatePickerChanged}
                    />
                }
            </div>
        )
    }
};

export default Header;