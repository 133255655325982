import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { inject, observer, Observer } from 'mobx-react';
import { Button, Radio, Icon } from 'antd';
import { Title, SubTitle } from 'reactbulma';
import 'bootstrap/dist/css/bootstrap.min.css';
import BootstrapTable from 'react-bootstrap-table-next';

import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink, CSVDownload } from "react-csv";

import { Tag } from 'antd';

@inject('store') 
class TableComponent extends Component {

    activeStatusFormatter(cell, row) {
        if (row.is_active) {
          return (<Tag color="#108ee9">active</Tag>);
        }
        return ( <Tag color="#f50">not active</Tag>)
    }

    tableNodata = (string) => {
        return (
          <div style={{paddingTop:'100px', paddingBottom:'100px', margin:'auto', textAlign:'center', backgroundColor:'#f8f8f8'}}>
                 <SubTitle is='5'> {`No data found!`}</SubTitle>
          </div>
        )
    }

    render() {
      let headers = []

      this.props.columns.forEach( item => {
       let obj = {}
       obj.label = item.text
       obj.key = item.dataField
       headers.push(obj)
      })

      if(this.props.tableName === 'products'){
        headers = headers.filter( e => {
          console.log(e.key)
          return e.key !== 'categories' && e.key !== 'key' && e.key !== 'active'
        })
      }

      console.log({headers});
      

      let last_page = this.props.store.table_pages[`${this.props.tableName}`]
      
      if(isNaN(last_page)) last_page = 1
      if(last_page < 1) last_page = 1

     
      const options = {
            page: last_page || 1,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Previous page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            sizePerPageList: [
            { text: '10', value: 10},
            { text: '50', value: 50},
            { text: '100', value: 100},
            { text: '500', value: 500},
            { text: 'Show all data', value: this.props.data.length }
            ],
            onPageChange: (page, sizePerPage) => {
              console.log('Page change!!!');
              console.log('Newest size per page:' + sizePerPage);
              console.log('Newest page:' + page);
              this.props.store.updateTablePages(this.props.tableName, page)
            }
        };

        return (
          <div>
            <BootstrapTable 
            keyField={this.props.keyField || 'pos'}
            bootstrap4={true}
            noDataIndication={this.tableNodata(this.props.noDataIndication)}
            data={ this.props.data } 
            columns={ this.props.columns } 
            filter={ filterFactory() } 
            pagination={ paginationFactory(options) }
            hover
            bordered={ true }
            //rowStyle={ rowStyle } 
            //selectRow={ selectRow }
            rowEvents={ this.props.rowEvents }
            />
            <hr/>
          
            <CSVLink 
              data={this.props.data} 
              filename={`${this.props.dataName || 'download'}_${new Date().toLocaleDateString()}.csv`}
              target="_blank"
              headers={headers} >
                Download CSV
            </CSVLink>

            </div>
        )
    }
};

export default TableComponent;