import React, { Component } from 'react'
import { Title } from 'reactbulma'
import { Route, Switch } from 'react-router'
import { Tabs } from 'antd';
import axios from 'axios'
import { inject, observer, Observer } from 'mobx-react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { SubTitle } from 'reactbulma'
import Header from '../../components/header'
import TableComponent from '../../components/tableComponent'
import Loader from '../../components/LoaderComponent'
import { columnFormatter, positionFormatter, boldFormatter } from '../../components/columnFormatter'

import AddEmployee from './addEmployee'
import DeliveryStaff from './deliveryStaff'
import EmployeeDeliveries from './employeeDeliveries'
import StatusTag from '../../components/StatusTag'

const { TabPane } = Tabs;
@inject('store') 
@observer
class Employees extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      errors: [],
      selected_employee: {},
      showTabs: true,
      showLoader: true
    }
    this.refresh = false
  }

  onTabClick = (key) => {
    this.setState({showLoader: false})
    if(key === '1') { 
      this.props.history.push('/employees')
      this.setState({showLoader: true})
    }
    if(key === '2') this.props.history.push('/employees/add')
    if(key === '3') this.props.history.push('/employees/delivery-staff')
  }

  rowEvents = {
    onClick: (e, row, rowIndex) => { 
      let item = {...row}
      console.log(item)
      this.setState({selected_employee: item}, () => {
        this.setState({showTabs: false})
        this.props.history.push(`/employees/${item.id}/details`);
      })

    }
  };

  fetchEmployees = (after_id = null, limit = this.state.pageSize) => {
    
    let organisation_id = sessionStorage.getItem('selected_org_id')

    const params = { organisation_id }

    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    this.setState({isLoading: true})
    axios({
      url: `${this.props.store.domain}/employees`,
      method: 'get',
      params,
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {

        const { data } = res.data

        data.forEach((element, index) => {
            element.key = index + 1
            element.name = `${element.first_name} ${element.last_name}`
        })

        this.props.store.updateValue('employees', data)
        this.setState({isLoading: false, errors: []})
        this.refresh = false
      })
      .catch( (error) => {
        if(error.response) {
          const { data } = error.response
          this.setState({ isLoading: false, errors: data.errors})
        } else {
          console.error(error)
        }
      })
  }

  componentDidMount() {
      if(this.props.store.employees.length === 0) this.fetchEmployees()
  }

  componentDidUpdate(previousProps, previousState) {
    console.log({previousProps})
    if(previousState.showTabs === false) {
      this.setState({showTabs: true})
    }
    
    if(previousProps.store.employees.length === 0 && !this.refresh) {
      this.refresh = true
      //this.fetchEmployees()
    }
  }

  status(cell, row) {
    return (
        <StatusTag row={row} />
    );
  } 

  Employees = observer(() => {
    let p =this.props.store.employees
    const columns = [
      { dataField: 'key', text: 'No',  align: 'center', ...positionFormatter}, 
      { dataField: 'name', text: 'Name',  ...boldFormatter, ...columnFormatter}, 
      { dataField: 'type', text: 'Type', ...columnFormatter}, 
      { dataField: 'phone_number', text: 'Phone', ...columnFormatter}, 
      { dataField: 'address', text: 'Address', ...columnFormatter}, 
      { dataField: 'email', text: 'Email', ...columnFormatter}, 
      { dataField: 'gender', text: 'Gender', ...columnFormatter},
      { dataField: 'active', text: 'Status', formatter: this.status.bind(this), ...columnFormatter}, 
    ];

    if(p.length === 0 ) return null 
    return (
      <div className='grid_margin card'>
      <TableComponent columns={columns} data={p} dataName={'employees'} rowEvents={ this.rowEvents }/>
      </div>
    )
  })

  render() {
    return (
      <div className='def_page'>
      <Header title = { 'Employees' } />
     
      {this.state.showTabs &&
        <Tabs className='grid_margin' defaultActiveKey="1" onTabClick={this.onTabClick} style={{marginTop: '0px !important'}}>
        <TabPane tab="Employees" key="1"></TabPane>
        <TabPane tab="Add new employee" key="2"></TabPane>
        { this.props.store.selected_organisation.has_delivery_service && <TabPane tab="Delivery staff" key="3"></TabPane> }
        </Tabs>
      }

      

      <Switch>
        <Route
          exact
          path={`${this.props.match.path}/`}
          render={ props => (
           <div>
            <Loader name={'employees'} isLoading={this.state.isLoading} errors={this.state.errors} size={this.props.store.employees.length}/>
            <this.Employees/>
            </div>
          )}
        />

        <Route
          exact
          path={`${this.props.match.path}/add`}
          render={ props => (
            <AddEmployee 
            fetchEmployees={this.fetchEmployees}
            mode={'add'}
            {...props}
            />
          )}
        /> 

        <Route
          
          path={`${this.props.match.path}/:id/details`}
          render={ props => (
            <AddEmployee 
            fetchEmployees={this.fetchEmployees}
            showEmployeeDeliveries={this.showEmployeeDeliveries}
            mode={'edit'}
            selected_employee={this.state.selected_employee}
            {...props}
            />
          )}
        /> 

        <Route
          
          path={`${this.props.match.path}/:id/deliveries`}
          render={ props => (
            <EmployeeDeliveries 
            //fetchEmployees={this.fetchEmployees}
            mode={'edit'}
            selected_employee={this.state.selected_employee}
            {...props}
            />
          )}
        /> 

        <Route
          exact
          path={`${this.props.match.path}/delivery-staff`}
          render={ props => (
            <DeliveryStaff 
           
            {...props}
            />
          )}
        /> 
        </Switch>
      </div>
    )
  }
}

export default Employees