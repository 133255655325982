import React, { Component } from "react";
import axios from "axios";
import { capitalize } from "lodash";
import {
  columnFormatter,
  positionFormatter,
  boldFormatter,
} from "../../../components/columnFormatter";
import TableComponent from "../../../components/tableComponent";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
    };
  }

  fetchStores() {
    const url = `https://foodsapi.mobitill.com/adhoc/store/get-many/${this.props.match.params.id}?page_limit=10000`;
    const authStr = `Bearer ${sessionStorage.getItem("token")}`;

    axios({ method: "GET", url, headers: { Authorization: authStr } })
      .then((res) => {
        const data = res.data.stores.map((v) => ({
          id: v.store_id,
          name: capitalize(v.store_name),
          description: capitalize(v.store_description),
          created_at: new Date(v.store_created_at).toLocaleDateString(),
        }));

        this.setState((prevState) => ({
          stores: [...prevState.stores, ...data],
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.fetchStores();
  }

  render() {
    const columns = [
      { dataField: "id", text: "No", align: "center", ...positionFormatter },
      { dataField: "name", text: "Name", ...boldFormatter, ...columnFormatter },
      { dataField: "description", text: "Description", ...columnFormatter },
      { dataField: "created_at", text: "Date added", ...columnFormatter },
    ];

    return <TableComponent columns={columns} data={this.state.stores} />;
  }
}

export default Main;
