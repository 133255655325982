import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import List from "./List";
import Item from "./item";

const Main = () => {
  const { path } = useRouteMatch();

  const routes = [
    {
      path: `${path}/list`,
      component: <List />,
    },
    {
      path: `${path}/:id`,
      component: <Item />,
    },
  ];

  return (
    <Switch>
      {routes.map((route) => (
        <Route path={route.path} children={route.component} />
      ))}

      <Redirect path={path} to={`${path}/list`} />
    </Switch>
  );
};

export default Main;
