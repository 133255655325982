import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import {
	diff,
} from 'deep-object-diff'
import { inject, observer } from 'mobx-react'
import {
	Form,
	Button,
	Row,
	Col,
	Select,
	Card,
	Upload,
	Icon,
	message,
	List,
} from 'antd'
import FormBuilder from 'antd-form-builder'
import SubHeader from '../../components/SubHeader'
import Descriptions from '../../components/Descriptions'
import Alerts from '../../components/Alerts'

const { Option } = Select
const { Dragger } = Upload

@inject('store')
@observer
class AddProduct extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			success: false,
			errors: [],
			categories: undefined,
			counter: 1,
			viewMode: false,
			startFileUpload: false,
			product_id: '',
			selected_product: {},
			selected_product_categories: [],
			fields: [],
		}
		this.children = []
		this.prices = []
		this.categories = []
		this.product_added = false
	}

	viewProduct = () => {
		if (this.props.mode === 'edit') {
			let data = {}
			data.Name = this.props.selected_product.description || 'N/A'
			data['Product Id'] = this.props.selected_product.id || 'N/A'
			data.Organisation = this.props.store.selected_organisation.name || 'N/A'
			data.Details = this.props.selected_product.details || 'N/A'
			data.Location = this.props.selected_product.location || 'N/A'
			data.VAT = this.props.selected_product.vat || 0
			data.Quantity = this.props.selected_product.quantity || 0
			data['Date created'] =
				moment(this.props.selected_product.created_at).format('LLLL') || 'N/A'
			data['Last updated'] =
				moment(this.props.selected_product.updated_at).format('LLLL') || 'N/A'
			data['Unit cost'] = this.props.selected_product.unit_cost || 0
			data['Reorder Level'] = this.props.selected_product.reorder_level || 0
			data['Reorder quantity'] =
				this.props.selected_product.reorder_quantity || 0
			data['Unit of Sale'] = this.props.selected_product.unit_of_sale || 'N/A'
			data['Unit of Order'] = this.props.selected_product.unit_of_order || 'N/A'
			data.Conversion = this.props.selected_product.conversion || 0
			data['Expiry date'] =
				(this.props.selected_product.expiry_date &&
					moment(this.props.selected_product.expiry_date).format('LLLL')) ||
				'N/A'
			data.Code = this.props.selected_product.code || 'N/A'
			data.SKU = this.props.selected_product.sku || 'N/A'
			data['Price'] = `${sessionStorage.getItem('selected_org_currency')} ${
				this.props.selected_product.price || 0
			}`
			data['Active'] = this.props.selected_product.active ? 'Yes' : 'No'

			if (this.props.selected_product.prices) {
				this.props.selected_product.prices.forEach((price, index) => {
					data[`Price ${index + 1}`] = `${
						price.description
					}  -  ${sessionStorage.getItem('selected_org_currency')} ${
						price.amount
					}`
				})
			}

			if (this.props.selected_product.categories) {
				let c = []
				this.props.selected_product.categories.forEach(id => {
					const i = this.props.store.product_categories.filter(item => {
						return item.id.toString() === id
					})
					if (i[0]) c.push(i[0].name)
				})
				data.categories = c.join(', ') || 'No category selected'
			}

			return data
		}
	}

	goBack = () => {
		this.props.history.goBack()
	}

	addCategoryOptions = () => {
		this.props.store.product_categories.forEach(item => {
			this.children.push(<Option key={item.id}>{item.name}</Option>)
		})
	}

	handleCategoryChange = (value, option) => {
		this.setState({ categories: value })
	}

	handleDetailsMode = () => {
		this.setState({ viewMode: true })
	}

	handleEditMode = () => {
		this.setState({ viewMode: false })
	}

	addNewProduct = () => {
		this.props.form.resetFields()
		this.setState({
			success: false,
			errors: [],
			startFileUpload: false,
			product_id: '',
		})
		this.product_added = false
	}

	validateValues = data => {
		console.log({ data })
		let errors = []
		const nums = ['price', 'vat']
		Object.keys(data).forEach(key => {
			if (nums.includes(key)) {
				if (data[key] !== undefined && typeof data[key] !== 'number') {
					errors.push(`${key} should be a number`)
				}
			}
		})
		if (data.prices) {
			data.prices.forEach(price => {
				if (
					(price.amount !== undefined && typeof price.amount !== 'number') ||
					(price.no_of_items !== undefined &&
						typeof price.no_of_items !== 'number')
				) {
					errors.push(
						`extra prices' amount and number of items should be a number`,
					)
				}
				if (price.description === undefined) {
					errors.push('You must include price description')
				}
			})
		}
		return errors
	}

	onDeleteItem = () => {
		const authStr = `Bearer ${sessionStorage.getItem('token')}`
		const headers = { Authorization: authStr }
		let payload = {}

		payload.id = sessionStorage.getItem('id')
		payload.type = sessionStorage.getItem('type')

		this.setState({ isLoading: true })
		axios({
			url: `${this.props.store.domain}/products/${this.props.selected_product.id}`,
			method: 'delete',
			data: payload,
			headers,
		})
			.then(res => {
				this.setState({ isLoading: false, success: true, errors: [] })
				message.success('Successfully deleted product')
				this.props.fetchProducts()
				this.goBack()
			})
			.catch(error => {
				message.error('Error deleting product')
				if (error.response) {
					const { data } = error.response
					//this.setState({isLoading: false, success: false, errors: data.errors})
				} else {
					console.error(error)
				}
			})
	}

	handleSubmit = evt => {
		evt.preventDefault()

		this.props.form.validateFields((err, values) => {
			if (err) return

			let prices = []
			let payload = this.props.form.getFieldsValue()
			payload.categories = this.state.categories
			Object.keys(payload).forEach(item => {
				if (item.includes('price_')) {
					let pos = item.split('_')[1]
					let obj = {}
					obj.description = payload[`price_${pos}`]
					obj.price = payload[`value_${pos}`]
					obj.id = payload[`remove_${pos}`]
					obj.no_of_items = payload[`no_of_items_${pos}`] || 1
					prices.push(obj)
					delete payload[`price_${pos}`]
					delete payload[`value_${pos}`]
					delete payload[`no_of_items_${pos}`]
					delete payload[`remove_${pos}`]
				}
			})

			payload.prices = prices
			const errors = this.validateValues(payload)
			if (errors.length > 0) return this.setState({ errors })
			console.log('no error')
			if (this.props.mode === 'add') {
				Object.keys(payload).forEach(
					key => payload[key] === undefined && delete payload[key],
				)
				console.log({ product_added: this.product_added })
				this.addProduct(payload)
			}

			if (this.props.mode === 'edit') {
				let changed = diff(this.props.selected_product, payload)
				changed.categories = this.state.categories
				let modified_prices = []
				let new_prices = []
				prices.forEach(a => {
					if (!a.id) return new_prices.push(a)
					this.props.selected_product.prices.forEach(b => {
						if (a.id === b.id) {
							if (
								a.description !== b.description ||
								a.price !== b.amount ||
								a.no_of_items !== b.no_of_items
							) {
								modified_prices.push(a)
							}
						}
					})
				})
				changed.prices = [...modified_prices, ...new_prices]
				if (changed.prices.length > 0) {
					changed.prices.forEach(item => {
						item.organisation_id = sessionStorage.getItem('selected_org_id')
						item.product_id = this.props.selected_product.id
					})
				}
				Object.keys(changed).forEach(
					key => changed[key] === undefined && delete changed[key],
				)

				this.updateProduct(changed)
			}
		})
	}

	deleteProductImage = url => {
		if (!url) return
		let data = {}
		data.image_urls = url
		data.image_urls_remove = true
		message.info('Deleting image...')
		this.updateProduct(data, true)
	}

	AddPriceButton = props => (
		<Row gutter={1}>
			<Col span={24}>
				<Button type="primary" icon="plus" onClick={this.addPriceField}>
					Add price
				</Button>{' '}
			</Col>
		</Row>
	)

	AddCategory = props => (
		<Row gutter={10}>
			<Col span={24}>
				<Select
					key="categs"
					mode="tags"
					size="default"
					placeholder="Please select categories"
					defaultValue={
						this.props.mode === 'edit' &&
						this.props.selected_product.categories &&
						this.props.selected_product.categories.length > 0
							? this.props.selected_product.categories
							: []
					}
					onChange={this.handleCategoryChange}
				>
					{this.children}
				</Select>
			</Col>
		</Row>
	)

	Kard = props => {
		return (
			<Card
				style={{ width: 300 }}
				hoverable
				cover={
					<img
						style={{
							width: 300,
							height: 300,
							backgroundSize: 'cover',
							overflow: 'hidden',
						}}
						alt="category_image"
						src={props.image}
					/>
				}
			>
				<Button
					type="danger"
					icon={'delete'}
					style={{ textAlign: 'center', width: '100%' }}
					onClick={evt => this.deleteProductImage(props.image)}
				>
					Delete image
				</Button>
			</Card>
		)
	}

	addPriceField = () => {
		this.setState({
			fields: [
				...this.state.fields,
				{
					key: `price_${this.state.counter}`,
					label: 'Price description',
					colSpan: 2,
				},
				{
					key: `value_${this.state.counter}`,
					label: 'Amount',
					widget: 'number',
					colSpan: 2,
				},
				{
					key: `no_of_items_${this.state.counter}`,
					label: 'Number of items',
					widget: 'number',
					colSpan: 1,
				},
				{
					key: `remove_${this.state.counter}`,
					label: '',
					colSpan: 1,
					widget: 'button',
					widgetProps: {
						type: 'danger',
						icon: 'minus',
						onClick: evt => this.removePriceField(evt.target),
					},
				},
			],
		})

		this.setState({ counter: this.state.counter + 1 })
	}

	removePriceField = target => {
		let { id, value } = target
		console.log({ id, value })
		if (!value) {
			let suffix = id.substring(id.indexOf('_'), id.length)
			this.setState({
				fields: this.state.fields.filter(item => !item.key.endsWith(suffix)),
			})
			return
		}

		message.info('Removing price ...')
		const authStr = `Bearer ${sessionStorage.getItem('token')}`
		const headers = { Authorization: authStr }
		let payload = {}
		payload.data = {}
		payload.data.product_price_id = value
		payload.data.product_id = this.props.selected_product.id
		payload.id = sessionStorage.getItem('id')
		payload.type = sessionStorage.getItem('type')
		payload.data.organisation_id = sessionStorage.getItem('selected_org_id')

		this.setState({ isLoading: true })

		axios({
			url: `${this.props.store.domain}/products/${
				this.props.selected_product.id
			}/prices?organisation_id=${sessionStorage.getItem('selected_org_id')}`,
			method: 'delete',
			data: payload,
			headers,
		})
			.then(res => {
				let suffix = id.substring(id.indexOf('_'), id.length)
				this.setState({
					isLoading: false,
					success: true,
					errors: [],
					fields: this.state.fields.filter(item => !item.key.endsWith(suffix)),
				})
				this.props.fetchProducts()
			})
			.catch(error => {
				if (error.response) {
					this.setState({ isLoading: false })
					const { data } = error.response
					this.setState({ success: false, errors: data.errors })
				} else {
					console.error(error)
				}
			})
	}

	addProduct = details => {
		if (this.product_added === true) return

		const authStr = `Bearer ${sessionStorage.getItem('token')}`
		const headers = { Authorization: authStr }
		let payload = {}

		payload.data = details
		payload.id = sessionStorage.getItem('id')
		payload.type = sessionStorage.getItem('type')
		payload.data.organisation_id = sessionStorage.getItem('selected_org_id')
		this.setState({ isLoading: true })

		axios({
			url: `${this.props.store.domain}/products`,
			method: 'post',
			data: payload,
			headers,
		})
			.then(res => {
				this.setState({
					isLoading: false,
					success: true,
					errors: [],
					startFileUpload: true,
					product_id: res.data.data.id,
				})
				this.product_added = true
				this.props.fetchProducts()
			})
			.catch(error => {
				if (error.response) {
					const { data } = error.response
					this.setState({
						isLoading: false,
						success: false,
						errors: data.errors,
					})
				} else {
					console.error(error)
				}
				this.product_added = false
			})
	}

	updateProduct = (details, isImage = false) => {
		const authStr = `Bearer ${sessionStorage.getItem('token')}`
		const headers = { Authorization: authStr }
		let payload = {}

		payload.data = details
		payload.id = sessionStorage.getItem('id')
		payload.type = sessionStorage.getItem('type')
		payload.data.organisation_id = sessionStorage.getItem('selected_org_id')
		this.setState({ isLoading: true })

		axios({
			url: `${this.props.store.domain}/products/${this.props.selected_product.id}`,
			method: 'put',
			data: payload,
			headers,
		})
			.then(res => {
				this.props.fetchProducts()
				this.setState({ isLoading: false })
				if (isImage) return message.success('Successfully removed image.')
				this.setState({ success: true, errors: [] })
			})
			.catch(error => {
				if (error.response) {
					this.setState({ isLoading: false })
					const { data } = error.response
					if (isImage) return message.error('Something went wrong.')
					this.setState({ success: false, errors: data.errors })
				} else {
					console.error(error)
				}
			})
	}

	componentDidMount() {
		this.addCategoryOptions()
		if (this.props.selected_product && this.props.mode === 'edit') {
			if (!this.props.selected_product.id) return this.props.history.goBack()
			let price_fields = []
			this.setState({ fields: [] })
			this.props.selected_product.prices.forEach((item, index) => {
				console.log({ item })
				price_fields.push(
					{
						key: `price_${index + 100}`,
						label: 'Price description',
						colSpan: 2,
						initialValue: item.description,
					},
					{
						key: `value_${index + 100}`,
						label: 'Amount',
						colSpan: 2,
						widget: 'number',
						initialValue: item.amount,
					},
					{
						key: `no_of_items_${index + 100}`,
						label: 'Number of items',
						widget: 'number',
						initialValue: item.no_of_items,
						colSpan: 1,
					},
					{
						key: `remove_${index + 100}`,
						label: '',
						initialValue: item.id,
						colSpan: 1,
						widget: 'button',
						widgetProps: {
							type: 'danger',
							icon: 'minus',
							onClick: evt => this.removePriceField(evt.target),
						},
					},
				)
			})
			this.setState({ fields: price_fields }, () => {
				this.setState({ viewMode: true })
			})
		}
	}

	render() {
		this.that = this
		const productid =
			this.props.mode === 'edit'
				? this.props.selected_product.id
				: this.state.product_id
		const active =
			this.props.mode === 'edit' ? this.props.selected_product.active : true

		const props = {
			name: 'images',
			multiple: true,
			accept: 'image/x-png, image/png, image/gif, image/jpeg',
			headers: { authorization: `Bearer ${sessionStorage.getItem('token')}` },
			action: `${this.props.store.domain}/products/${productid}/upload`,
			onChange: info => {
				const { status } = info.file
				if (status !== 'uploading') {
					console.log(this.that)
					console.log(this.props)
					this.that.setState({ isLoading: true })
				}
				if (status === 'done') {
					this.that.setState({ isLoading: false })
					this.that.props.fetchProducts()
					message.success(`${info.file.name} files uploaded successfully.`)
				} else if (status === 'error') {
					message.error(`${info.file.name} file upload failed.`)
				}
			},
			onRemove(file) {
				return false
			},
		}

		const UploadImage = props => {
			return (
				<Dragger {...props}>
					<p className="ant-upload-drag-icon">
						<Icon type="picture" theme="twoTone" />
					</p>
					<p className="ant-upload-text">Click or drag images to upload</p>
					<p className="ant-upload-hint">
						Up to 4 images allowed. Maximum size per image should be 3MB
					</p>
				</Dragger>
			)
		}

		const meta = {
			columns: 6,
			gutter: 20,
			formItemLayout: null, // Must set this for inline layout
			colon: true,
			fields: [
				{
					key: 'label1',
					colSpan: 6,
					render() {
						return (
							<fieldset>
								<legend>{'Add product info'}</legend>
							</fieldset>
						)
					},
				},
				{
					key: 'description',
					label: 'Name',
					colSpan: 2,
					required: true,
					initialValue:
						this.props.selected_product &&
						this.props.selected_product.description,
				},
				{
					key: 'sku',
					label: 'SKU',
					colSpan: 2,
					required: true,
					initialValue:
						this.props.selected_product && this.props.selected_product.sku,
				},
				{
					key: 'code',
					label: 'Code',
					colSpan: 2,
					initialValue:
						this.props.selected_product && this.props.selected_product.code,
				},
				{
					key: 'location',
					label: 'Location',
					colSpan: 2,
					initialValue:
						this.props.selected_product && this.props.selected_product.location,
				},
				{
					key: 'vat',
					label: 'VAT',
					colSpan: 2,
					required: true,
					widget: 'number',
					initialValue:
						(this.props.selected_product && this.props.selected_product.vat) ||
						this.props.store.selected_organisation.vat,
				},
				{
					key: 'expiry_date',
					label: 'Expiry date',
					colSpan: 2,
					widget: 'date-picker',
					initialValue:
						this.props.selected_product &&
						moment(this.props.selected_product.expiry_date),
				},
				{
					key: 'details',
					label: 'Product Description',
					colSpan: 4,
					widget: 'textarea',
					initialValue:
						this.props.selected_product && this.props.selected_product.details,
				},
				{
					key: 'categories',
					label: 'Categories',
					widget: this.AddCategory,
					colSpan: 2,
				},
				{
					key: 'price',
					label: 'Price',
					colSpan: 2,
					widget: 'number',
					extra: `Currency ${sessionStorage.getItem('selected_org_currency')}`,
					initialValue:
						this.props.selected_product && this.props.selected_product.price,
				},
				{
					key: 'active',
					label: 'Active',
					colSpan: 2,
					widget: 'switch',
					help: 'Is this product active?',
					initialValue: active,
				},
				{
					key: 'label1',
					colSpan: 6,
					render() {
						return (
							<fieldset>
								<legend>Add more product pricing</legend>
							</fieldset>
						)
					},
				},
				...this.state.fields,
				{
					key: 'button',
					label: '',
					colSpan: 6,
					widget: this.AddPriceButton,
					widgetProps: { textAlign: 'right' },
				},
				{
					key: 'label1',
					colSpan: 6,
					render() {
						return (
							<fieldset>
								<legend>Inventory Management</legend>
							</fieldset>
						)
					},
				},
				{
					key: 'unit_of_order',
					label: 'Unit of order',
					colSpan: 2,
					initialValue:
						this.props.selected_product &&
						this.props.selected_product.unit_of_order,
				},
				{
					key: 'unit_cost',
					label: 'Unit cost',
					colSpan: 2,
					widget: 'number',
					initialValue:
						this.props.selected_product &&
						this.props.selected_product.unit_cost,
				},
				{
					key: 'unit_of_sale',
					label: 'Unit of sale',
					colSpan: 2,
					initialValue:
						this.props.selected_product &&
						this.props.selected_product.unit_of_sale,
				},
				{
					key: 'conversion',
					label: 'Conversion',
					colSpan: 2,
					widget: 'number',
					initialValue:
						this.props.selected_product &&
						this.props.selected_product.conversion,
				},
				{
					key: 'reorder_level',
					label: 'Reorder Level',
					colSpan: 2,
					widget: 'number',
					initialValue:
						this.props.selected_product &&
						this.props.selected_product.reorder_level,
				},
				{
					key: 'reorder_quantity',
					label: 'Reorder Quantity',
					colSpan: 2,
					widget: 'number',
					initialValue:
						this.props.selected_product &&
						this.props.selected_product.reorder_quantity,
				},
				//{ key: 'response', label: '', widget: Alerts, colSpan: 6 },
			],
		}

		return (
			<div className="grid_margin">
				{this.props.mode === 'edit' && (
					<SubHeader
						title={this.props.selected_product.description}
						tab_one_text={'Product details'}
						tab_two_text={'Edit product'}
						handleDetailsMode={this.handleDetailsMode}
						handleEditMode={this.handleEditMode}
						goBack={this.goBack}
					/>
				)}

				{this.props.mode === 'edit' && this.state.viewMode && (
					<Descriptions
						data={this.viewProduct()}
						onDeleteItem={this.onDeleteItem}
						showDelete={true}
						deleteText={'Delete product'}
						showImages={true}
						imageData={this.props.selected_product.image_urls}
					/>
				)}

				{!this.state.viewMode && (
					<Form layout="vertical" onSubmit={this.handleSubmit}>
						<FormBuilder form={this.props.form} meta={meta} />
						<Form.Item>
							{!this.state.viewMode &&
								this.props.mode === 'edit' &&
								this.props.selected_product.image_urls &&
								this.props.selected_product.image_urls.length > 0 && (
									<List
										header={'Product images'}
										bordered
										grid={{ gutter: 16, column: 4 }}
										dataSource={this.props.selected_product.image_urls}
										renderItem={item => (
											<List.Item>
												<this.Kard
													title={''}
													description={''}
													image={item}
												></this.Kard>
											</List.Item>
										)}
									/>
								)}

							{(this.state.startFileUpload || this.props.mode === 'edit') && (
								<div>
									<br />
									<UploadImage {...props} />
									<br />
								</div>
							)}

							<Alerts
								success={this.state.success}
								errors={this.state.errors}
								description={`${
									this.props.mode === 'add'
										? 'Added new product successfully'
										: 'Successfully updated product'
								}`}
							/>
							<br />
							<Button
								htmlType="submit"
								type="primary"
								loading={this.state.isLoading}
								style={{ width: '100%' }}
							>
								Submit
							</Button>
							{this.props.mode === 'add' && this.state.success && (
								<div>
									<br />
									<Button
										style={{ width: '100%' }}
										onClick={this.addNewProduct}
									>
										Add another new product
									</Button>
									<br />
									<br />
									{/* <Button type="danger"  style={{ width: '100%' }} onClick={this.goBack}>
            go back
          </Button> */}
								</div>
							)}
						</Form.Item>
					</Form>
				)}
			</div>
		)
	}
}

export default Form.create()(AddProduct)
