import { Button, Card, Table, Tag, notification, Spin } from 'antd'
import { capitalize } from 'lodash'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ReactFileReader from 'react-file-reader'
import csv from 'csvtojson'
import { CSVLink } from 'react-csv'

const Main = () => {
	const [inventory, setInventory] = useState([])
	const [pagination, setPagination] = useState({})
	const authStr = `Bearer ${sessionStorage.getItem('token')}`
	const organisation_id = sessionStorage.getItem('selected_org_id')
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		fetchInventory()
	}, [])

	const openNotification = (message, type = 'info') =>
		notification[type]({
			message,
		})

	const fetchInventory = (page_number = 1) => {
		setLoading(true)
		if (!organisation_id) {
			console.error('Organisation ID not found in session storage.')
			return
		}
		const url = `https://foodsapi.mobitill.com/inventories/all?organisation_id=${organisation_id}`

		axios({ method: 'GET', url, headers: { Authorization: authStr } })
			.then(response => {
				const { data } = response.data
				setInventory(data)
				setLoading(false)
				// const pager = { ...pagination }
				// pager.total = response.data.pagination.page_count
				// setPagination(pager)

				// const inventoryData = response.data.inventory.map(item => {
				// 	item.key = item._id
				// 	return item
				// })
				// setInventory(inventoryData)
			})
			.catch(error => {
				console.error('Error fetching inventory:', error)
				setLoading(false)
			})
	}

	const onTableChange = p => {
		const pager = { ...pagination }
		pager.current = p.current
		setPagination(pager)
		fetchInventory(pager.current)
	}

	const columns = [
		{
			title: 'Name',
			dataIndex: 'description',
			key: 'description',
			render: name => capitalize(name),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: status => (
				<Tag color={status === 'ADDED' ? 'green' : 'blue'}>
					{status.toUpperCase()}
				</Tag>
			),
		},
		{
			title: 'Units',
			dataIndex: 'units',
			key: 'units',
		},
		{
			title: 'Unit price',
			dataIndex: 'unit_price',
			key: 'unit_price',
			render: price => (
				<>{`${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} /=`}</>
			),
		},
		{
			title: 'Total price',
			dataIndex: 'total_price',
			key: 'total_price',
			render: price => (
				<>{`${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} /=`}</>
			),
		},
		{
			title: 'Date',
			dataIndex: 'created_at',
			key: 'created_at',
			render: date => new Date(date).toLocaleDateString(),
		},
		{
			title: 'Action',
			dataIndex: 'id',
			key: 'id',
			render: _id => <a href={`/inventories/${_id}`}>View</a>,
		},
	]

	const onHandleFiles = files => {
		const reader = new FileReader()
		reader.onload = e => {
			csv()
				.fromString(reader.result)
				.then(response => {
					const url = `https://foodsapi.mobitill.com/adhoc/inventory/update-many/${organisation_id}`

					return axios.post(url, { data: response })
				})
				.then(response => {
					openNotification('Inventory successfully updated', 'success')
				})
				.catch(err => {
					openNotification(err.message, 'error')
				})
		}
		reader.readAsText(files[0])
	}

	const [selectedRowKeys, setSelectedRowKeys] = useState([])
	const [selectedRowRecords, setSelectedRowRecords] = useState([])

	const onSelectChange = (keys, records) => {
		setSelectedRowKeys(keys)
		setSelectedRowRecords(records)
	}

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	}

	return (
		<div className="def_page" style={{ height: '100%' }}>
			<Spin size="large" spinning={loading}>
			<Card
				title="Inventory"
				extra={
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<CSVLink
							data={selectedRowRecords.map(r => {
								const tmp = { ...r }
								delete tmp.id
								delete tmp.organisation_id
								delete tmp.product_id
								delete tmp.created_at
								delete tmp.updated_at
								delete tmp.employee_id
								delete tmp.key

								return tmp
							})}
							filename={`${new Date().getTime()}.csv`}
						>
							<Button
								type="dashed"
								disabled={selectedRowRecords.length ? false : true}
							>
								Download upload template
							</Button>
						</CSVLink>

						<ReactFileReader
							fileTypes={'.csv'}
							//base64={true}
							handleFiles={onHandleFiles}
						>
							<Button type="primary" style={{ marginLeft: '4px' }}>
								Upload update CSV
							</Button>
						</ReactFileReader>
					</div>
				}
			>
				<div style={{ marginBottom: '16px' }}>
					<span style={{ marginLeft: '8px' }}>
						{selectedRowKeys.length
							? `Selected ${selectedRowKeys.length} items.`
							: undefined}
					</span>
				</div>

				<Table
					columns={columns}
					dataSource={inventory}
					pagination={pagination}
					onChange={onTableChange}
					rowSelection={rowSelection}
				/>
			</Card>
			</Spin>
		</div>
	)
}

export default Main
