import React, { Component } from 'react'
import { Title } from 'reactbulma'
import { Redirect } from 'react-router-dom'
import { Layout } from 'antd';
import axios from 'axios'
import moment from 'moment'
import { inject, observer, Observer } from 'mobx-react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { SubTitle } from 'reactbulma'
import Header from '../../components/header'
import TableComponent from '../../components/tableComponent'
import { columnFormatter, positionFormatter, boldFormatter } from '../../components/columnFormatter'

@inject('store') 
@observer
class PurchaseOrders extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      redirect: false,
      data: {}
    }

  }

  fetchPurchaseOrders = (after_id = null, limit = this.state.pageSize) => {

    let organisation_id = this.props.store.selected_organisation.id

    const params = { organisation_id }

    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    
    axios({
      url: `${this.props.store.domain}/purchase-orders`,
      method: 'get',
      params,
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {

        const { data } = res.data

        data.forEach((element, index) => {
            element.key = index + 1
            element.created_at = moment(element.created_at).format('LLLL')
            element.delivery_date = moment(element.delivery_date).format('LL')
        })

        this.props.store.updateValue('purchase_orders', data)

      })
      .catch( (error) => {
        if(error.response) {
          const { data } = error.response
          this.setState({
            errors: data.errors
          })
        } else {
          console.error(error)
        }
      })
  }

  componentDidMount() {
      if(this.props.store.purchase_orders.length === 0) this.fetchPurchaseOrders()
  }

  render() {
    let p =this.props.store.purchase_orders
    const columns = [
      { dataField: 'key', text: 'No',  align: 'center', ...positionFormatter}, 
      { dataField: 'name', text: 'Description',  ...boldFormatter, ...columnFormatter},
      { dataField: 'created_at', text: 'Date created', ...columnFormatter},
      { dataField: 'vendor', text: 'Distributor',  ...boldFormatter, ...columnFormatter}, 
      { dataField: 'delivery_date', text: 'Date to deliver', ...columnFormatter},
      { dataField: 'status', text: 'Status', ...columnFormatter}, 
      { dataField: 'total', text: 'Total amount', ...columnFormatter}, 
      
    ];

    return (
      <div className='def_page'>
      <Header title = { 'Purchase orders' } />
      <div className='grid_margin card'>
      <TableComponent columns={columns} data={p} />
      </div>
      </div>
    )
  }
}

export default PurchaseOrders