import { Card, Table, Tag, Button, notification } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { capitalize } from "lodash";
import { useParams } from "react-router-dom";

const Main = () => {
  const [inventory, setInventory] = useState([]);

  const [pagination, setPagination] = useState({});

  const organisation_id = "3586adba0445e75c8d06712a66108c57";

  const openNotification = (message, type = "info") =>
    notification[type]({
      message,
    });

  const fetchInventory = (organisation_id, page_number = 1) => {
    const url = `https://foodsapi.mobitill.com/adhoc/inventory/get-many/${organisation_id}?page_number=${page_number}`;

    axios({ method: "GET", url })
      .then((response) => {
        const pager = { ...pagination };
        pager.total = response.data.pagination.page_count;

        setPagination(pager);

        const inventory = response.data.inventory.map((i) => {
          i.key = i._id;

          return i;
        });

        setInventory(inventory);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchInventory(organisation_id);
  }, []);

  const onTableChange = (p) => {
    const pager = { ...pagination };
    pager.current = p.current;
    setPagination(pager);

    fetchInventory(organisation_id, pager.current);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "description",
      key: "description",
      render: (name) => capitalize(name),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "ADDED" ? "green" : "blue"}>
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
    },
    {
      title: "Unit price",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (price) => (
        <>{`${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /=`}</>
      ),
    },
    {
      title: "Total price",
      dataIndex: "total_price",
      key: "total_price",
      render: (price) => (
        <>{`${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /=`}</>
      ),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (_id) => <a href={`/inventories/${_id}`}>View</a>,
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowRecords, setSelectedRowRecords] = useState([]);

  const onSelectChange = (keys, records) => {
    setSelectedRowKeys(keys);
    setSelectedRowRecords(records);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const { storeId } = useParams();

  const handleAddInventory = () => {
    const data = selectedRowRecords.map((i) => {
      return { inventory_id: i._id };
    });

    const url = `https://foodsapi.mobitill.com/adhoc/store-inventory/add-many/${storeId}`;

    axios({ method: "PUT", url, data: { data } })
      .then(() => {
        openNotification("Inventory successfully added", "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="def_page" style={{ height: "100%" }}>
      <Card
        title="Add store inventory"
        extra={
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="primary"
              disabled={selectedRowRecords.length ? false : true}
              onClick={handleAddInventory}
            >
              Add inventory
            </Button>
          </div>
        }
      >
        <Table
          columns={columns}
          dataSource={inventory}
          pagination={pagination}
          onChange={onTableChange}
          rowSelection={rowSelection}
        />
      </Card>
    </div>
  );
};

export default Main;
