import React, { Component } from 'react';
import { Input, Button, message } from 'antd';
import { inject, observer } from 'mobx-react';
import TableComponent from '../../components/tableComponent';
import {
	columnFormatter,
	positionFormatter,
	boldFormatter,
} from '../../components/columnFormatter';
import Header from '../../components/header';

@inject('store')
@observer
class ProductAudit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			products: [],
		};
	}

	componentDidMount() {
		const { store } = this.props;
		this.setState({ products: store.products }); 
	}

	handleSave = (productId, newValue) => {
		
		const updatedProducts = this.state.products.map(product => {
			if (product.id === productId) {
				return { ...product, audit_value: newValue };
			}
			return product;
		});
		this.setState({ products: updatedProducts });
		message.success('Audit value saved successfully');
	};

	render() {
		const { products } = this.state;

		const columns = [
			{ dataField: 'key', text: 'No', align: 'center', ...positionFormatter },
			{ dataField: 'description', text: 'Description', ...boldFormatter, ...columnFormatter },
			{ dataField: 'id', text: 'Product id', hidden: true },
			{ dataField: 'location', text: 'Location', hidden: true },
			{ dataField: 'sku', text: 'SKU', ...boldFormatter, ...columnFormatter, align: 'right' },
			{ dataField: 'code', text: 'Code', ...boldFormatter, ...columnFormatter, align: 'right' },
			{ dataField: 'stock_amount', text: 'Stock Amount', ...columnFormatter, align: 'right' },
			{
				dataField: 'audit_value',
				text: 'Audit Value',
				formatter: (cell, row) => (
					<Input defaultValue={cell} onChange={(e) => this.handleSave(row.id, e.target.value)} style={{ width: 120 }} />
				),
				...columnFormatter,
				align: 'center',
			},
			{
				dataField: 'save_button',
				text: 'Save',
				formatter: (cell, row) => (
					<Button type="primary" onClick={() => this.handleSave(row.id, row.audit_value)}>
						Save
					</Button>
				),
				...columnFormatter,
				align: 'center',
			},
		];

		return (
			<div className="def_page" style={{ height: '100%' }}>
				<Header title={'Product Audit'} />
				<div className="grid_margin card">
					<TableComponent columns={columns} data={products} tableName="products" />
				</div>
			</div>
		);
	}
}

export default ProductAudit;
