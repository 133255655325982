import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ListAll from "./listAll";
import ViewOne from "./viewOne";

const Main = () => {
  const { path } = useRouteMatch();

  const routes = [
    {
      path: `${path}/all`,
      component: <ListAll />,
    },
    {
      path: `${path}/view/:storeId`,
      component: <ViewOne />,
    },
  ];

  return (
    <Switch>
      {routes.map((route) => (
        <Route path={route.path} children={route.component} />
      ))}

      <Redirect path={path} to={`${path}/all`} />
    </Switch>
  );
};

export default Main;
