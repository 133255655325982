import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import { capitalize } from "lodash";

const Main = () => {
  const { storeId } = useParams();

  const [inventory, setInventory] = useState([]);

  const [pagination, setPagination] = useState({});

  const fetchInventory = (storeId, page_number = 1) => {
    const url = `https://foodsapi.mobitill.com/adhoc/store-inventory/get-many/${storeId}?page_number=${page_number}`;

    axios({ method: "GET", url })
      .then((response) => {
        const pager = { ...pagination };
        pager.total = response.data.pagination.page_count;

        setPagination(pager);

        const inventory = response.data.inventory.map((i) => {
          i.key = i.inventory__id;

          return i;
        });

        setInventory(inventory);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchInventory(storeId);
  }, []);

  const onTableChange = (p) => {
    const pager = { ...pagination };
    pager.current = p.current;
    setPagination(pager);

    fetchInventory(storeId, pager.current);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "description",
      key: "description",
      render: (name) => capitalize(name),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "ADDED" ? "green" : "blue"}>
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
    },
    {
      title: "Unit price",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (price) => (
        <>{`${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /=`}</>
      ),
    },
    {
      title: "Total price",
      dataIndex: "total_price",
      key: "total_price",
      render: (price) => (
        <>{`${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /=`}</>
      ),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Action",
      dataIndex: "inventory_id",
      key: "inventory_id",
      render: (id) => <a href={`/inventories/${id}`}>View</a>,
    },
  ];

  return (
    <div style={{ height: "100%" }}>
      <Table
        columns={columns}
        dataSource={inventory}
        pagination={pagination}
        onChange={onTableChange}
      />
    </div>
  );
};

export default Main;
