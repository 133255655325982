import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import { Layout } from 'antd';
import VerticalMenu from './components/VerticalMenu'
import HorizontalComponent from './components/horizontalComponent'
import MainPage from './components/mainPage'
import SignUp from "./components/Signup";
import Login from "./components/Login";

import './App.css';

function App() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("./firebase-messaging-sw.js")
      .then(function(registration) {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function(err) {
        console.log("Service worker registration failed, error:", err);
      });
  }

  return (
    <Switch>
        <Route  path={'/'} component={MainPage} />
    </Switch>
  )

  // if(sessionStorage.getItem('loggedIn')) {
  // return (
  //   <div className="App" style={{ display: 'flex', flexDirection: 'row', backgroundColor:'#ffffff'}}>
  //     <VerticalMenu/>
  //     <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, backgroundColor:'#F0F2F5'}}>
  //     <HorizontalComponent />
  //     <MainPage/>
  //     </div>
  //   </div>)
  //   }else{
  //     return(
  //       <Login/>
  //       // <Switch>
  //       //     <Route exact path={'/signup'} component={SignUp} />
  //       //     <Route exact path={'/login'} component={Login} />
  //       // </Switch>
  //     )
  //   }
}

export default App;
