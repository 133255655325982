import React, { Component } from "react";
import { Form, Input, Button, Alert } from "antd";
import axios from "axios";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      alertType: "info",
      alertMessage: "",
    };
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.state.name) {
      this.setState({ alertType: "error", alertMessage: "Name is required." });
    } else {
      const url = `https://foodsapi.mobitill.com/adhoc/store/create/${this.props.match.params.id}`;
      const authStr = `Bearer ${sessionStorage.getItem("token")}`;
      const payload = {};
      payload.name = this.state.name;
      if (this.state.description) payload.description = this.state.description;

      const data = {
        data: [payload],
      };

      axios({ method: "PUT", url, data, headers: { Authorization: authStr } })
        .then(() => {
          this.setState({ alertType: "success", alertMessage: "Store added." });
        })
        .catch((error) => {
          this.setState({ alertType: "error", alertMessage: error.message });
        });
    }
  };

  render() {
    return (
      <Form {...layout} name="basic">
        <Form.Item label="Name" name="name" required>
          <Input
            name="name"
            value={this.state.name}
            onChange={this.handleChange}
          />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea
            name="description"
            value={this.state.description}
            onChange={this.handleChange}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>
            Submit
          </Button>
        </Form.Item>

        {this.state.alertMessage && (
          <Alert
            type={this.state.alertType}
            message={this.state.alertMessage}
          />
        )}
      </Form>
    );
  }
}

export default Main;
