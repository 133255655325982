import React, { Component, Fragment } from "react";
import { Table, Tag, DatePicker, Divider } from "antd";
import axios from "axios";
import moment from "moment";
import { CSVLink } from "react-csv";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      start_date: moment(new Date()).subtract(30, "days").calendar(),
      end_date: new Date().toLocaleDateString("en-UK"),
      page_number: 1,
      page_limit: 10,
      page_count: 1,
    };
  }

  onChange = (value, dateString) =>
    this.setState(
      { start_date: dateString[0], end_date: dateString[1] },
      () => {
        const organisation_id = sessionStorage.getItem("selected_org_id");

        this.fetchOrders(
          organisation_id,
          this.state.start_date,
          this.state.end_date,
          this.state.page_number,
          this.state.page_limit
        );
      }
    );

  onTableChange = (pagination) => {
    this.setState({ page_number: pagination.current }, () => {
      const organisation_id = sessionStorage.getItem("selected_org_id");

      this.fetchOrders(
        organisation_id,
        this.state.start_date,
        this.state.end_date,
        this.state.page_number,
        this.state.page_limit
      );
    });
  };

  fetchOrders = (id, start_date, end_date, page_number, page_limit) => {
    const url = `https://foodsapi.mobitill.com/adhoc/customer-orders/get-many/${id}?type=organization&start_date=${start_date} &end_date=${end_date}&page_number=${page_number}&page_limit=${page_limit}`;
    const authStr = `Bearer ${sessionStorage.getItem("token")}`;

    axios({ method: "GET", url, headers: { Authorization: authStr } })
      .then((res) => {
        const orders = res.data.results.customer_orders.map((v) => ({
          key: v._id,
          _id: v._id,
          address: v.delivery_address,
          amount: v.amount,
          status: v.status,
          created_at: new Date(v.created_at).toLocaleDateString("en-UK"),
        }));

        this.setState({ orders, page_count: res.data.pagination.page_count });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    const organisation_id = sessionStorage.getItem("selected_org_id");

    this.fetchOrders(
      organisation_id,
      this.state.start_date,
      this.state.end_date,
      this.state.page_number,
      this.state.page_limit
    );
  }

  render() {
    const columns = [
      {
        title: "No.",
        dataIndex: "_id",
        key: "_id",
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (amount) => (
          <>{`${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /=`}</>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status) => (
          <Tag color={status === "not payed" ? "red" : "blue"}>
            {status === "not payed" ? "Not paid" : status}
          </Tag>
        ),
      },
      {
        title: "Date",
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: "Action",
        dataIndex: "_id",
        key: "_id",
        render: (_id) => <a href={`/customer-orders/view/${_id}`}>View</a>,
      },
    ];

    const { RangePicker } = DatePicker;

    const csvHeaders = [
      { label: "Order No.", key: "_id" },
      { label: "Address", key: "address" },
      { label: "Status", key: "status" },
      { label: "Created at", key: "created_at" },
    ];

    console.log(this.state);

    return (
      <Fragment>
        <RangePicker onChange={this.onChange} onOk={() => alert("aye")} />

        <Divider />

        <Table
          dataSource={this.state.orders}
          columns={columns}
          pagination={{
            current: this.state.page_number,
            pageSize: this.state.page_limit,
            total: this.state.page_count,
          }}
          onChange={this.onTableChange}
        />

        <Divider />

        <CSVLink data={this.state.orders} headers={csvHeaders}>
          Download
        </CSVLink>
      </Fragment>
    );
  }
}

export default Main;
