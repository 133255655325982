import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { Title } from 'reactbulma'
import { Route, Switch } from 'react-router'
import { Tabs, Avatar, List, Icon, Divider } from 'antd';
import axios from 'axios'
import moment from 'moment'
import { inject, observer, Observer } from 'mobx-react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import GoogleMapReact from 'google-map-react';
import io from 'socket.io-client';
import { SubTitle } from 'reactbulma'
import Header from '../../components/header'
import TableComponent from '../../components/tableComponent'
import Loader from '../../components/LoaderComponent'
import { columnFormatter, positionFormatter, boldFormatter } from '../../components/columnFormatter'
import Tag from '../../components/Tag'
import AddEmployee from './addEmployee'
const { TabPane } = Tabs;
@inject('store') 
@observer
class DeliveryStaff extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      errors: [],
      employees: [],
      marker: null,
      locs: [],
      locs_timestamp: {},
      status: {}
    }
    this.refresh = false
    this.mapCenter = null
    
  }

  fetchEmployees = (after_id = null, limit = this.state.pageSize) => {
    
    let organisation_id = sessionStorage.getItem('selected_org_id')
    const type = 'delivery'

    const params = { organisation_id, type }

    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    this.setState({isLoading: true})
    axios({
      url: `${this.props.store.domain}/employees`,
      method: 'get',
      params,
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {

        const { data } = res.data

        data.forEach((element, index) => {
            element.key = index + 1
            element.name = `${element.first_name} ${element.last_name}`
        })

        this.setState({isLoading: false, errors: [], employees: data})
        this.refresh = false
      })
      .catch( (error) => {
        if(error.response) {
          const { data } = error.response
          this.setState({ isLoading: false, errors: data.errors})
        } else {
          console.error(error)
        }
      })
  }

  componentDidMount() {
      if(this.state.employees.length === 0) this.fetchEmployees()
      this.handleSocket()
  }

  componentWillUnmount() {
     this.socket.disconnect()
  }

  componentDidUpdate(previousProps, previousState) {
    // console.log('employees updated')
    // if(previousProps.store.employees.length === 0 && !this.refresh) {
    //   this.refresh = true
    //   console.log('fetching em...')
    //   this.fetchEmployees()
    // }
  }

  handleEmployeeClick = (data) => {
      console.log(data)
      
      if(data.lat && data.long){
        this.setState({ marker: {lat:9.035578, lng: 38.730383}})
        console.log({lat: data.lat, lng: data.long})
        //this.props.google.maps.Map.panTo(new window.google.maps.LatLng(data.lat, data.long));
        //if (this.props && this.props.google) {
          console.log('pan to')
            const map = this.map;
            const curr = this.state.currentLocation;
        
            const google = this.props.google;
            const maps = google.maps;
        
            //if (map) {
            let center = new maps.LatLng(9.035578, 38.730383)
            map.panTo(center)
            //}
          //}
      }

  }

  LocationMarker = () => {
    var outer = {
      position: 'absolute',
      left: '0px',
      top: '0px',
      zIndex: '1',
      backgroundColor: 'white',
      borderRadius: "50%",
      width:'30px',
      height:'30px',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    };

    var inner = {
      position: 'absolute',
      left: '0px',
      top: '0px',
      bottom: '0px',
      right: '0px',
      zIndex: '2',
      margin: 'auto',
      backgroundColor: '#007BFF',
      borderRadius: "50%",
      width:'20px',
      height:'20px',
    };

    return (
      <div style={{ width:'30px', height:'30px', position:'relative',  }}>
        <div style={outer}></div>
        <div style={inner}></div>
      </div>
      
    );
  }

  showMarkers = (data) => {
    return data.map(item => {
      return <this.LocationMarker 
      lat={item.lat}
      lng={item.long}
      text="mark"
      />
    })
  }

  getLatestDeliveryLocations = () => {
   this.socket.emit('location_data', sessionStorage.getItem('selected_org_id'))
  }

   makeId = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

  handleSocket = () => {
    this.socket = io(this.props.store.domain, {
      "transports": ['websocket']
    });

    this.socket.on('connect', () => { 
      console.log('socket on connect')
      let user_id = `${sessionStorage.getItem('id')}_${this.makeId(12)}`
      console.log({user_id})
      let organisation_id = sessionStorage.getItem('selected_org_id')
      this.socket.emit('on_dash_id',{ user_id, organisation_id });
    });

    this.socket.on('on_dash_id', (data) => {
      console.log('on_dash_id')
      console.log({data})
      this.deliveryQueryInterval = setInterval(() => {
       this.getLatestDeliveryLocations()
       }, 5000);
    });

    this.socket.on('connect_error', () => {
      console.log('connect error')
    });

    this.socket.on('disconnect', () => { 
      console.log('socket on disconnect')
    });

    this.socket.on('location_data', (data) => { 
      //console.log(data)
      //this.showMarkers(data)
      this.setState({locs: data.locs})
      let o = {}
      let status = {}
     
      data.locs.forEach(item => {
        o[item.id] =  moment(item.time_stamp).format('LLLL')
      })

      data.status.forEach(item => {
        status[item.id] =  item.delivering
      })

      console.log(o)
      console.log(status)
      this.setState({locs_timestamp: o, status})
    });

  }

  getTimestamp = (employee_id) => {
    //return this.state.lo
  //   console.log({employee_id})
  //   console.log(this.state.locs[this.props.id])
  //     return moment(this.state.locs.find(item => item.id === employee_id).time_stamp).format('LLLL') || 'No data'
  // }
  }

  showItems = () => {
      return this.state.employees.map(item => {
         return <this.Item props={item}/>
      })
  }

  Status = (props) => {
    console.log(this.state.status)
    console.log(this.state.status[props.driver_id])
    if(this.state.status[props.driver_id] === true){
      return <div className='round_tag_progress'>Delivery in progress</div>
    }
      return <Tag ok={props.available} text_ok='Available' text_danger='Not available'/>
      // if(props.available === true) return  <Tag success>Available</Tag>
      // if(props.available === false) return  <Tag danger>Not available</Tag>
  }

  Item = (props) => {
      return(
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%'}} onClick={ () => this.handleEmployeeClick(props.props)}>
            <Avatar src="" />
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '1px', marginTop: '1px', marginLeft: '10px', }}>
            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', width: '100%', justifyContent: 'space-between'}}>
             {props.props.name}
             <this.Status available={props.props.available} driver_id={props.props.id}/> 
             </div>
             <div style={{ display: 'flex', flexDirection: 'row', width: '100%'}}>
              Last seen  <span style={{color: 'grey', marginLeft: '5px'}}>{this.state.locs_timestamp[props.props.id] || 'unknown'}</span>
             </div>
             <Divider />
            </div>
          </div>
      )
  }

  Map = () => {
      if(sessionStorage.getItem('lat') && sessionStorage.getItem('long')){
          this.mapCenter = {lat: parseFloat(sessionStorage.getItem('lat')), lng: parseFloat(sessionStorage.getItem('long')) }
      }

      const defaultProps = {
        center: {
          lat: 59.95,
          lng: 30.33
        },
        zoom: 11
      };

      return (

        <div style={{ height: '100vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBQGGf1Y_JkJ3sT2oHwEC59Apt0NQVD-Z0' }}
            defaultCenter={this.mapCenter}
            defaultZoom={defaultProps.zoom}>
        
            {this.showMarkers(this.state.locs)}

          </GoogleMapReact>
        </div>
      );
  }

  Employees = observer(() => {
    return (
      <div className='grid_margin ' style={{ display: 'flex', flexDirection: 'row', width:'100%'}}>
   
      <div className='card'style={{ display: 'flex', flexDirection: 'column', width: '30%', height: '100%'}}>
        {this.showItems()}
      </div>
      <div style={{ display: 'flex', width: '65%', position: 'relative', marginLeft: '20px'}}>
      <this.Map/>
      </div>
     
      </div>
    )
  })

  render() {
    return (
      <div className='def_page' style={{ height: '100%' }}>
      <Loader name={'employees'} isLoading={this.state.isLoading} errors={this.state.errors} size={this.state.employees.length}/>
      <this.Employees/>
      </div>
    )
  }
}

export default DeliveryStaff
