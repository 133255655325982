import React, { Component } from 'react'
import { Title } from 'reactbulma'
import { Redirect } from 'react-router-dom'
import { Layout, Tabs } from 'antd';
import { Route, Switch } from 'react-router'
import axios from 'axios'
import moment from 'moment'
import { inject, observer, Observer } from 'mobx-react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { SubTitle } from 'reactbulma'
import Header from '../../components/header'
import TableComponent from '../../components/tableComponent'
import Loader from '../../components/LoaderComponent'
import { columnFormatter, positionFormatter, boldFormatter } from '../../components/columnFormatter'
import Tag from '../../components/Tag'
import UserDetails from './userDetails'

const { TabPane } = Tabs;
@inject('store') 
@observer
class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errors: [],
      selected_user: {}
    }
    this.refresh = false
  }

  rowEvents = {
    onClick: (e, row, rowIndex) => { 
      let item = {...row}
      console.log(item)
      this.setState({selected_user: item}, () => {
        this.setState({showTabs: false})
        this.props.history.push(`/users/${item.id}/details`);
      })

    }
  };

  fetchUsers = (after_id = null) => {

    let organisation_id = sessionStorage.getItem('selected_org_id')
    const params = {organisation_id,}
    const authStr = `Bearer ${sessionStorage.getItem('token')}` 
    this.setState({isLoading: true})

    axios({
      url: `${this.props.store.domain}/users`,
      method: 'get',
      params,
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {
        const { data } = res.data

        //const { orders } = data
        
        data.forEach((element, index) => {
            element.key = index + 1
            element.created_at = element.created_at = moment(element.created_at).format('LLLL')
            element.name = `${element.first_name} ${element.last_name}`
        })
        
        this.props.store.updateValue('users', data)
        // this.props.store.updateValue('order_items', order_items)
        // this.props.store.updateValue('order_payments', payments)
        this.setState({isLoading: false, errors: []})
      })
      .catch( (error) => {
        if(error.response) {
          const { data } = error.response
          this.setState({ isLoading: false, errors: data.errors})
        } else {
          console.error(error)
        }

      })
  }

  componentDidMount() {
    if(this.props.store.users.length === 0) this.fetchUsers()
  }

  userStatus(cell, row) {
  return (
      <Tag ok={!row.blacklisted} text_ok= 'Active' text_danger='Blacklisted'/>
  );
  }   

  Users = observer(() => {
    let p =this.props.store.users
    const columns = [
      { dataField: 'key', text: 'No',  align: 'center', ...positionFormatter}, 
      { dataField: 'name', text: 'Names',  ...boldFormatter, ...columnFormatter}, 
      { dataField: 'country', text: 'Country', ...columnFormatter}, 
      { dataField: 'phone_number', text: 'Mobile Number', ...columnFormatter}, 
      { dataField: 'email', text: 'Email', ...columnFormatter}, 
      { dataField: 'gender', text: 'Gender', ...columnFormatter}, 
      { dataField: 'status', text: 'Status', formatter: this.userStatus.bind(this), ...columnFormatter}, 
      { dataField: 'cancel_count', text: 'Cancels', ...columnFormatter}, 
    ];

    return (
      <div className='grid_margin card'>
      <TableComponent columns={columns} data={p} rowEvents={ this.rowEvents }/>
      </div>
    )
  })

  render() {
    let p =this.props.store.users

    return (
      <div className='def_page'>
      <Header title = {'Customers'} />

      <Switch>
        
        <Route
          exact
          path={`${this.props.match.path}/`}
          render={ props => (
            <this.Users />
          )}
        />

        <Route
          exact
          path={`${this.props.match.path}/:id/details`}
          render={ props => (
            <UserDetails 
            selected_user = {this.state.selected_user}
            {...props}
            />
          )}
        /> 
        </Switch>

      <Loader name={'customers'} isLoading={this.state.isLoading} errors={this.state.errors} size={p.length}/>
     
      </div>
    )
  }
}

export default Users