import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Button, Card, Descriptions } from "antd";
import { capitalize } from "lodash";
import moment from "moment";

const Main = () => {
  const { storeId } = useParams();

  const [store, setStore] = useState({
    name: "",
    description: "",
    longitude: "",
    latitude: "",
    created_at: "",
  });

  useEffect(() => {
    if (storeId) {
      const url = `https://foodsapi.mobitill.com/adhoc/store/get-one/${storeId}`;

      axios({ method: "GET", url }).then((response) => {
        const data = response.data.store;

        setStore({ ...data });
      });
    }
  }, [storeId]);

  return (
    <div className="def_page" style={{ width: "100%" }}>
      <Card
        title="Store description"
        extra={
          <Button type="link" href="/stores/all">
            Back to stores
          </Button>
        }
      >
        <Descriptions>
          <Descriptions.Item label="Name">
            {capitalize(store.name)}
          </Descriptions.Item>

          <Descriptions.Item label="Description">
            {store.description}
          </Descriptions.Item>

          <Descriptions.Item label="Longitude">
            {store.longitude}
          </Descriptions.Item>

          <Descriptions.Item label="Latitude">
            {store.latitude}
          </Descriptions.Item>

          <Descriptions.Item label="Created at">
            {moment(store.created_at).format("MMMM Do YYYY")}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </div>
  );
};

export default Main;
