import React, { Fragment, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { Button, Descriptions, Divider, Table } from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "./OrderPdf";

const Main = () => {
  const { id } = useParams();
  const organisation_id = sessionStorage.getItem("selected_org_id");
  let history = useHistory();

  const [showDownloadButton, setShowDownloadButton] = useState(false);

  const [customer_order, setCustomerOrder] = useState({
    order__id: 0,
    order_amount: 0,
    order_status: "",
    customer_first_name: "",
    customer_last_name: "",
    customer_phone_number: "",
    customer_email: "",
    order_delivery_address: null,
    order_block_number: null,
    order_house_number: null,
    order_floor_number: null,
  });

  const [customerOrderItems, setCustomerOrderItems] = useState([]);

  useEffect(() => {
    const url = `https://foodsapi.mobitill.com/adhoc/customer-orders/get-one/${organisation_id}/${id}`;
    const authStr = `Bearer ${sessionStorage.getItem("token")}`;

    axios({ method: "GET", url, headers: { Authorization: authStr } })
      .then((res) => {
        setCustomerOrder({ ...res.data.customer_order });
        setCustomerOrderItems((customerOrderItems) => [
          ...customerOrderItems,
          ...res.data.customer_order_items,
        ]);
        setShowDownloadButton(true);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          history.push("/customer-orders");
        } else {
          console.log(error);
        }
      });
  }, []);

  const orderItemsColumn = [
    {
      title: "Name",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Amount per unit",
      dataIndex: "amount_per_unit",
      key: "amount_per_unit",
      render: (amount) => (
        <>{`${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /=`}</>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Total",
      dataIndex: "amount_total",
      key: "amount_total",
      render: (total) => (
        <>{`${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /=`}</>
      ),
    },
  ];

  return (
    <Fragment>
      <div id="order_details" style={{ padding: "10px" }}>
        <Button
          type="primary"
          onClick={() => history.push("/customer-orders/orders")}
        >
          Back
        </Button>

        <Divider />

        <Descriptions title="Order details">
          <Descriptions.Item label="Order No.">
            {customer_order.order__id}
          </Descriptions.Item>
          <Descriptions.Item label="Amount">
            {`${customer_order.order_amount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /=`}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {customer_order.order_status}
          </Descriptions.Item>
          <Descriptions.Item label="Customer name" span={3}>
            {`${customer_order.customer_first_name} ${customer_order.customer_last_name}`}
          </Descriptions.Item>
          <Descriptions.Item label="Customer phone No.">
            {customer_order.customer_phone_number}
          </Descriptions.Item>
          <Descriptions.Item label="Customer email">
            {customer_order.customer_email}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions title="Address information">
          <Descriptions.Item label="Address" span={3}>
            {customer_order.order_delivery_address}
          </Descriptions.Item>
          <Descriptions.Item label="Block No.">
            {customer_order.order_block_number}
          </Descriptions.Item>
          <Descriptions.Item label="House No.">
            {customer_order.order_house_number}
          </Descriptions.Item>
          <Descriptions.Item label="Floor No.">
            {customer_order.order_floor_number}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions title="Store information">
          <Descriptions.Item label="Store" span={3}>
            {customer_order.store_name}
          </Descriptions.Item>
        </Descriptions>

        <Divider />

        <Table
          pagination={false}
          dataSource={customerOrderItems}
          columns={orderItemsColumn}
        />
      </div>

      <Divider />

      {showDownloadButton && (
        <PDFDownloadLink
          document={
            <MyDocument
              customer_order={customer_order}
              customer_order_items={customerOrderItems}
            />
          }
          fileName={`${customer_order.order__id}-${new Date().getTime()}.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              "Loading document"
            ) : (
              <Button type="primary">Download</Button>
            )
          }
        </PDFDownloadLink>
      )}
    </Fragment>
  );
};

export default Main;
