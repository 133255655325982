import React, { useState, useCallback, Component } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { inject, observer } from 'mobx-react';
import { SubTitle } from 'reactbulma'
import { Form, Button, Row, Col, Select, Upload, Icon, message } from 'antd'

import FormBuilder from 'antd-form-builder'

import { getCountries, getCurrencies } from '../../components/utils'
const { Option } = Select;
const { Dragger } = Upload;

@inject('store') 
@observer
class AddOrganisation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      success: false,
      errors:[],
      startFileUpload: false,
      organisation_id: ''
    }
    //this.DraggerProps ={}
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (err) return
     
    })
    
    let payload = this.props.form.getFieldsValue()
    this.addOrganisation(payload)
  }

  addOrganisation = (details) => {
   
    const authStr = `Bearer ${sessionStorage.getItem('token')}`
    const headers = {'Authorization': authStr}
    let payload = {}
    
    payload.data = details
    payload.id = sessionStorage.getItem('id')
    payload.type = sessionStorage.getItem('type')
    payload.data.owner_id = sessionStorage.getItem('id')

    axios({
      url: `${this.props.store.domain}/organisations`,
      method: 'post',
      data: payload,
      headers
    })
    .then( (res) => {
     const { data } = res.data 
     message.success('Organisation created successfully');
     this.setState({success: true, errors: [], startFileUpload: true, organisation_id: data.id})
    })
    .catch( (error) => {
      message.error('Something went wrong');
      if(error.response) {
        const { data } = error.response
        this.setState({success: false, errors: data.errors})
      } else {
        console.error(error)
      }
    })
  }

  componentDidMount() {
   
  }
 
  render() {
   
    const logo_props = {
        name: 'images',
        multiple: false,
        data: 'org_logo',
        accept: 'image/x-png, image/png, image/gif, image/jpeg',
        headers: { authorization:  `Bearer ${sessionStorage.getItem('token')}`},
        action: `${this.props.store.domain}/organisations/${this.state.organisation_id}/upload/logo`,
        onChange(info) {
          
          const { status } = info.file;
          if (status !== 'uploading') {
           
          }
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
    };

    const image_props = {
        name: 'images',
        multiple: true,
        data: 'org_imgs',
        accept: 'image/x-png, image/png, image/gif, image/jpeg',
        headers: { authorization:  `Bearer ${sessionStorage.getItem('token')}`},
        action: `${this.props.store.domain}/organisations/${this.state.organisation_id}/upload/images`,
        onChange(info) {
           
          const { status } = info.file;
          if (status !== 'uploading') {
           
          }
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
    };

    const  UploadLogo = (props) => {
        return(
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                <Icon type="picture" theme="twoTone" />
                </p>
                <p className="ant-upload-text">Click or drag one organisation Logo to upload</p>
                <p className="ant-upload-hint">
                Up to 4 images allowed. Maximum size per image should be 3MB
                </p>
            </Dragger>
        )
    }

    const  UploadImage = (props) => {
        return(
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                <Icon type="picture" theme="twoTone" />
                </p>
                <p className="ant-upload-text">Click or drag organisation images to upload</p>
                <p className="ant-upload-hint">
                Up to 4 images allowed. Maximum size per image should be 3MB
                </p>
            </Dragger>
        )
    }

    const meta = {
      columns: 6,
      gutter: 50,
      formItemLayout: null, // Must set this for inline layout
      colon: true,
      fields: [
        // {
        //   key: 'label1',
        //   colSpan: 6,
        //   render() {
        //     return (
        //       <fieldset>
        //         <legend>Add organisation</legend>
        //       </fieldset>
        //     )
        //   },
        // },
        { key: 'name', label: 'Name', colSpan: 2, required: true},
        { key: 'email', label: 'Email', colSpan: 2, required: true },
        { key: 'phone_number', label: 'Phone number', colSpan: 2,  },
        { key: 'country', label: 'Country', colSpan: 2,required: true, widget: 'select', options: getCountries(),  widgetProps: {showSearch: true }},
        { key: 'currency', label: 'Currency', colSpan: 2,required: true, widget: 'select', options: getCurrencies(), widgetProps: {showSearch: true }},
        { key: 'vat', label: 'VAT %', colSpan: 2,required: true  },
        { key: 'tax_id', label: 'Tax id', colSpan: 2,  },
        { key: 'tax_revenue_pin', label: 'PIN', colSpan: 2,  },
        { key: 'description', label: 'Description', colSpan: 4, widget: 'textarea'},
        { key: 'address', label: 'Address', colSpan: 4, widget: 'textarea', required: true },
        { key: 'has_delivery_service', label: 'Delivery', colSpan: 2, widget:'switch', help: 'Does the company offer delivery services?' },
       
      
       // { key: 'button', label: '', colSpan: 6, widget: this.UploadImage },
      ],
      
    }
  
    return (
      <div className='grid_margin'>
      <Form layout="vertical" onSubmit={this.handleSubmit}>
        <FormBuilder form={this.props.form} meta={meta} />
        <Form.Item> 
        <br/>
        {this.state.startFileUpload && <SubTitle is='6'>Upload one logo</SubTitle>}
        {this.state.startFileUpload && <UploadLogo {...logo_props}/>}
        <br/>
        <br/>
        {this.state.startFileUpload && <SubTitle is='6'>Upload organisation images</SubTitle>}
        {this.state.startFileUpload && <UploadImage {...image_props}/>}
        <br/>
          <Button htmlType="submit" type="primary" style={{ width: '100%' }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
     
     </div>
    )
  }

}

export default Form.create()(AddOrganisation)

