import { Card, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import View from './View'
import Edit from './Edit'
import { useRouteMatch } from 'react-router-dom'
import axios from 'axios'

const Main = () => {
	const { params } = useRouteMatch()
	const [inventory, setInventory] = useState(null)

	const fetchInventory = id => {
		const authStr = `Bearer ${sessionStorage.getItem('token')}`
		const organisation_id = sessionStorage.getItem('selected_org_id')

		const url = `https://foodsapi.mobitill.com/inventories/${id}?organisation_id=${organisation_id}`

		axios({ method: 'GET', url, headers: { Authorization: authStr } })
			.then(response => {
				const [data] = response.data.data
				setInventory(data)
			})
			.catch(error => {
				console.log(error)
			})
	}

	useEffect(() => {
		const id = params.id
		fetchInventory(id)
	}, [params])

	const [currentTab, setCurrentTab] = useState('View')

	const onTabChange = key => setCurrentTab(key)

	const tabList = [
		{
			key: 'View',
			tab: 'View',
		},
		{
			key: 'Edit',
			tab: 'Edit',
		},
	]

	const contentList = {
		View: <View inventory={inventory} />,
		Edit: <Edit inventory={inventory} fetchInventory={fetchInventory} />,
	}

	return (
		<Card
			style={{ width: '100%' }}
			title="Item description"
			tabList={tabList}
			activeTabKey={currentTab}
			onTabChange={key => onTabChange(key)}
			extra={<a href="/inventories">Back</a>}
		>
			{inventory ? contentList[currentTab] : <Spin size="large" />}
		</Card>
	)
}

export default Main
