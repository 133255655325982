import React, { Component } from 'react'
import { Title } from 'reactbulma'
import { Redirect } from 'react-router-dom'
import { Layout } from 'antd';
import axios from 'axios'
import { inject, observer, Observer } from 'mobx-react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { SubTitle } from 'reactbulma'
import Header from '../../components/header'
import TableComponent from '../../components/tableComponent'
import {columnFormatter} from '../../components/columnFormatter'

@inject('store') 
@observer
class Overview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      redirect: false,
      data: {}
    }

  }

  componentDidMount() {
    this.initialize()
  }



  initialize = () => {

    if(!sessionStorage.getItem('token')) {
      this.setState({
        redirect: true
      })
      return
    }

    const authStr = `Bearer ${sessionStorage.getItem('token')}` 
    
    return axios({
      url: `${this.props.store.domain}/overview`,
      method: 'get',
      headers: { 'Authorization': authStr }
      })
      .then( (res) => {
        if(res.data.success === true) {

          this.setState({
            isLoading: false
          })
          return

        }
        else {
            // Redirect to login //
             {/* <Header/>
      <div className='grid_margin card'>
      <TableComponent columns={columns} data={data} />
      </div>
      </div> */}
            this.setState({
              redirect: true
            })
            return
        }
      })
      .catch( (error) => {
        this.setState({
          redirect: true
        })
        return
      })
  }

   positionFormatter = (cell, row) => {
    return (
      <span style={ { color: 'grey' } }>
      {cell}
      </span>
    );
}

  render() {
    const data = [
      
    ]
    
    return (
      <div className='def_page'>
      <Header title = { 'Overview' } />
    
      </div>
    )
  }
}

export default Overview