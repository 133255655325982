import { Button, Form, Input, notification } from "antd";
import axios from "axios";
import React from "react";

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 2, span: 16 },
};

const hasErrors = (fieldsError) =>
  Object.keys(fieldsError).some((field) => fieldsError[field]);

const Main = ({ form }) => {
  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
  } = form;

  const openNotification = (message, type = "info") =>
    notification[type]({
      message,
    });

  const onSubmit = (e) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        const url = "https://foodsapi.mobitill.com/adhoc/store/create/20";

        axios({ method: "PUT", url, data: { data: [values] } })
          .then(() => openNotification("Store added successfully.", "success"))
          .catch((error) => openNotification(error.message, "error"));
      }
    });
  };

  // Only show error after a field is touched.
  const nameError = isFieldTouched("name") && getFieldError("name");
  const descriptionError =
    isFieldTouched("description") && getFieldError("description");
  const latitudeError = isFieldTouched("latitude") && getFieldError("latitude");
  const longitudeError =
    isFieldTouched("longitude") && getFieldError("longitude");

  return (
    <Form {...layout} onSubmit={onSubmit}>
      <Form.Item
        label="Name"
        validateStatus={nameError ? "error" : ""}
        help={nameError || ""}
      >
        {getFieldDecorator("name", {
          rules: [{ required: true, message: "Name is required." }],
        })(<Input placeholder="Name" />)}
      </Form.Item>

      <Form.Item
        label="Description"
        validateStatus={descriptionError ? "error" : ""}
        help={descriptionError || ""}
      >
        {getFieldDecorator("description")(
          <Input.TextArea placeholder="Description" />
        )}
      </Form.Item>

      <Form.Item label="Coordinates">
        <Form.Item
          validateStatus={latitudeError ? "error" : ""}
          help={latitudeError || ""}
          style={{ display: "inline-block", width: "calc(50% - 8px)" }}
        >
          {getFieldDecorator("latitude")(<Input placeholder="Latitude" />)}
        </Form.Item>

        <Form.Item
          validateStatus={longitudeError ? "error" : ""}
          help={longitudeError || ""}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "0 8px",
          }}
        >
          {getFieldDecorator("longitude")(<Input placeholder="Longitude" />)}
        </Form.Item>
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError())}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const WrappedForm = Form.create({ name: "add_store" })(Main);

export default WrappedForm;
