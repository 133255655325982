import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import { Tabs } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { inject, observer} from 'mobx-react'
import Header from '../../components/header'
import TableComponent from '../../components/tableComponent'
import {
	columnFormatter,
	positionFormatter,
	boldFormatter,
} from '../../components/columnFormatter'
import Loader from '../../components/LoaderComponent'
import Footer from '../../components/footer'
import PriceFormatter from '../../components/PriceFormatter'

import AddProduct from './addProduct'
import AddCategory from './addCategory'
import ProductCategories from './categories'
import Tag from '../../components/Tag'
import AddFromExcel from './addFromExcel'
import ProductAudit from './productAudit'

const { TabPane } = Tabs

@inject('store')
@observer
class Products extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selected_product: {},
			selected_category: {},
			showTabs: true,
			isLoading: false,
			errors: [],
		}
		this.refresh = false
	}

	onTabClick = key => {
		if (key === '1') this.props.history.push('/products')
		if (key === '2') this.props.history.push('/products/add')
		if (key === '3') this.props.history.push('/products/categories')
		if (key === '4') this.props.history.push('/products/categories/add')
		if (key === '5') this.props.history.push('/products/addfromexcel')
		if (key === '6') this.props.history.push('/products/productAudit')

	}

	rowEvents = {
		onClick: (e, row, rowIndex) => {
			let item = { ...row }
			this.setState({ selected_product: item }, () => {
				this.setState({ showTabs: false })
				this.props.history.push(`/products/${item.id}/details`)
			})
		},
	}

	showCategoryDetails = selected_category => {
		this.setState({ selected_category }, () => {
			this.props.history.push(
				`/products/categories/${selected_category.id}/details`,
			)
		})
	}

	fetchProducts = async (after_id = null, limit = this.state.pageSize) => {
		await this.fetchProductCategories()
		let organisation_id = sessionStorage.getItem('selected_org_id')
		const params = { organisation_id, dashboard: true }
		const authStr = `Bearer ${sessionStorage.getItem('token')}`
		this.setState({ isLoading: true })
		axios({
			url: `${this.props.store.domain}/products`,
			method: 'get',
			params,
			headers: { Authorization: authStr },
		})
			.then(res => {
				const { data } = res.data
				data.forEach((element, index) => {
					element.key = index + 1
					element.category_names = this.getCategoryNames(element.categories)
					element.is_active = element.active ? 'Yes' : 'No'
					element.has_images =
						element.image_urls && element.image_urls.length > 0 ? 'Yes' : 'No'
					element.created_at = moment(element.created_at).format('LLLL')
				})
				this.props.store.updateValue('products', data)
				//this.fetchProductCategories()
				this.setState({ isLoading: false })
			})
			.catch(error => {
				if (error.response) {
					const { data } = error.response
					this.setState({ isLoading: false, errors: data.errors })
				} else {
					console.error(error)
				}
			})
	}

	fetchProductCategories = (after_id = null, limit = this.state.pageSize) => {
		let organisation_id = sessionStorage.getItem('selected_org_id')
		const params = { organisation_id }
		const authStr = `Bearer ${sessionStorage.getItem('token')}`

		axios({
			url: `${this.props.store.domain}/product-categories`,
			method: 'get',
			params,
			headers: { Authorization: authStr },
		})
			.then(res => {
				const { data } = res.data
				data.forEach((element, index) => {
					element.key = index + 1
				})
				this.props.store.updateValue('product_categories', data)
				this.setState({ isLoading: false })
				this.refresh = false
			})
			.catch(error => {
				if (error.response) {
					const { data } = error.response
					this.setState({ isLoading: false, errors: data.errors })
				} else {
					console.error(error)
				}
			})
	}

	componentDidMount() {
		if (this.props.store.products.length === 0) this.fetchProducts()
	}

	componentDidUpdate(previousProps, previousState) {
		console.log('product updated')

		if (previousState.showTabs === false) {
			this.setState({ showTabs: true })
		}
		// console.log('previous products size - ' + previousProps.store.products.length)
		// console.log(' products size - ' +this.props.store.products.length)
		if (previousProps.store.products.length === 0 && !this.refresh) {
			this.refresh = true
			console.log('fetching em...')
			//this.fetchProducts()
		}
	}

	capitalizeFirstLetter = string => {
		if (string == null) return ''
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	getCategoryNames = category_ids => {
		if (category_ids == null) return ''
		if (category_ids.length === 0) return ''

		let categories = this.props.store.product_categories
		let category_names = ''

		categories.forEach(item => {
			category_ids.forEach(id => {
				if (item.id.toString() === id.toString()) {
					category_names += `${this.capitalizeFirstLetter(item.name)},  `
				}
			})
		})
 
		return category_names
	}

	getCategoryName(cell, row) {
		let categories = this.props.store.product_categories
		let ids = row.categories || []
		let category_names = ''

		categories.forEach(item => {
			ids.forEach(id => {
				if (item.id.toString() === id.toString()) {
					category_names += `${this.capitalizeFirstLetter(item.name)},  `
				}
			})
		})

		return <p style={{ color: 'grey' }}> {category_names} </p>
	}

	priceFormatter(cell, row) {
		return <PriceFormatter amount={row.price} />
	}

	activeStatus(cell, row) {
		return <Tag ok={row.active} text_ok="Yes" text_danger="Not active" />
	}

	codeFormatter(cell, row) {
		return (
			<span>
				<strong style={{ color: '#007BFF' }}>{row.code}</strong>
			</span>
		)
	}

	skuFormatter(cell, row) {
		return (
			<span>
				<strong style={{ color: '#696969' }}>{row.sku}</strong>
			</span>
		)
	}

	quantityInStockFormatter(cell, row) {
		return (
			<div style={{ display: 'flex', float: 'right', flexDirection: 'row' }}>
				<span>
					<strong>{row.quantity || 0}</strong>
				</span>
				&ensp;
				<span style={{ color: 'grey' }}>
					{(row.unit_of_sale &&
						`${this.capitalizeFirstLetter(row.unit_of_sale)}(s)`) ||
						''}
				</span>
			</div>
		)
	}

	Products = observer(props => {
		let p = this.props.store.products

		const columns = [
			{ dataField: 'key', text: 'No', align: 'center', ...positionFormatter },
			{
				dataField: 'description',
				text: 'Description',
				...boldFormatter,
				...columnFormatter,
			},
			{ dataField: 'id', text: 'Product id', hidden: true },
			{ dataField: 'location', text: 'Location', hidden: true },
			{
				dataField: 'sku',
				text: 'SKU',
				...boldFormatter,
				...columnFormatter,
				align: 'right',
			},
			{
				dataField: 'price',
				text: 'Price(s)',
				formatter: this.priceFormatter.bind(this),
				...columnFormatter,
				align: 'right',
			},
			{ dataField: 'vat', text: 'Vat', ...columnFormatter, align: 'right' },
			{
				dataField: 'code',
				text: 'Code',
				...boldFormatter,
				formatter: this.codeFormatter.bind(this),
				...columnFormatter,
				align: 'right',
			},
			{
				dataField: 'categories',
				text: 'Categories',
				formatter: this.getCategoryName.bind(this),
				...columnFormatter,
				align: 'center',
			},
			{ dataField: 'category_names', text: 'Category(s)', hidden: true },
			{
				dataField: 'quantity',
				text: 'Quantity in stock',
				...columnFormatter,
				formatter: this.quantityInStockFormatter.bind(this),
				align: 'right',
			},
			{
				dataField: 'active',
				text: 'Active',
				formatter: this.activeStatus.bind(this),
				...columnFormatter,
			},
			{ dataField: 'unit_cost', text: 'Unit cost', hidden: true },
			{ dataField: 'reorder_level', text: 'Reorder level', hidden: true },
			{ dataField: 'unit_of_order', text: 'Unit of order', hidden: true },
			{ dataField: 'unit_of_sale', text: 'Unit of sale', hidden: true },
			{ dataField: 'conversion', text: 'Conversion', hidden: true },
			{ dataField: 'created_at', text: 'Date Created', hidden: true },
			{ dataField: 'is_active', text: 'Is active?', hidden: true },
			{ dataField: 'has_images', text: 'Has images?', hidden: true },
			{ dataField: 'details', text: 'Details', hidden: true },
		]

		if (p.length === 0) return null
		return (
			<div className="grid_margin card">
				<TableComponent
					columns={columns}
					data={p}
					tableName="products"
					rowEvents={this.rowEvents}
				/>
			</div>
		)
	})

	render() {
		return (
			<div className="def_page" style={{ height: '100%' }}>
				<Header title={'Products'} />
				{this.state.showTabs && (
					<Tabs
						className="tab_margin"
						defaultActiveKey="1"
						onTabClick={this.onTabClick}
						style={{ marginTop: '0px !important' }}
					>
						<TabPane tab="Products" key="1"></TabPane>
						<TabPane tab="Add product" key="2"></TabPane>
						<TabPane tab="Add products from excel file" key="5"></TabPane>
						<TabPane tab="Categories" key="3"></TabPane>
						<TabPane tab="Add category" key="4"></TabPane>
						<TabPane tab="Product Audit" key="6"></TabPane>
					</Tabs>
				)}
				<Loader
					name={'products'}
					isLoading={this.state.isLoading}
					errors={this.state.errors}
					size={this.props.store.products.length}
				/>
				<Switch>
					<Route
						exact
						path={`${this.props.match.path}/`}
						render={props => <this.Products data={this.props.store.products} />}
					/>

					<Route
						exact
						path={`${this.props.match.path}/add`}
						render={props => (
							<AddProduct
								fetchProducts={this.fetchProducts}
								mode={'add'}
								{...props}
							/>
						)}
					/>

					<Route
						exact
						path={`${this.props.match.path}/addfromexcel`}
						render={props => (
							<AddFromExcel
								fetchProducts={this.fetchProducts}
								//mode={'add'}
								{...props}
							/>
						)}
					/>

					<Route
						exact
						path={`${this.props.match.path}/:id/details`}
						render={props => (
							<AddProduct
								fetchProducts={this.fetchProducts}
								mode={'edit'}
								selected_product={this.state.selected_product}
								{...props}
							/>
						)}
					/>

					<Route
						exact
						path={`${this.props.match.path}/categories`}
						render={props => (
							<ProductCategories
								showCategoryDetails={this.showCategoryDetails}
								{...props}
							/>
						)}
					/>
					<Route
						exact
						path={`${this.props.match.path}/categories/add`}
						render={props => (
							<AddCategory
								fetchProductCategories={this.fetchProductCategories}
								{...props}
							/>
						)}
					/>

					<Route
						exact
						path={`${this.props.match.path}/categories/:id/details`}
						render={props => (
							<AddCategory
								fetchProductCategories={this.fetchProductCategories}
								selected_category={this.state.selected_category}
								{...props}
							/>
						)}
					/>
					<Route
						exact
						path={`${this.props.match.path}/productAudit`}
						render={props => (
							<ProductAudit
								showCategoryDetails={this.showProductAudit}
								{...props}
							/>
						)}
					/>
				</Switch>
				<Footer />
			</div>
		)
	}
}

export default Products
