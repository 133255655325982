import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import axios from 'axios'
import { inject, observer, Observer } from 'mobx-react';
import SignUp from "./Signup";
import Login from "./Login";
import ForgotPassword from "./forgotPassword";
import Overview from "../pages/overview";
import VerticalMenu from "./VerticalMenu";
import HorizontalComponent from "./horizontalComponent";
import Organisations from "../pages/organisations";
import Products from "../pages/products";
import CustomerOrders from "../pages/customer-orders";
import Inventories from "../pages/inventories";
import Employees from "../pages/employees";
import SalesReport from "../pages/sales-report";
import StockUpdates from "../pages/stock-updates";
import Expenses from "../pages/expenses";
import Vendors from "../pages/vendors";
import PurchaseOrders from "../pages/purchase-orders";
import Devices from "../pages/devices";
import Settings from "../pages/settings";
import Deliveries from "../pages/deliveries";
import Users from "../pages/users";
import Stores from '../pages/stores'
import { messaging } from "../init-fcm";

@inject("store")
class MainPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
          error: null
        };
    };
    
    async componentDidMount() {
      messaging.requestPermission()
        .then(async function() {
          const token = await messaging.getToken();
          console.log(token)
        })
        .catch(function(err) {
          console.log("Unable to get permission to notify.", err);
        });
    navigator.serviceWorker.addEventListener("message", (message) => {
     
    
    });

    }

    render() {
    //     return (
    //         <Overview/>
    //     )
    // }
    if(sessionStorage.getItem('loggedIn')) {
    return (
      
    <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', backgroundColor: "#E9ECF0", overflow: 'hidden' }}>
       <div style={{ height: '100%',}}>
        <Route
                path='/'
                render={ props => (
                  <VerticalMenu
                  {...props}
                  />
                )}
              />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column',width: '100%', flexGrow: 1, backgroundColor:'#F0F2F5'}}>
      
      <Route
              path='/'
              render={ props => (
                <HorizontalComponent
                {...props}
                />
              )}
            />
      <Route
              path='/overview'
              render={ props => (
                <Overview
                {...props}
                />
              )}
            />
      <Route
              path='/organisations'
              render={ props => (
                <Organisations
                {...props}
                />
              )}
            />      
      <Route
              path='/products'
              render={ props => (
                <Products
                {...props}
                />
              )}
            />      
      <Route
              path='/customer-orders'
              render={ props => (
                <CustomerOrders
                {...props}
                />
              )}
            />   
      <Route
              path='/inventories'
              render={ props => (
                <Inventories
                {...props}
                />
              )}
            />    

       <Route
              path='/employees'
              render={ props => (
                <Employees
                {...props}
                />
              )}
            />   

      <Route
              path='/users'
              render={ props => (
                <Users
                {...props}
                />
              )}
            />          

      <Route
              path='/sales-report'
              render={ props => (
                <SalesReport
                {...props}
                />
              )}
            />   
        <Route
              path='/stock-updates'
              render={ props => (
                <StockUpdates
                {...props}
                />
              )}
            />  
          <Route
              path='/expenses'
              render={ props => (
                <Expenses
                {...props}
                />
              )}
            />   
          <Route
              path='/distributors'
              render={ props => (
                <Vendors
                {...props}
                />
              )}
            />     
          <Route
              path='/purchase-orders'
              render={ props => (
                <PurchaseOrders
                {...props}
                />
              )}
            />   
          <Route
              path='/devices'
              render={ props => (
                <Devices
                {...props}
                />
              )}
            />  
          <Route
              path='/settings'
              render={ props => (
                <Settings
                {...props}
                />
              )}
            />     
          <Route
              path='/deliveries'
              render={ props => (
                <Deliveries
                {...props}
                />
              )}
            />
          <Route
            path='/stores'
            render={ props => (
              <Stores
              {...props}
              />
            )}
          />     

    </div>
    </div>)
    }else{
      return(
       <div>
            <Route
              path='/signup'
              render={ props => (
                <SignUp
                {...props}
                />
              )}
            />

            <Route
              path='/forgotPassword'
              render={ props => (
                <ForgotPassword
                {...props}
                />
              )}
            />

            <Route
              path='/login'
              render={ props => (
                <Login
                {...props}
                />
              )}
            />
             <Route
              exact
              path='/'
              render={ props => (
                <Login
                {...props}
                />
              )}
            />
       </div>
      )
    }
    }
};

export default MainPage;