import React, { Component } from "react";
import Header from "../../../components/header";
import { Tabs } from "antd";
import MyStores from "./MyStores";
import AddStore from "./AddStore";

const { TabPane } = Tabs;

class Main extends Component {
  render() {
    return (
      <div className="def_page">
        <Header title="Stores" />

        <div className="grid_margin card">
          <Tabs defaultActiveKey="1">
            <TabPane tab="My stores" key="1">
              <MyStores {...this.props} />
            </TabPane>

            <TabPane tab="Add store" key="2">
              <AddStore {...this.props} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default Main;
