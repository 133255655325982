import React, { Component } from "react";
import moment from 'moment'
import { Title, SubTitle } from 'reactbulma'
import { Layout, Empty, Tabs, Button, Descriptions } from 'antd';
import { DatePicker } from 'antd';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MMMM-YYYY';
class SubHeader extends Component {

    onTabClick = (key) => {
      
        if(key === '1') this.props.handleDetailsMode()
        if(key === '2') this.props.handleEditMode()
        if(key === '3') this.props.handleTabThree()
      }

    SubTabs = () => {
        return (
        <div  style={{ width: '100%', display:'flex', flexDirection:'row'}}>
          
           <Tabs defaultActiveKey="1" onTabClick={this.onTabClick} style={{marginTop: '0px !important'}}>
                <TabPane tab={this.props.tab_one_text} key="1"></TabPane>
                <TabPane tab={this.props.tab_two_text} key="2"></TabPane>
                { this.props.tab_three_text && <TabPane tab={this.props.tab_three_text} key="3"></TabPane>}
            </Tabs>
        </div>
        )
    }

    backOnClick = () => {
        this.props.goBack()
    }

    render() {
        return (
            <div>
              <div  style={{ width: '100%', display:'flex', flexDirection:'row', alignItems:'center'}}>
               <Button type="primary"  shape="round" icon={'arrow-left'} size={"default"} onClick={this.backOnClick} style={{marginRight: '20px', paddingRight: '10px'}}>Back</Button>
               <Title is='5' style={{marginBottom: '0px'}}>{this.props.title}</Title>
               </div>

               <div  style={{ display: 'flex' , justifyContent: 'space-between', flexDirection: 'row'}}>
               <this.SubTabs/>  
               {this.props.showDatePicker &&
               <RangePicker
                      defaultValue={[moment(), moment()]}
                      format={dateFormat}
                      bordered={false}
                      onChange = {this.props.onDatePickerChanged}
                    />
               }
                </div>
           </div>
        )
    }
};

export default SubHeader;