import React, { Component } from 'react'
import { Title, SubTitle, Message, Delete } from 'reactbulma'
import { Layout, Empty, Tabs, Button, Descriptions, Divider, List, Card } from 'antd';
import { ReactComponent as Nothing } from '../images/svg.svg'



class Description extends Component {

    constructor(props) {
        super(props);
        this.state = {
          
        };
    };

    Label = (data) => {
        return (
        <strong>{data}</strong>
        )
    }

    getItems = (data) => {
    let children = []
    let big = ['Details', 'Cancel reason' ]  

    Object.keys(data).forEach(function(key) {
    data[key] !== undefined && children.push( <Descriptions.Item label={key} span={ big.includes(key) ? 2 : 1}>{data[key]}</Descriptions.Item>);
    }); 
    return children
    }

    Kard = (props) => {
        return(
            <img  style={{ width: 200, height: 200, backgroundSize: 'cover', overflow: 'hidden', marginTop: '16px' }}
              alt="category_image"
              src={props.image}
            />
        )
    }

    ImagesTitle = (props) => {
        return (
        <Title style={{ marginBottom: '2px'}} is='5'>
            {`Product images(${props.length})`}
        </Title>
        )
    }

    render() {
        return (
        <div style={{ display:'flex', flexDirection:'column', width: '100%', marginTop: '10px'}}> 
        <div style={{ display:'flex', flexDirection:'row', width: '100%', justifyContent: 'space-between'}}>
        <Descriptions bordered column={2} size={'default'} style={{ display:'flex', flexGrow: '1'}}>
         {this.getItems(this.props.data)}
        </Descriptions>
        { this.props.showImages && 
           <div style={{ display:'flex', flexDirection:'column', width: '250px', marginLeft: '20px', marginRight: '20px'}}>
            
            <List
                header={this.ImagesTitle(this.props.imageData || [])}
                itemLayout="vertical"
               
                pagination={{
                onChange: page => {
                    console.log(page);
                },
                pageSize: 2,
                }}
                bordered
                grid={{ gutter: 16, column: 1 }}
                dataSource={this.props.imageData || []}
                renderItem={item => (
                <List.Item>
                    <this.Kard title={""} description={""} image={item}></this.Kard>
          </List.Item>
            )}
            />
        </div>
       }
        </div> 
        <br/>
        <br/>
        {this.props.showDelete &&
        <Button type='danger' size='default' onClick={this.props.onDeleteItem}> {this.props.deleteText} </Button>
        }
        </div>
        )
  }
}

export default Description