import React from 'react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Title } from 'reactbulma'

const positionFormat = (cell, row) => {
  return (
    <span style={ { color: 'grey' } }>
    {cell}
    </span>
  );
}

const boldFormat = (cell, row) => {
  return (
    <span>
    <strong>{cell}</strong>
    </span>
  );
}

const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', fontFamily:'', fontSize: '0.5rem'}}>
        <Title is='6' style={{ fontSize: '0.9rem', marginBottom: '5px'}}>{ column.text }</Title>
        { filterElement }
        </div>
    );
}

const columnFormatter = { filter: textFilter(), headerFormatter: headerFormatter }
const positionFormatter = { formatter: positionFormat }
const boldFormatter = { formatter: boldFormat }

export {
  columnFormatter,
  positionFormatter,
  boldFormatter
} 