import React, { Component } from "react";
import { Title, SubTitle } from 'reactbulma'

class Footer extends Component {
    render() {
        return (
        <p style={{width: '100%' ,textAlign:'center', marginTop: '10px', display: 'flex', justifyContent: 'center', fontSize: '11px'}}>
            Copyright &copy; 2023.  <span style={{color:'#007bff'}}>Data Integrated Limited.</span> All rights reserved
        </p>
        )
    }
};

export default Footer;