import React, { Component } from "react";
import { Title, SubTitle } from 'reactbulma'

class Tag extends Component {
    render() {
        
        return (
            <div style={{width: 'fit-content', }}>
                <div className={ this.props.ok ? 'round_tag_ok' : 'round_tag_danger'}>
                { this.props.ok ? this.props.text_ok : this.props.text_danger }
                </div>
            </div>
          );
    }
};

export default Tag;